import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import {
    Card,
    CardContent,
    Grid,
    AppBar,
    IconButton,
    Button,
    Dialog,
    Slide,
    Toolbar,
    Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import settings from "../../../settings";
// import Title from "../../Helpers/Titles";
import Progress from "../../Helpers/Progress";
import ContactForm from "../Contacts/ContactForm";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
}));

export default function Contact({ match }) {
    let mounted = true;
    const classes = useStyles();
    const initialContact = {
        id: 1,
        first: "Sharon",
        last: "DeHaas",
        email: "sharon.dehaas@dcdsb.ca",
        role: "N/A",
        school: "St. Michael Catholic School",
        board: "C-Ontario Catholic Schools",
    };

    const [contact, setContact] = useState({});
    const [contactId, setContactId] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState("");
    const [showProgress, setshowProgress] = useState([]);

    const fetchContacts = (contactId, token) => {
        fetch(settings.api().contacts + "/" + contactId, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (mounted) {
                    setContact(data);
                    setshowProgress(false);
                }
            });
    };
    const handleClickEditContact = (contactId) => {
        const token = localStorage.getItem("pes-crm-token");
        fetch(settings.api().contacts + "/" + contactId, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setDialogTitle("Edit Contact");
                setContactId(contactId);
                setOpen(true);
                setContact(data);
            });
    };
    const handleClose = () => {
        setOpen(false);
        const contactId = match.params.id;
        const token = localStorage.getItem("pes-crm-token");
        fetchContacts(contactId, token);
    };
    const formatNumber = (num) => {
        if (num) return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1-");
        else return num;
    };
    useEffect(() => {
        const contactId = match.params.id;
        const token = localStorage.getItem("pes-crm-token");
        setshowProgress(true);

        fetchContacts(contactId, token);

        return () => {
            mounted = false;
        };
    }, []);

    return (
        <div>
            <Card>
                <CardContent className={classes.content}>
                    {showProgress === true ? (
                        <Progress />
                    ) : (
                        <div>
                            <div>
                                <Grid container spacing={0}>
                                    <Grid item xs={9}>
                                        <p>
                                            <h1>{contact.firstName + " " + contact.lastName}</h1>
                                        </p>
                                        <p>
                                            <h2>{contact.email}</h2>
                                        </p>
                                    </Grid>
                                    <Grid item xs={3} style={{ "text-align": "right" }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            aria-label="Go"
                                            onClick={() => handleClickEditContact(contact.id)}
                                        >
                                            <EditIcon /> Edit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                            <Card>
                                <CardContent className={classes.content}>
                                    <div style={{ fontSize: 15 }}>
                                        <p>
                                            <b>First Name: </b>
                                            {contact.firstName}
                                        </p>
                                        <p>
                                            <b>Last Name: </b>
                                            {contact.lastName}
                                        </p>
                                        <p>
                                            <b>Role: </b>
                                            {contact.role}
                                        </p>
                                        <p>
                                            <b>Title: </b>
                                            {contact.title}
                                        </p>
                                        <p>
                                            <b>Email: </b>
                                            {contact.email}
                                        </p>
                                        <p>
                                            <b>Phone: </b>
                                            {formatNumber(contact.phone)}
                                        </p>
                                        <p>
                                            <b>School: </b>
                                            <span to={`/crm/schools/${contact.schoolId}`}>{contact.school}</span>
                                        </p>
                                        <p>
                                            <b>Board: </b>
                                            <span to={`/crm/schools/${contact.boardId}`}>{contact.board}</span>
                                        </p>
                                        <p>
                                            <b>Notes: </b>
                                            {contact.notes}
                                        </p>
                                        <p>
                                            <b>Default status: </b>
                                            {contact.default}
                                        </p>
                                        <p>
                                            <b>Active status: </b>
                                            {contact.active == true ? "Yes" : "No"}{" "}
                                        </p>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    )}
                </CardContent>
            </Card>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography componet="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                            {dialogTitle}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <ContactForm schoolId="1" contactId={contactId} contactDetail={contact} updated={handleClose} />
            </Dialog>
        </div>
    );
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
