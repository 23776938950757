import React, { Component } from 'react';

class Profile extends Component {
    render() {
        return (
            <div>
                <h1>Alerts</h1>
                <p>This is a sample page. The idea is that CRM users can view alerts directly in the portal.  Alerts could be messages from a PES administrator, another CRM user, a School Contact, etc. </p>
            </div>
        );
    }
}

export default Profile;