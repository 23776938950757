import React, { useState, useEffect, useRef } from "react";
import {
    Avatar,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    TableFooter,
    Typography,
    MenuItem,
    TextField,
    FormControl,
    InputLabel,
    Select,
    AppBar,
    Slide,
    Toolbar,
    Dialog,
    IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ListIcon from "@material-ui/icons/PermContactCalendar";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";

import settings from "../../../settings";

import Title from "../../Helpers/Titles";
import Progress from "../../Helpers/Progress";
import ContactForm from "./ContactForm";
const useStyles = makeStyles((theme) => ({
    root: {},
    content: {
        padding: 0,
    },
    inner: {
        minWidth: 700,
    },
    nameCell: {
        display: "flex",
        alignItems: "center",
    },
    avatar: {
        height: 42,
        width: 42,
        backgroundColor: blue[500],
        marginRight: theme.spacing(1),
    },
}));

export default function Contacts() {
    const classes = useStyles();
    const [contacts, setContacts] = useState([]);
    const [showProgress, setshowProgress] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setrowsPerPage] = useState(10);
    //const [TotalCount, setTotalCount] = useState(0);
    const [pagination, setPagination] = useState({});

    // let mounted = true;
    const mounted = useRef(true);
    const token = localStorage.getItem("pes-crm-token");
    const [filterName, setFilterName] = useState("");
    const [filterschool, setFilterschool] = useState("");
    const [filterboard, setFilterboard] = useState("");
    const [filterEmail, setFilterEmail] = useState("");
    const [boards, setBoards] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState("");
    const [contactId, setContactId] = React.useState("");
    const [contact, setContact] = React.useState({});
    const handleChangePage = (event, page) => {
        setPage(page);
        setshowProgress(true);
        fetchContacts(page, rowsPerPage, filterschool, filterboard, filterName, filterEmail);
    };
    const handleChangePageSize = (event) => {
        setrowsPerPage(event.target.value);
        setshowProgress(true);
        fetchContacts(page, event.target.value, filterschool, filterboard, filterName, filterEmail);
    };

    const fetchContacts = (pageL, rowsPerPageL, filterschoolL, filterboardL, filterNameL, filterEmailL) => {
        //fetch('/mock/contacts')
        pageL = pageL + 1;
        let filterQuerystring =
            (filterNameL !== null && filterNameL !== undefined && filterNameL !== "" ? "&Name=" + filterNameL : "") +
            (filterboardL !== null && filterboardL !== undefined && filterboardL !== ""
                ? "&Board=" + filterboardL
                : "") +
            (filterschoolL !== null && filterschoolL !== undefined && filterschoolL !== ""
                ? "&School=" + filterschoolL
                : "") +
            (filterEmailL !== null && filterEmailL !== undefined && filterEmailL !== ""
                ? "&Email=" + filterEmailL
                : "");

        fetch(settings.api().contacts + "?PageNumber=" + pageL + "&PageSize=" + rowsPerPageL + filterQuerystring, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                for (var pair of response.headers.entries()) {
                    if (pair[0] === settings.api().pagination) {
                        setPagination(JSON.parse(pair[1]));
                    }
                }
                return response.json();
            })
            .then((data) => {
                if (mounted.current) {
                    setContacts(data);
                    setshowProgress(false);
                }
            });
    };

    useEffect(() => {
        setPage(0);
        setrowsPerPage(10);
        setshowProgress(true);
        fetchContacts(page, rowsPerPage);
        fetchBoards();

        return () => {
            mounted.current = false;
        };
    }, []);

    const txt_School_Changed = (event, value) => {
        localStorage.setItem("filterschool", event.target.value);
        setFilterschool(event.target.value);
        setPage(0);
        //setrowsPerPage(10);
        fetchContacts(0, rowsPerPage, event.target.value, filterboard, filterName, filterEmail);
    };
    const Board_Change = (event, value) => {
        if (event.target.value !== undefined && event.target.value !== "" && event.target.value !== " ") {
            localStorage.setItem("filterboard", event.target.value);
            setFilterboard(event.target.value);
            setPage(0);
            //setrowsPerPage(10);
            fetchContacts(0, rowsPerPage, filterschool, event.target.value, filterName, filterEmail);
        } else if (event.target.value === "" || event.target.value === " ") {
            localStorage.setItem("filterboard", "");
            setFilterboard("");
            setPage(0);
            //setrowsPerPage(10);
            fetchContacts(0, rowsPerPage, filterschool, event.target.value, filterName, filterEmail);
        } else {
            setFilterboard(event.target.value);
        }
    };

    const txt_Name_Changed = (event, value) => {
        localStorage.setItem("filterName", event.target.value);
        setFilterName(event.target.value);
        setPage(0);
        //setrowsPerPage(10);
        fetchContacts(0, rowsPerPage, filterschool, filterboard, event.target.value, filterEmail);
    };
    const txt_Email_Changed = (event, value) => {
        localStorage.setItem("filterEmail", event.target.value);
        setFilterEmail(event.target.value);
        setPage(0);
        //setrowsPerPage(10);
        fetchContacts(0, rowsPerPage, filterschool, filterboard, filterName, filterEmail);
    };

    //

    const clearFilter = () => {
        localStorage.setItem("filterschool", "");
        localStorage.setItem("filterboard", "");
        localStorage.setItem("filterName", "");
        localStorage.setItem("filterEmail", "");
        setFilterName("");
        setFilterschool("");
        setFilterboard("");
        setFilterEmail("");
        setPage(0);
        setrowsPerPage(10);

        fetchContacts(0, 10, "", "", "", "");
    };
    const txt_keyup = (event, mode) => {
        if (event != null && event.key === "Enter") {
            if (mode === 1) {
                txt_School_Changed(event);
            } else if (mode === 2) {
                txt_Name_Changed(event);
            } else if (mode === 3) {
                txt_Email_Changed(event);
            }
            //event.target.value
        }
    };
    const fetchBoards = () => {
        fetch(settings.api().meta.boards, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                let arrBoard = [];
                let keys = Object.keys(data);
                for (let i = 0; i < keys.length; i++) {
                    if (data[i]) arrBoard.push({ id: keys[i], value: data[i] });
                }
                arrBoard = arrBoard.sort((a, b) => a.value.localeCompare(b.value));
                setBoards(arrBoard);
                setFilterboard(localStorage.getItem("filterboard"));
            });
    };
    const handleClickEditContact = (contactId) => {
        const token = localStorage.getItem("pes-crm-token");
        fetch(settings.api().contacts + "/" + contactId, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setDialogTitle("Edit Contact");
                setContactId(contactId);
                setOpen(true);
                setContactId(contactId);
                setContact(data);
                setOpen(true);
            });
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleSaveClose = () => {
        setOpen(false);
        setshowProgress(true);
        fetchContacts(0, 10, filterschool, filterboard, filterName);
    };

    return (
        <div>
            <Title
                title="Contact List"
                subtitle="Contacts"
                desc="This page lists all PES Contacts. You can use filters to search for a particular contact or view and edit their details. Since contacts are linked to school, you need to add contacts from individual School pages."
            />
            <Card>
                <CardHeader title="Search"></CardHeader>
                <Divider />
                <CardContent className={classes.content}>
                    <div></div>
                    <table>
                        <tbody>
                            <TableRow>
                                <TableCell width={300}>
                                    <TextField
                                        style={{ width: "100%" }}
                                        label="School"
                                        id="txt_School"
                                        onChange={txt_School_Changed}
                                        value={filterschool}
                                        onKeyUp={(e) => txt_keyup(e, 1)}
                                    />
                                </TableCell>
                                <TableCell width={300}>
                                    <FormControl width={300} fullWidth className={classes.formControl}>
                                        <InputLabel>Board</InputLabel>
                                        <Select onChange={Board_Change} displayEmpty value={filterboard}>
                                            <MenuItem value="" primaryText=""></MenuItem>
                                            <MenuItem value=" " primaryText="">
                                                --Select--
                                            </MenuItem>
                                            {boards.map((option) => (
                                                <MenuItem value={option.value} primaryText={option.value}>
                                                    {option.value}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell width={250}>
                                    <TextField
                                        style={{ width: "100%" }}
                                        label="Name"
                                        id="txt_Name"
                                        onChange={txt_Name_Changed}
                                        value={filterName}
                                        onKeyUp={(e) => txt_keyup(e, 2)}
                                    />
                                </TableCell>
                                <TableCell width={250}>
                                    <TextField
                                        style={{ width: "100%" }}
                                        label="Email"
                                        id="txt_Email"
                                        onChange={txt_Email_Changed}
                                        value={filterEmail}
                                        onKeyUp={(e) => txt_keyup(e, 3)}
                                    />
                                </TableCell>
                                <TableCell width={100}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={(e) => {
                                            clearFilter();
                                        }}
                                    >
                                        Clear
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </tbody>
                    </table>
                </CardContent>
                <Divider />
            </Card>
            <Card>
                <CardHeader title="All Contacts"></CardHeader>
                <Divider />
                <CardContent className={classes.content}>
                    <div className={classes.inner}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Full Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>School</TableCell>
                                    <TableCell>Board</TableCell>
                                    <TableCell>Title</TableCell>
                                    <TableCell>Role</TableCell>
                                    <TableCell align="center">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {showProgress === true ? (
                                    <TableRow>
                                        <TableCell colSpan={7} align="center">
                                            <Progress />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    contacts.map((contact) => (
                                        <TableRow hover>
                                            <TableCell>
                                                <div className={classes.nameCell}>
                                                    <Avatar className={classes.avatar}>
                                                        <ListIcon />
                                                    </Avatar>
                                                    <Typography variant="h6">
                                                        {contact.firstName + " " + contact.lastName}
                                                    </Typography>
                                                </div>
                                            </TableCell>
                                            <TableCell>{contact.email}</TableCell>
                                            <TableCell component={RouterLink} to={`/crm/schools/${contact.schoolId}`}>
                                                {contact.school}
                                            </TableCell>
                                            <TableCell>{contact.board}</TableCell>
                                            <TableCell>{contact.title}</TableCell>
                                            <TableCell>{contact.role}</TableCell>
                                            <TableCell align="center">
                                                <Button
                                                    color="primary"
                                                    component={RouterLink}
                                                    size="small"
                                                    to={`/crm/contacts/${contact.id}`}
                                                    variant="outlined"
                                                    style={{ "margin-top": "5px", "margin-left": "5px" }}
                                                >
                                                    View
                                                </Button>

                                                <Button
                                                    color="primary"
                                                    onClick={() => handleClickEditContact(contact.id)}
                                                    size="small"
                                                    variant="outlined"
                                                    value={contact.id}
                                                    style={{ "margin-top": "5px", "margin-left": "5px" }}
                                                >
                                                    Edit
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        colSpan={7}
                                        count={pagination.totalCount}
                                        rowsPerPage={rowsPerPage}
                                        rowsPerPageOptions={[5, 10, 25, 50]}
                                        page={page}
                                        backIconButtonProps={{
                                            "aria-label": "Previous Page",
                                        }}
                                        nextIconButtonProps={{
                                            "aria-label": "Next Page",
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangePageSize}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </div>
                </CardContent>
            </Card>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography componet="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                            {dialogTitle}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <ContactForm contactId={contactId} contactDetail={contact} updated={handleSaveClose} />
            </Dialog>
        </div>
    );
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
