import React from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Popover from "@material-ui/core/Popover";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    paper: {
        marginRight: theme.spacing(2),
    },
}));

export default function UserMenu() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const history = useHistory();

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const navigateProfile = (event) => {
        handleClose(event);

        history.push("/crm/profile");
    };

    const logout = (event) => {
        localStorage.removeItem("pes-crm-token");
        history.push("/login");

        // event.preventDefault();
        // fetch('/api/logout', {
        //     method: 'GET',
        // })
        // .then(res => {
        //     console.log(res.status);
        //     if (res.status === 200){

        //         console.log('working');
        //         //
        //         history.push('/crm');

        //     }
        //     else {
        //         console.log('throwing!');
        //         const error = new Error(res.error);
        //         throw error;
        //     }
        // })
        // .catch (err => {
        //     console.error(err);
        //     alert('Error logging out');
        // })

        handleClose(event);

        //document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";

        //localStorage.removeItem('token');
        //console.log('token removed');
        //console.log('--->' + localStorage.getItem('token'));

        //history.push('/');
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div className={classes.root}>
            <div>
                <IconButton
                    color="inherit"
                    ref={anchorRef}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                >
                    <Badge color="secondary">
                        <AccountCircle />
                    </Badge>
                </IconButton>
                <Popover
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    transformOrigin={{ horizontal: "left", vertical: "top" }}
                    transition
                    disablePortal
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                <MenuItem onClick={navigateProfile}>Profile</MenuItem>
                                <MenuItem onClick={logout}>Logout</MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Popover>
            </div>
        </div>
    );
}
