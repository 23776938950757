import React, { useState, useEffect, useRef } from "react";
import {
    //    Avatar,
    Button,
    Card,
    CardContent,
    //    CardHeader,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    //    TablePagination,
    TableFooter,
    Typography,
    Tabs,
    Box,
    Tab,
    //    TextField,
    //SelectField ,
    //    MenuItem,
    //    FormControl,
    //    InputLabel,
    //    Select,
    Dialog,
    DialogTitle,
    //    AppBar,
    //    Toolbar,
    //    Slide,
    //    IconButton
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
//import Autocomplete from '@material-ui/lab/Autocomplete';
//import AddIcon from '@material-ui/icons/Add';
//import WarningIcon from '@material-ui/icons/Warning';
//import ListIcon from '@material-ui/icons/School';
//import CloseIcon from '@material-ui/icons/Close';
//import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";
import Moment from "moment";

import settings from "../../../settings";
//import Title from '../../Helpers/Titles';

import Progress from "../../Helpers/Progress";
//import { useDispatch, useSelector } from "react-redux";

//import fileDownload from 'js-file-download';
//import { saveAs } from 'file-saver';

const useStyles = makeStyles((theme) => ({
    root: {},

    inner: {
        minWidth: 700,
    },
    nameCell: {
        display: "flex",
        alignItems: "center",
    },
    avatar: {
        height: 42,
        width: 42,
        backgroundColor: blue[500],
        marginRight: theme.spacing(1),
    },
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    orderDetail: {
        fontSize: 16,
        padding: 5,
        marginLeft: 6,
        fontWeight: 500,
    },
    textRight: {
        textAlign: "right",
    },
    marginRight: {
        marginRight: 10,
    },
}));

/*const gridStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
}));*/
const tabs = [
    {
        title: "Planners",
        index: 0,
    },
    {
        title: "Other",
        index: 1,
    },
];
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function indexProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

export default function Order({ match }) {
    const classes = useStyles();
    //const gridClasses = gridStyles();

    const [order, setOrder] = useState({});
    //const [orderStyle, setOrderStyle] = useState({});
    const [boxes, setBoxes] = useState([]);
    const [tracking, setTracking] = useState([]);
    const [trackingPopup, setTrackingPopup] = useState(false);
    //const [open, setOpen] = React.useState(false);
    //const [firstLoad, setfirstLoad] = React.useState(true);
    const [showProgress, setshowProgress] = useState([]);
    const [tabValue, setTabValue] = useState(0);

    const token = localStorage.getItem("pes-crm-token");
    // let mounted = true;
    const mounted = useRef(true);

    const orderId = match.params.id;

    const fetchOrder = () => {
        setshowProgress(true);
        fetch(settings.api().orders + "/" + orderId, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (mounted.current) {
                    setOrder(data);
                    setshowProgress(false);
                }
            });
    };
    const fetchBoxes = () => {
        fetch(settings.api().orders + "/" + orderId + "/boxes", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (mounted.current) {
                    let array = [];
                    let arrayData = [];
                    for (let i = 0; i < data.length; i++) {
                        data[i].index = array[data[i].totalBoxes]
                            ? array[data[i].totalBoxes]
                            : (array[data[i].totalBoxes] = 1);

                        array[data[i].totalBoxes] = array[data[i].totalBoxes] + 1;
                        arrayData.push(data);
                    }
                    setBoxes(data);
                    setshowProgress(false);
                }
            });
    };

    const closeModalPopup = () => {
        setTrackingPopup(false);
    };

    useEffect(() => {
        setshowProgress(true);
        fetchOrder();
        fetchBoxes();
        return () => {
            mounted.current = false;
        };
    }, []);

    const downloadInvoice = async (orderId) => {
        const response = await fetch(settings.api().orders + "/" + orderId + "/invoice", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        response.blob().then((blob) => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = url;
            a.download = orderId + ".pdf";
            a.click();
        });
        /*if(response.headers.get('Content-Disposition')) {
		 const filename = response.headers.get('Content-Disposition')
			  .split(';')
			  .find(n => n.includes('filename='))
			  .replace('filename=', '')
			  .trim()
			;

			const blob = await response.blob();

			// Download the file
			saveAs(blob, filename);
		}
		fileDownload(response.body, orderId+'.pdf');*/
    };
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const checkTracking = async (boxId) => {
        fetch(settings.api().boxes + "/" + boxId + "/tracking", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (mounted.current) {
                    setTracking(data);
                    setTrackingPopup(true);
                }
            })
            .catch((err) => {
                console.log("something went wrong");
            });
    };

    return (
        <div>
            {showProgress === false ? (
                <Card>
                    <CardContent className={classes.content}>
                        <Grid container spacing={0}>
                            <Grid item xs={9}>
                                <p>
                                    <h1>Order: #{order.orderId}</h1>
                                </p>
                            </Grid>
                        </Grid>
                        <Divider />

                        <Grid container className={classes.root}>
                            <Grid item xs={12}>
                                <Grid container justify="space-between">
                                    <Grid item xs={6}>
                                        <div className={classes.orderDetail}>{order.schoolName}</div>
                                        <div className={classes.orderDetail}>{order.boardName}</div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className={`${classes.textRight} ${classes.marginRight}`}>
                                            <div className={classes.orderDetail}>
                                                {order.orderId > 48000 ? (
                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={(e) => {
                                                            downloadInvoice(order.orderId);
                                                        }}
                                                        value={order.orderId}
                                                    >
                                                        Invoice
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        color="secondary"
                                                        variant="outlined"
                                                        onClick={(e) => {
                                                            downloadInvoice(order.orderId);
                                                        }}
                                                        value={order.orderId}
                                                    >
                                                        Invoice
                                                    </Button>
                                                )}
                                            </div>
                                            <div className={classes.orderDetail}>
                                                <span
                                                    style={{
                                                        "background-color": `#${order.orderStatusBackColour}`,
                                                        color: `#${order.orderStatusForeColour}`,
                                                        padding: "3px",
                                                    }}
                                                >
                                                    {order.orderStatus}
                                                </span>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <div className={classes.root}>
                            <Paper elevation={1} variant="outlined" square className={classes.elevation2}>
                                <Tabs value={tabValue} onChange={handleTabChange} aria-label="information">
                                    {tabs.map((option) => (
                                        <Tab label={option.title} {...indexProps(option.index)} />
                                    ))}
                                </Tabs>
                                <Divider />
                                <TabPanel value={tabValue} index={0}>
                                    <div className={classes.inner}>
                                        <Grid container className={classes.root}>
                                            <Grid item xs={12}>
                                                <Grid container justify="space-between">
                                                    <Grid item xs={6}>
                                                        <div>
                                                            Order Total: ${" "}
                                                            {new Intl.NumberFormat("en-IN", {
                                                                maximumSignificantDigits: "5",
                                                            }).format(order.orderTotal)}
                                                        </div>
                                                        <div>
                                                            Ship Release Date:{" "}
                                                            {Moment(order.oshawaReleaseDateUtc)
                                                                .local()
                                                                .format("DD-MMM-YYYY")}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div className={`${classes.textRight} ${classes.marginRight}`}>
                                                            <div>Order Contact</div>
                                                            <div>{order.orderContactName}</div>
                                                            <div>
                                                                <a href={`mailto:${order.orderContactEmail}`}>
                                                                    {order.orderContactEmail}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left">Planner</TableCell>
                                                    <TableCell align="left">Boxes</TableCell>
                                                    <TableCell align="left">Tracking #</TableCell>
                                                    <TableCell align="left">Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {boxes.map((box) => (
                                                    <TableRow hover key={box.boxId}>
                                                        <TableCell>{box.planner}</TableCell>
                                                        <TableCell>
                                                            {box.index}/{box.totalBoxes}
                                                        </TableCell>
                                                        <TableCell>
                                                            {box.trackingNum !== "" ? box.trackingNum : ""}
                                                        </TableCell>
                                                        <TableCell>
                                                            {box.trackingNum !== "" ? (
                                                                <Button
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    value={box.trackingNum}
                                                                    onClick={(e) => {
                                                                        checkTracking(box.boxID);
                                                                    }}
                                                                >
                                                                    Track
                                                                </Button>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                            <TableFooter></TableFooter>
                                        </Table>
                                    </div>
                                </TabPanel>
                                <TabPanel value={tabValue} index={1}>
                                    <div>Others</div>
                                </TabPanel>
                            </Paper>
                        </div>
                    </CardContent>
                    <Dialog open={trackingPopup} onClose={closeModalPopup}>
                        <DialogTitle id="simple-dialog-title">Tracking Details</DialogTitle>
                        {tracking.length > 0 ? (
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Date</TableCell>
                                        <TableCell align="left">City</TableCell>
                                        <TableCell align="left">Description</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tracking.map((track, index) => (
                                        <TableRow hover key={index}>
                                            <TableCell>
                                                {Moment(track.eventDate).local().format("DD-MMM-YYYY HH:SS:MM")}
                                            </TableCell>
                                            <TableCell>{track.city}</TableCell>
                                            <TableCell>{track.description}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <TableFooter></TableFooter>
                            </Table>
                        ) : (
                            <div>
                                {" "}
                                <p>No tracking detail available</p>
                            </div>
                        )}
                    </Dialog>
                </Card>
            ) : (
                <Progress />
            )}
        </div>
    );
}

/*const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });*/
