import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { DateRangePicker, DateRangeDelimiter, LocalizationProvider } from "@material-ui/pickers";
//import DateFnsUtils from "@material-ui/pickers/adapter/date-fns"; // choose your lib
import MomentUtils from "@date-io/moment";
import Moment from "moment";

export default function BasicDateRangePicker(props) {
    const [selectedDate, setSelectedDate] = useState([null]);
    const handleDateChange = (date) => {
        setSelectedDate(date);
        props.callBack(date);
    };
    useEffect(() => {
        setSelectedDate(props.date);
    }, []);

    return (
        <LocalizationProvider dateAdapter={MomentUtils}>
            <DateRangePicker
                startText="Start Date"
                endText="End Date"
                value={selectedDate}
                onChange={(date) => handleDateChange(date)}
                renderInput={(startProps, endProps) => (
                    <>
                        <TextField {...startProps} />

                        <TextField {...endProps} />
                    </>
                )}
            />
        </LocalizationProvider>
    );
}
