import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTableRow from './MuiTableRow';

export default {
    MuiTableCell,
    MuiTableHead,
    MuiTableRow
};

