import React from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import ExitIcon from '@material-ui/icons/ExitToApp';
import SecureIcon from '@material-ui/icons/Https';


const useStyles = makeStyles((theme) => ({
    containerBlock: {
        textAlign: 'center',
        maxWidth: '850px',
        padding: '0px 20px',
        color: '#fff',
    },
    background: {
        backgroundColor: '#fff',
        padding: '20px',
        border: '1px solid #ccc'
    },
    titleText: {
        color: '#fff',
        fontWeight: 'bold',
        marginBottom: '25px',
    },
    button: {
        margin: theme.spacing(1),
    },
    subtitleText: {
        color: '#fff',
    }

}));

export default function HomePanel() {

    const classes = useStyles();

    return (
        <div className={classes.containerBlock}>
            <Typography component="h1" variant="h3" className={classes.titleText} >
                First Class CRM
            </Typography>
            <Typography variant="h4" className={classes.subtitleText} paragraph>
                First Class Planners CRM is an internal application for managing Student Planners across Canada.
            </Typography>
            <Button component={Link} to="/crm/" variant="contained" color="primary" size="large" className={classes.button} startIcon={<SecureIcon />}>
                Sign In
            </Button>
            <Button href="https://www.firstclassplanners.ca/" target="_blank" variant="contained" color="primary" size="large" className={classes.button} startIcon={<ExitIcon />}>
                First Class Website
            </Button>
        </div>
    );
}