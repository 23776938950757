import {
    AppBar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    makeStyles,
    Slide,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Toolbar,
    Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import OrderRequest from "./OrderRequest";

import CloseIcon from "@material-ui/icons/Close";
import settings from "../../../../settings";

const useStyles = makeStyles((theme) => ({
    newContract: {
        margin: theme.spacing(2, 0),
    },
    actionBtns: {
        margin: theme.spacing(0, 1),
    },
}));

const Contracts = (props) => {
    const classes = useStyles();
    const [schoolInfo, setSchoolInfo] = useState({});
    const [correctSchool, setCorrectSchool] = useState(false);
    const [createContract, setCreateContract] = useState(false);
    const [contractStatus, setContractStatus] = useState("");
    const [contractId, setContractId] = useState(0);
    const [contracts, setContracts] = useState([]);

    const [contractAdded, setContractAdded] = useState(false);
    const [popupMeassge, setPopupMessage] = useState("");

    const isManager = JSON.parse(localStorage.getItem("user-data")).crmRoleId === 2;

    useEffect(() => {
        let tmp = JSON.parse(localStorage.getItem("school"));
        setSchoolInfo(tmp);

        const token = localStorage.getItem("pes-crm-token");

        fetch(settings.api().schools + `/${tmp.id}/contracts`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.json())
            .then((data) => setContracts(data));
    }, []);

    //submitting contract form
    const handleContractSaveClose = () => {
        setCreateContract(false);
    };

    //creating new contract form
    const handleNewContract = () => {
        setCorrectSchool(false);
        setCreateContract(true);
    };

    const confirmSchool = (status, id) => {
        setContractStatus(status);
        setContractId(id);
        setCorrectSchool(true);
    };

    const handleClose = () => {
        setCorrectSchool(false);
    };

    const getDateFormat = (dateString) => {
        let d = new Date(dateString);
        d = new Date(d.getTime() - d.getTimezoneOffset() * 60000);

        const year = d.getFullYear();
        const month = d.getMonth() + 1;
        const date = d.getDate();
        let hour = d.getHours();
        let min = d.getMinutes();

        let ampm = "am";
        if (hour > 12) {
            hour -= 12;
            ampm = "pm";
        }
        if (min < 10) {
            min = "0" + min;
        }

        return year + "-" + month + "-" + date + " " + hour + ":" + min + " " + ampm;
    };

    const downloadContract = async (cId) => {
        const token = localStorage.getItem("pes-crm-token");
        const response = await fetch(settings.api().contract + "/" + cId + "/download", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        response.blob().then((blob) => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = url;
            a.download = "ContractID" + cId + ".pdf";
            a.click();
        });
    };

    return (
        <div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Contract ID</TableCell>
                        <TableCell align="center">State</TableCell>
                        <TableCell align="center">School Year</TableCell>
                        <TableCell align="center">Created</TableCell>
                        <TableCell align="center">Modified</TableCell>
                        <TableCell align="center">Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {/* ================MAP CONTRACTS HERE================ */}
                    {contracts.length !== 0 &&
                        contracts.map((c) => (
                            <TableRow key={c.contractId}>
                                <TableCell>{c.contractId}</TableCell>
                                <TableCell align="center">{c.status}</TableCell>
                                <TableCell align="center">{c.schoolYear}</TableCell>
                                <TableCell align="center">{getDateFormat(c.createdUtc)}</TableCell>
                                <TableCell align="center">{getDateFormat(c.modifiedUtc)}</TableCell>

                                <TableCell align="center">
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        className={classes.actionBtns}
                                        onClick={() => downloadContract(c.contractId)}
                                    >
                                        Download
                                    </Button>
                                    {c.schoolYear === "2024-2025" &&
                                        (isManager || (c.status !== "Completed" && c.status !== "Signed")) && (
                                            <Button
                                                color="primary"
                                                variant="outlined"
                                                onClick={() => confirmSchool("EDIT", c.contractId)}
                                                className={classes.actionBtns}
                                            >
                                                Edit
                                            </Button>
                                        )}
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
            <Button
                onClick={() => confirmSchool("CREATE")}
                variant="contained"
                className={classes.newContract}
                color="primary"
            >
                New Contract
            </Button>
            <Dialog open={correctSchool} onClose={handleClose}>
                <DialogTitle disableTypography>
                    <Typography variant="subtitle1">
                        {contractStatus === "CREATE" ? "New" : "Edit"} contract for{" "}
                        <strong>{schoolInfo.schoolName}</strong>
                    </Typography>
                </DialogTitle>

                <DialogContent>
                    <DialogContentText variant="subtitle1">
                        Please confirm that you have the correct <u>{schoolInfo.schoolName}</u>:<br />
                        <br />
                        Address:{" "}
                        <strong>
                            {schoolInfo.address1}, {schoolInfo.city}, {schoolInfo.provCode}, {schoolInfo.postal}
                            <br />
                        </strong>
                        Board: <strong>{schoolInfo.boardName}</strong>
                        <br />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleNewContract} color="primary">
                        Continue
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullScreen
                open={createContract}
                onClose={handleContractSaveClose}
                TransitionComponent={Transition}
                scroll="paper"
            >
                <AppBar>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleContractSaveClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            componet="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            //   className={classes.title}
                        >
                            {/* {dialogTitle} */}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <OrderRequest
                    schoolInfo={schoolInfo}
                    closeDialog={handleContractSaveClose}
                    contractStatus={contractStatus}
                    contractId={contractId}
                    success={(title) => {
                        setPopupMessage(title);
                        setContractAdded(true);
                    }}
                />
            </Dialog>

            <Dialog open={contractAdded}>
                <DialogTitle>
                    <Typography variant="subtitle1">{popupMeassge}</Typography>
                </DialogTitle>
                <DialogActions>
                    <Button
                        onClick={() => {
                            window.location.reload();
                            setContractAdded(false);
                        }}
                        color="primary"
                    >
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default Contracts;
