import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import {
    TextField,
    Grid,
    Card,
    CardContent,
    Checkbox,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    Button,
} from "@material-ui/core";
import Progress from "../../Helpers/Progress";

import settings from "../../../settings";
//import Title from '../../Helpers/Titles';

// const useStyles = makeStyles((theme) => ({
//     appBar: {
//         position: "relative",
//     },
//     title: {
//         marginLeft: theme.spacing(2),
//         flex: 1,
//     },
// }));

class SchoolForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            boards: [],
            boardValue: "",
            provincesValue: "",
            provinces: [],
            school: [],
            error: {},
            showProgress: true,
        };
    }
    componentDidMount = async (event) => {
        this.fetchBoards();
        let JSONstr = localStorage.getItem("school");
        if (JSONstr !== undefined && JSONstr !== null && JSONstr !== "") {
            let data = JSON.parse(JSONstr);
            this.setState({ school: data });
        }
    };
    fetchBoards = () => {
        let token = localStorage.getItem("pes-crm-token");
        fetch(settings.api().meta.boards, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                let arrBoard = [];
                let keys = Object.keys(data);
                for (let i = 0; i < keys.length; i++) {
                    if (data[i]) {
                        arrBoard.push({ id: keys[i], value: data[i] });
                    }
                }
                arrBoard = arrBoard.sort((a, b) => a.value.localeCompare(b.value));
                this.setState({ boards: arrBoard, showProgress: false });
            });

        fetch(settings.api().meta.provinces, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                //let i=1;
                let arrProvinces = [];
                let keys = Object.keys(data);
                for (let i = 0; i < keys.length; i++) {
                    arrProvinces.push({ id: keys[i], value: keys[i] });
                }
                this.setState({ provinces: arrProvinces });
            });
        //
    };
    province_change = (event) => {
        if (event.target.value !== undefined && event.target.value !== "" && event.target.value !== " ") {
            let value = event.target.value;
            this.setState((prevState) => ({
                school: { ...prevState.school, provCode: value },
            }));
        }
    };
    // board_change = (event) => {
    //   if (
    //     event.target.value !== undefined &&
    //     event.target.value !== "" &&
    //     event.target.value !== " "
    //   ) {
    //     let value = event.target.value;
    //     let boardId = this.state.boards.find(
    //       (element) => element.value === value
    //     );
    //     this.setState((prevState) => ({
    //       school: {
    //         ...prevState.school,
    //         boardID: boardId.id,
    //         boardName: value,
    //       },
    //     }));
    //   }
    // };

    isactive_change = (event) => {
        let value = event.target.checked;
        this.setState((prevState) => ({
            school: { ...prevState.school, active: value },
        }));
    };
    fieldChange = (event) => {
        if (event.target.value !== undefined && event.target.value !== "" && event.target.value !== " ") {
            let key = event.target.id;
            let value = event.target.value;
            this.setState((prevState) => ({
                school: { ...prevState.school, [key]: value },
            }));
        }
    };
    updateSchool = () => {
        this.setState({ error: {} });
        let isError = false;
        if (
            this.state.school.schoolName === "" ||
            this.state.school.schoolName === undefined ||
            this.state.school.schoolName === null
        ) {
            this.setState((prevState) => ({
                error: {
                    ...prevState.error,
                    nameError: true,
                    helperText: "Please Provide School Name",
                },
            }));
            isError = true;
        }
        if (
            this.state.school.boardID === "" ||
            this.state.school.boardID === undefined ||
            this.state.school.boardID === null
        ) {
            this.setState((prevState) => ({
                error: {
                    ...prevState.error,
                    boardError: true,
                    boardHelperText: "Please Board Value",
                },
            }));
            isError = true;
        }
        if (
            this.state.school.provCode === "" ||
            this.state.school.provCode === undefined ||
            this.state.school.provCode === null
        ) {
            this.setState((prevState) => ({
                error: {
                    ...prevState.error,
                    provCodeError: true,
                    provCodeHelperText: "Please Provide Province",
                },
            }));
            isError = true;
        }
        if (this.state.school.city === "" || this.state.school.city === undefined || this.state.school.city === null) {
            this.setState((prevState) => ({
                error: {
                    ...prevState.error,
                    cityError: true,
                    cityHelperText: "Please Provide City",
                },
            }));
            isError = true;
        }
        if (
            this.state.school.postal === "" ||
            this.state.school.postal === undefined ||
            this.state.school.postal === null
        ) {
            this.setState((prevState) => ({
                error: {
                    ...prevState.error,
                    postalError: true,
                    postalHelperText: "Please Provide Postal",
                },
            }));
            isError = true;
        }
        if (
            this.state.school.address1 === "" ||
            this.state.school.address1 === undefined ||
            this.state.school.address1 === null
        ) {
            this.setState((prevState) => ({
                error: {
                    ...prevState.error,
                    addressError: true,
                    addressHelperText: "Please Provide Address1",
                },
            }));
            isError = true;
        }
        if (
            this.state.school.phone === "" ||
            this.state.school.phone === undefined ||
            this.state.school.phone === null
        ) {
            this.setState((prevState) => ({
                error: {
                    ...prevState.error,
                    phoneError: true,
                    phoneHelperText: "Please Provide Phone",
                },
            }));
            isError = true;
        }
        if (this.state.school.fax === "") {
            this.state.school.fax = null;
            // this.setState((prevState) => ({
            //     school: {
            //         fax: null,
            //     },
            // }));
        }

        if (isError) return;
        let token = localStorage.getItem("pes-crm-token");
        let schoolData = this.state.school;
        fetch(settings.api().schools + "/" + this.state.school.id, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(schoolData),
        })
            .then((data) => {
                this.props.saved();
            })
            .catch((err) => {
                alert("data can not be saved");
            });
    };

    render() {
        return (
            <div>
                <Grid container spacing={2} direction="column" alignItems="center" style={{ minHeight: "100vh" }}>
                    {this.state.showProgress === true ? (
                        <Grid item xs={6}>
                            <Progress />
                        </Grid>
                    ) : (
                        <Grid item xs={10}>
                            <Card style={{ "padding-top": "20px" }}>
                                <CardContent>
                                    <br />
                                    <Grid xs={12} container spacing={1}>
                                        <Grid xs={5} container spacing={1}>
                                            <Grid item xs={12}>
                                                {" "}
                                                <TextField
                                                    id="schoolName"
                                                    onChange={this.fieldChange}
                                                    fullWidth
                                                    label="School Name"
                                                    value={this.state.school.schoolName}
                                                    helperText={this.state.error.nameHelperText}
                                                    error={this.state.error.nameError}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl width={300} fullWidth error={this.state.error.boardError}>
                                                    <InputLabel>Boards </InputLabel>
                                                    <Select
                                                        disabled
                                                        id="board"
                                                        displayEmpty
                                                        value={this.state.school.boardName}
                                                        onChange={this.board_change}
                                                    >
                                                        <MenuItem value="" primaryText=""></MenuItem>
                                                        {this.state.boards.map((option) => (
                                                            <MenuItem value={option.value} primaryText={option.value}>
                                                                {option.value}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {this.state.error.boardError ? (
                                                        <FormHelperText>
                                                            {this.state.error.boardHelperText}
                                                        </FormHelperText>
                                                    ) : (
                                                        ""
                                                    )}
                                                </FormControl>
                                                <Divider />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="address1"
                                                    onChange={this.fieldChange}
                                                    fullWidth
                                                    label="Address 1"
                                                    value={this.state.school.address1}
                                                    helperText={this.state.error.addressHelperText}
                                                    error={this.state.error.addressError}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="address2"
                                                    onChange={this.fieldChange}
                                                    fullWidth
                                                    label="Address 2"
                                                    value={this.state.school.address2}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="city"
                                                    onChange={this.fieldChange}
                                                    fullWidth
                                                    label="City"
                                                    value={this.state.school.city}
                                                    helperText={this.state.error.cityHelperText}
                                                    error={this.state.error.cityError}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl
                                                    width={300}
                                                    fullWidth
                                                    error={this.state.error.provCodeError}
                                                >
                                                    <InputLabel>Provinces</InputLabel>
                                                    <Select
                                                        id="prov"
                                                        displayEmpty
                                                        value={this.state.school.provCode}
                                                        onChange={this.province_change}
                                                    >
                                                        <MenuItem value="" primaryText=""></MenuItem>
                                                        {this.state.provinces.map((option) => (
                                                            <MenuItem value={option.value} primaryText={option.value}>
                                                                {option.value}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {this.state.error.provCodeError ? (
                                                        <FormHelperText>
                                                            {this.state.error.provCodeHelperText}
                                                        </FormHelperText>
                                                    ) : (
                                                        ""
                                                    )}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="postal"
                                                    onChange={this.fieldChange}
                                                    fullWidth
                                                    label="Postal"
                                                    value={this.state.school.postal}
                                                    helperText={this.state.error.postalHelperText}
                                                    error={this.state.error.postalError}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid xs={1} container spacing={1}></Grid>
                                        <Grid xs={5} container spacing={1}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="phone"
                                                    onChange={this.fieldChange}
                                                    fullWidth
                                                    label="Phone"
                                                    value={this.state.school.phone}
                                                    helperText={this.state.error.phoneHelperText}
                                                    error={this.state.error.phoneError}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="fax"
                                                    onChange={this.fieldChange}
                                                    fullWidth
                                                    label="Fax"
                                                    value={this.state.school.fax}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Checkbox
                                                    checked={this.state.school.active ? true : false}
                                                    label="Active"
                                                    color="primary"
                                                />{" "}
                                                Active
                                            </Grid>
                                            <Grid item xs={12}></Grid>
                                            <Grid item xs={12}></Grid>
                                            <Grid item xs={12}></Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="notes"
                                                    multiline
                                                    fullWidth
                                                    label="Notes"
                                                    variant="outlined"
                                                    rows="4"
                                                    onChange={this.fieldChange}
                                                    value={this.state.school.notes}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    aria-label="Save"
                                                    onClick={this.updateSchool}
                                                >
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                            <div></div>
                        </Grid>
                    )}
                </Grid>
            </div>
        );
    }
}
export default SchoolForm;
