import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
//import Dialog from '@material-ui/core/Dialog';
//import ListItemText from '@material-ui/core/ListItemText';
//import ListItem from '@material-ui/core/ListItem';
//import List from '@material-ui/core/List';
//import Divider from '@material-ui/core/Divider';
//import AppBar from '@material-ui/core/AppBar';
//import Toolbar from '@material-ui/core/Toolbar';
//import IconButton from '@material-ui/core/IconButton';
//import Typography from '@material-ui/core/Typography';
//import CloseIcon from '@material-ui/icons/Close';
//import Slide from '@material-ui/core/Slide';
import SaveIcon from "@material-ui/icons/Save";
import Progress from "../../Helpers/Progress";
import {
    TextField,
    Grid,
    Card,
    CardContent,
    //    CardHeader,
    //    Checkbox,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@material-ui/core";

import settings from "../../../settings";
const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

export default function NoteForm({ schoolId, onSaved }) {
    const classes = useStyles();
    const token = localStorage.getItem("pes-crm-token");
    const [schoolyears, setSchoolyears] = useState([]); //
    const [showProgress, setshowProgress] = useState([]);
    //
    const [schoolnote, setSchoolnote] = useState(""); //
    const [schoolyear, setSchoolyear] = useState(0); //

    //const onSaved
    const fetchYears = () => {
        fetch(settings.api().meta.schoolyears, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                let i = 1;
                let arrSchoolyears = [];
                while (data[i] !== undefined) {
                    arrSchoolyears.push({ id: i, value: data[i] });
                    i++;
                }
                setSchoolyears(arrSchoolyears);
            });
    };
    const handleClickSave = () => {
        if (schoolnote !== "" && schoolyear > 0) {
            setshowProgress(true);
            fetch(settings.api().schools + "/" + schoolId + "/notes", {
                method: "POST",
                body: JSON.stringify({ Note: schoolnote, SchoolYearId: schoolyear }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => {
                    if (res.ok) {
                        setshowProgress(false);
                        onSaved({ message: '"Note" Saved !', isSuccess: true });
                    } else {
                        const error = new Error(res.error);
                        throw error;
                    }
                })
                .then((data) => {})
                .catch((err) => {});
        }
    };
    const year_Change = (event, value) => {
        if (event.target.value !== "") {
            setSchoolyear(event.target.value);
        }
    };
    const note_Change = (event, value) => {
        setSchoolnote(event.target.value);
    };
    useEffect(() => {
        fetchYears();
        setshowProgress(false);
        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Grid container spacing={2} direction="column" alignItems="center" style={{ minHeight: "100vh" }}>
                <Grid item xs={6}>
                    <Card style={{ "padding-top": "20px" }}>
                        <CardContent className={classes.content}>
                            <br />
                            {showProgress === true ? (
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <Progress />
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <FormControl
                                            style={{ width: "300px", "margin-bottom": "20px" }}
                                            className={classes.formControl}
                                        >
                                            <InputLabel>School years</InputLabel>
                                            <Select onChange={year_Change}>
                                                <MenuItem value="" primaryText=""></MenuItem>
                                                <MenuItem value=" " primaryText="">
                                                    --Select--
                                                </MenuItem>
                                                {schoolyears.map((option) => (
                                                    <MenuItem value={option.id} primaryText={option.value}>
                                                        {option.value}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="standard-basic"
                                            onChange={note_Change}
                                            multiline
                                            fullWidth
                                            label="Notes"
                                            variant="outlined"
                                            rows="4"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        &nbsp;
                                    </Grid>
                                    <Grid item xs={12} style={{ "text-align": "right" }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            aria-label="Save"
                                            onClick={handleClickSave}
                                        >
                                            <SaveIcon /> Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                        </CardContent>
                    </Card>
                    <div></div>
                </Grid>
            </Grid>
        </div>
    );
}
