import React, { useState, useEffect } from 'react';
//import { makeStyles } from '@material-ui/core/styles';
//import Paper from '@material-ui/core/Paper';
//import Grid from '@material-ui/core/Grid';
import {
//    Avatar,
//    Button,
    Card,
    CardContent,
//    CardHeader,
//    Divider,
//    Table,
//    TableBody,
//    TableCell,
//    TableHead,
//    TableRow,
//    Typography
} from '@material-ui/core';

//import settings from '../../../settings';
import Title from '../../Helpers/Titles';
import Progress from '../../Helpers/Progress';


export default function Rep({match}){    
    const initialRep = {
        id: 1,
        Name:"Andrea",
        CRMEnabled:true,
        Active:true
    }

    //const [rep, setRep] = useState({});
    const [showProgress, setshowProgress] = useState([]);

    useEffect(() => {
        //let mounted = true;
        //const repId = match.params.id;
        //const token = localStorage.getItem('pes-crm-token')
        setshowProgress(true);
       
        const fetchReps = () => {
           /* 
           Rep API
           fetch(settings.api().reps + '/' + repId, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(response => response.json())
            .then((data) => {
                if (mounted) {
                    setRep(data);
                    setshowProgress(false);
                }
            })
            */
           setshowProgress(false);
        }

        fetchReps();

        return () => {
            //mounted = false;
        };

    }, []);

    
        return (
            <div>
        {showProgress === true ? <Progress />:
            <div>                
                <Title title={"Sales Rep: " + initialRep.Name} subtitle="Sales" desc="Once integrated with the API, this page will list PES Schools. Currently we're listing static sample data only. The old system listed Users. Not sure how these relate to PES." />
            <Card>
                <CardContent >
                <div style={{"fontSize":15}}>                          
                        <p><b>Name: </b>{initialRep.Name}</p>
                        <p><b>CRM Enabled: </b>{initialRep.CRMEnabled === true?'Yes':'No'}</p>
                        <p><b>Active: </b>{initialRep.Active === true?'Yes':'No'}</p>
                    </div>
                </CardContent>
            </Card>
            </div>
    }</div>
        );
    
}

