import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
    AppBar,
    Box,
    Button,
    Card,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    List,
    ListItem,
    ListSubheader,
    makeStyles,
    MenuItem,
    Select,
    Slide,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Toolbar,
    Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import ContactForm from "../../Contacts/ContactForm";
import CreateContactForm from "../../Contacts/CreateContactForm";
import CurrencyInput from "react-currency-input";
import settings from "../../../../settings";

import moment from "moment";
import { DateRangePicker, DateRangeDelimiter, LocalizationProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(11, 5, 7),
        overflow: "scroll",
    },
    contactBtn: {
        marginBottom: 0,
        marginLeft: 0,
    },
    spaceLeft: {
        margin: theme.spacing(0, 0, 0, 1),
    },
    spaceRight: {
        margin: theme.spacing(0, 1, 0, 0),
    },
    notesBox: {
        padding: theme.spacing(1),
        borderWidth: 1,
        borderColor: "black",
        borderStyle: "solid",
        borderRadius: "1%",
    },
    notesBox2: {
        marginLeft: theme.spacing(0.5),
        padding: theme.spacing(1),
        borderWidth: 1,
        borderColor: "black",
        borderStyle: "solid",
        borderRadius: "1%",
        backgroundColor: "#ffebee",
    },
    internalNotes: {
        color: "#b71c1c",
    },
    box: {
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
    },
    table: {
        marginTop: theme.spacing(1),
    },
    disabledCell: {
        backgroundColor: "lightgrey",
    },
    noPadding: {
        padding: 0,
        margin: 0,
    },
    selectPadding: {
        padding: 0,
        margin: 0,
        backgroundColor: "white",
    },
    tableBorder: {
        borderWidth: 1,
        borderColor: "black",
        borderStyle: "solid",
    },
    noneditField: {
        backgroundColor: "#eeeeee",
    },
    frenchCol: {
        background: "#e1f5fe",
    },
    appBar: {
        position: "relative",
    },
    selectDelivery: {
        backgroundColor: "#ef5350",
        color: "white",
    },
    loading: {
        position: "relative",
        top: "50%",
        left: "50%",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    submitButton: {
        margin: theme.spacing(2, 3),
    },
    noDefaultBorder: {
        borderBottom: "none",
    },
    // signedCheck: {
    //     margin: theme.spacing(1),
    //     padding: theme.spacing(1),
    // },
}));

const OrderRequest = (props) => {
    const classes = useStyles();

    const [contacts, setContacts] = useState([]);
    const [structure, setStructure] = useState({});
    const [loading, setLoading] = useState(true);
    const [formValidationMessage, setFormValidationMessage] = useState("");
    const [missingBWPageMessage, setMissingBWPageMessage] = useState([]);

    const [value, setValue] = useState([null, null]);

    const [dateError, setDateError] = useState([null, null]);

    const [disableBtn, setDisableBtn] = useState(false);
    const [save, setSave] = useState([false, ""]);
    const [sendConfirmation, setSendConfirmation] = useState(false);

    const [initPlan, setInitPlan] = useState([]);
    const [localCosts, setLocalCosts] = useState({
        overrideUnitPrice: 0,
        deliveryWeek: "",
    });

    const [costs, setCosts] = useState({
        contractTemplateId: 0,
        schoolId: 0,
        contactPrimaryId: "",
        contactSecondaryId: "",
        poNumber: "",
        contractStatus: "",
        contractSeries: "",
        invoiceTo: "",
        shipTo: "",
        contractNotes: "",
        internalNotes: "",

        totalPrice: 0,
        totalQuantity: 0,
        unitPriceFrontCover: 0,
        unitPriceBackCover: 0,

        // customFrontCover: false,
        // customBackCover: false,

        schoolPageBWBoardUnits: 0,
        bwTotalPrice: 0,
        unitPriceSchoolPageBW: 0,

        colourTotalPrice: 0,
        unitPriceSchoolPageColour: 0,

        baseFee: 0,
        baseFeeDiscount: 0,

        calcSubtotal: 0,

        calcShipping: 0,
        shippingType: 2,
        gst: 0,
        pst: 0,
        hst: 0,
        calcGST: 0,
        calcPST: 0,
        calcHST: 0,
        calcTotal: 0,

        materialHandbook: false,
        materialHandbookDate: "",
        materialCover: false,
        materialCoverDate: "",

        hardCopy: false,
    });

    const [contactId, setContactId] = useState("");
    const [contact, setContact] = useState({});
    const [newContact, setNewContact] = useState(false);
    const [createContactOpen, setCreateContactOpen] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem("pes-crm-token");
        // get and set school contacts
        fetch(settings.api().schools + "/" + props.schoolInfo.id + "/contacts", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setContacts(data);
            });

        // either load a new contract or an existing contract form
        if (props.contractStatus === "CREATE") {
            fetch(settings.api().contractTemplate + `?schoolId=${props.schoolInfo.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    console.log(data);
                    setStructure(data);
                });
        } else {
            // loadExistingContract(token);
            fetch(settings.api().contract + "/" + props.contractId, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setCosts({ ...costs, ...data });
                    setInitPlan(data.planners);

                    fetch(
                        settings.api().contractTemplate +
                            `?schoolId=${props.schoolInfo.id}&templateId=${data.contractTemplateId}`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    )
                        .then((res) => res.json())
                        .then((data) => {
                            setStructure(data);
                        });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getTaxes = () => {
        let gst = 0,
            pst = 0,
            hst = 0;

        switch (props.schoolInfo.provCode) {
            case "AB":
                gst = 0.05;
                break;
            case "BC":
                gst = 0.05;
                pst = 0.07;
                break;
            case "MB":
                gst = 0.05;
                pst = 0.07;
                break;
            case "NB":
                hst = 0.15;
                break;
            case "NL":
                hst = 0.15;
                break;
            case "NT":
                gst = 0.05;
                break;
            case "NS":
                hst = 0.15;
                break;
            case "NU":
                gst = 0.05;
                break;
            case "ON":
                hst = 0.13;
                break;
            case "PE":
                hst = 0.15;
                break;
            case "QC":
                gst = 0.05;
                pst = 0.09975;
                break;
            case "SK":
                gst = 0.05;
                pst = 0.06;
                break;
            case "YT":
                gst = 0.05;
                break;
            default:
                break;
        }
        return [gst, pst, hst];
    };

    //===============when opening a existing contract form ===============
    // const loadExistingContract = (token) => {
    // if (props.contractStatus === "EDIT") {
    // const token = localStorage.getItem("pes-crm-token");

    // fetch(settings.api().contract + "/" + props.contractId, {
    //     headers: {
    //         Authorization: `Bearer ${token}`,
    //     },
    // })
    //     .then((res) => res.json())
    //     .then((data) => {
    //         setCosts({ ...costs, ...data });
    //         setInitPlan(data.planners);

    //         fetch(
    //             settings.api().contractTemplate +
    //                 `?schoolId=${props.schoolInfo.id}&templateId=${data.contractTemplateId}`,
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${token}`,
    //                 },
    //             }
    //         )
    //             .then((res) => res.json())
    //             .then((data) => {
    //                 setStructure(data);
    //             });
    //     });
    // }
    // };

    // =====setting all prices and others once contract-template is called============
    useEffect(() => {
        if (Object.entries(structure).length !== 0) {
            createCostsObjectInstance();
        }
    }, [structure]);

    Array.prototype.hasMin = function (attrib) {
        return (
            (this.length &&
                this.reduce(function (prev, curr) {
                    return prev[attrib] < curr[attrib] ? prev : curr;
                })) ||
            null
        );
    };

    const createCostsObjectInstance = () => {
        let rest = {};
        let plannerList = [];
        let plannerObj = {};
        let newList;
        let deliveryWeek;
        let [gst, pst, hst] = getTaxes();
        if (props.contractStatus === "EDIT") {
            newList = costs.planners;
        }
        // creating planners array for local uses
        structure.planners.forEach((p) => {
            const tmp = structure.plannerTypes.find((type) => {
                // console.log(type, type.id, p.plannerTypeId);
                return type.id === p.plannerTypeId;
            });

            if (tmp !== undefined) {
                // set sideId to 0 if sizes array is 0 or 2 or more
                if (p.sizes.length === 1) {
                    plannerObj = {
                        ...plannerObj,
                        sizeId: p.sizes[0].id,
                    };
                } else {
                    plannerObj = {
                        ...plannerObj,
                        sizeId: 0,
                    };
                }

                plannerObj = {
                    ...plannerObj,
                    templatePlannerId: p.templatePlannerId,
                    english: p.language === "English",
                    plannerTypeId: p.plannerTypeId,
                    quantity: 0,
                    unitPrice: p.unitPrice,
                    frontCoverId: 0,
                    backCoverId: 0,
                    schoolPagesBWPrice: structure.schoolPagesBWUnitPrice,
                    schoolPagesBWUnits: 0,
                    schoolPagesBWFree: 0,
                    schoolPagesColourUnits: 0,
                    supplements: [],
                };
                plannerList.push(plannerObj);
            }
        });
        let tmp_list = plannerList;
        // console.log(tmp_list);

        //adding in disabled column into planners list
        let i = 1;
        let j = 0;
        let index = 0;
        // while (index < tmp_list.length) {
        while (index < 10) {
            if (!!tmp_list[index] && tmp_list[index].plannerTypeId === i) {
                j++;
                if (j === 2) {
                    i++;
                    j = 0;
                }
            } else {
                if (j < 2) {
                    if (tmp_list[index - 1].english) {
                        tmp_list.splice(index, 0, { templatePlannerId: i });
                    } else {
                        tmp_list.splice(index - 1, 0, { templatePlannerId: i });
                    }
                }
                j = 0;
                i++;
            }
            index++;
        }

        plannerList = tmp_list;
        //creating local supplements variables for local uses
        structure.supplements.forEach((s) => {
            const name = s.title.replace(/\s|-/g, "");
            rest = {
                ...rest,
                [`unitPrice${name}`]: s.unitPrice,
                [`quantity${name}`]: 0,
                [`total${name}`]: 0,
            };
        });

        //setting all other variables for local display and calcuation purposes
        rest = {
            ...rest,
            contractTemplateId: structure.id,
            schoolId: props.schoolInfo.id,
            schoolYearId: structure.schoolYearId,
            schoolYear: structure.schoolYear,
            unitPriceSchoolPageBW: structure.schoolPagesBWUnitPrice,
            schoolPageBWBoardUnits: structure.schoolPagesBWFreeUnits,
            unitPriceSchoolPageColour: structure.schoolPagesColourPrice,

            pouchUnitPrice: structure.pouchUnitPrice,
            pouchNote: "",
            pouchQuantity: 0,
            pouchTotal: 0,

            teacherPlannerUnitPrice: structure.teacherPlannerUnitPrice,
            staffMonthlyUnitPrice: structure.staffMonthlyUnitPrice,
            unitPriceFrontCover: structure.frontCoverUnitPrice,
            unitPriceBackCover: structure.backCoverUnitPrice,
            customFrontCoverPrice: structure.customFrontCoverPrice,
            customBackCoverPrice: structure.customBackCoverPrice,

            frontTotalPrice: 0,
            backTotalPrice: 0,
            bwTotalPrice: 0,
            colourTotalPrice: 0,

            teacherPlannerCover: "",
            teacherPlannerNote: "",
            teacherPlannerQuantity: 0,
            teacherPlannerTotal: 0,

            staffMonthlyNote: "",
            staffMonthlyQuantity: 0,
            staffMonthlyTotal: 0,

            totalQuantity: 0,
            totalPrice: 0,

            baseFee: 0,
            baseFeeDiscount: 0,

            gst: gst,
            pst: pst,
            hst: hst,
        };

        if (props.contractStatus === "EDIT") {
            // replace saved planners back into local list
            newList.forEach((a) => {
                const index = plannerList.findIndex((b) => b.templatePlannerId === a.templatePlannerId);

                // plannerList.splice(a.templatePlannerId - 1, 1, a);
                plannerList.splice(index, 1, a);
            });

            // //edit size id if only one planner of exists
            plannerList = loadContractHelper(plannerList);

            //setting all other variables from previously saved data for local display and calcuation purposes
            let total = 0,
                quantity = 0,
                frontCoverTotal = 0,
                backCoverTotal = 0,
                bwTotal = 0,
                colourTotal = 0;

            let containCustomFront = false,
                containCustomBack = false;
            newList.map((item) => {
                quantity += item.quantity;
                total += item.quantity * item.unitPrice;

                // handling covers total
                if (item.frontCoverId > 0 && item.frontCoverId !== null) {
                    if (
                        structure.frontCovers.find((c) => c.id === item.frontCoverId) &&
                        !structure.frontCovers.find((c) => c.id === item.frontCoverId).customCover
                    ) {
                        frontCoverTotal += item.quantity * costs.unitPriceFrontCover;
                    } else {
                        if (!containCustomFront) {
                            frontCoverTotal += costs.customFrontCoverPrice;
                            containCustomFront = true;
                        }
                    }
                }
                if (item.backCoverId > 0 && item.backCoverId !== null) {
                    if (
                        structure.backCovers.find((c) => c.id === item.backCoverId) &&
                        !structure.backCovers.find((c) => c.id === item.backCoverId).customCover
                    ) {
                        backCoverTotal += item.quantity * costs.unitPriceBackCover;
                    } else {
                        if (!containCustomBack) {
                            backCoverTotal += costs.customBackCoverPrice;
                            containCustomBack = true;
                        }
                    }
                }

                if (item.schoolPagesBWUnits > item.schoolPagesBWFree) {
                    bwTotal +=
                        item.schoolPagesBWPrice * (item.schoolPagesBWUnits - item.schoolPagesBWFree) * item.quantity;
                } else {
                    bwTotal += 0;
                }
                colourTotal += costs.unitPriceSchoolPageColour * item.schoolPagesColourUnits * item.quantity;
            });
            rest = {
                ...rest,
                totalQuantity: quantity,
                totalPrice: total,

                frontTotalPrice: frontCoverTotal,
                backTotalPrice: backCoverTotal,
                customBackCover: costs.customBackCover,
                customBackCoverPrice: costs.customBackCoverPrice,
                customFrontCover: costs.customFrontCover,
                customFrontCoverPrice: costs.customFrontCoverPrice,

                bwTotalPrice: bwTotal,
                colourTotalPrice: colourTotal,
                materialCoverDate: !costs.materialCoverDate ? "" : costs.materialCoverDate,
                materialHandbookDate: !costs.materialHandbookDate ? "" : costs.materialHandbookDate,
                unitPriceFrontCover: costs.unitPriceFrontCover,
                unitPriceBackCover: costs.unitPriceBackCover,
                unitPriceSchoolPageBW: costs.unitPriceSchoolPageBW,
                unitPriceSchoolPageColour: costs.unitPriceSchoolPageColour,

                pouchUnitPrice: costs.pouchUnitPrice,
                pouchNote: costs.pouchNote,
                pouchQuantity: costs.pouchQuantity,
                pouchTotal: costs.pouchTotal,

                teacherPlannerUnitPrice: costs.teacherPlannerUnitPrice,
                teacherPlannerCover: costs.teacherPlannerCover,
                teacherPlannerNote: costs.teacherPlannerNote,
                teacherPlannerQuantity: costs.teacherPlannerQuantity,
                teacherPlannerTotal: costs.teacherPlannerTotal,

                staffMonthlyUnitPrice: costs.staffMonthlyUnitPrice,
                staffMonthlyNote: costs.staffMonthlyNote,
                staffMonthlyQuantity: costs.staffMonthlyQuantity,
                staffMonthlyTotal: costs.staffMonthlyTotal,

                baseFee: costs.baseFee,
                baseFeeDiscount: costs.baseFeeDiscount,
            };

            //set previously saved supplement prices
            // let i = 1;
            // while (i < 8) {
            //     let supQuantity = 0,
            //         supTotal = 0;
            //     const supplement = structure.supplements.find((supp) => supp.id === i);
            //     console.log(supplement, i);
            //     const name = supplement.title.replace(/\s|-/g, "");
            //     newList.map((p) => {
            //         const found = p.supplements.find((s) => s.templateSupplementId === i);
            //         if (found !== undefined) {
            //             supQuantity += p.quantity;
            //             supTotal += p.quantity * found.unitPrice;
            //             rest = {
            //                 ...rest,
            //                 [`unitPrice${name}`]: found.unitPrice,
            //             };
            //         }
            //         rest = {
            //             ...rest,
            //             [`quantity${name}`]: supQuantity,
            //             [`total${name}`]: supTotal,
            //         };
            //     });
            //     i++;
            // }

            structure.supplements.forEach((sup) => {
                let supQuantity = 0,
                    supTotal = 0;
                const name = sup.title.replace(/\s|-/g, "");
                newList.forEach((p) => {
                    const found = p.supplements.find((s) => s.templateSupplementId === sup.id);

                    if (found !== undefined) {
                        supQuantity += p.quantity;
                        supTotal += p.quantity * found.unitPrice;
                        rest = {
                            ...rest,
                            [`unitPrice${name}`]: found.unitPrice,
                        };
                    }
                    rest = {
                        ...rest,
                        [`quantity${name}`]: supQuantity,
                        [`total${name}`]: supTotal,
                    };
                });
            });

            //set previously saved deliverly date for local display
            deliveryWeek = structure.deliveryWeeks.find((d) => {
                return d.startDate === costs.deliveryWeekStartDate && d.endDate === costs.deliveryWeekEndDate;
            });
            if (deliveryWeek !== undefined) {
                deliveryWeek = `{"start": "${costs.deliveryWeekStartDate}","end": "${costs.deliveryWeekEndDate}"}`;
            } else {
                if (costs.deliveryWeekStartDate === costs.deliveryWeekEndDate) {
                    deliveryWeek = "asap";
                } else {
                    deliveryWeek = "n/a";
                }
            }
        }
        if (props.contractStatus === "CREATE") {
            if (Object.entries(rest).length !== 0) {
                setCosts({
                    ...costs,
                    planners: plannerList,
                    ...rest,
                });
            }
        } else {
            setValue([costs.deliveryWeekStartDate, costs.deliveryWeekEndDate]);
            setLocalCosts({
                ...localCosts,
                deliveryWeek,
            });
            setCosts({
                ...costs,
                planners: plannerList,
                ...rest,
            });
        }
        setLoading(false);
    };

    // ==============handling contacts==============
    const handleClickEditContact = (contactId) => {
        const token = localStorage.getItem("pes-crm-token");
        fetch(settings.api().contacts + "/" + contactId, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setNewContact(false);
                setContactId(contactId);
                setContact(data);
                setCreateContactOpen(!createContactOpen);
            });
    };

    const openCreateContact = () => {
        setNewContact(true);
        setCreateContactOpen(!createContactOpen);
    };

    //==========helper functions============
    //selects the whole string in the input field
    const selectAll = (e) => {
        e.target.select();
    };

    const overrideAllPlannersUnitPrice = (e, maskedvalue, floatvalue) => {
        let newArray = [...costs.planners];
        for (let index = 0; index < newArray.length; index++) {
            if (newArray[index].unitPrice !== undefined) {
                newArray[index] = {
                    ...newArray[index],
                    unitPrice: floatvalue,
                };
            }
        }

        setLocalCosts({ ...localCosts, overrideUnitPrice: floatvalue });
        setCosts({
            ...costs,
            planners: newArray,
        });
    };

    const overrideBWUnitPrice = (e, maskedvalue, floatvalue) => {
        let newArray = [...costs.planners];

        for (let index = 0; index < newArray.length; index++) {
            if (newArray[index].schoolPagesBWPrice !== undefined) {
                newArray[index] = {
                    ...newArray[index],
                    schoolPagesBWPrice: floatvalue,
                };
            }
        }

        setCosts({
            ...costs,
            unitPriceSchoolPageBW: floatvalue,
            planners: newArray,
        });
    };

    useEffect(() => {
        if (Object.entries(structure).length !== 0 && costs.planners) {
            customExist();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [costs.planners]);

    const customExist = () => {
        let frontExist = false;
        let backExist = false;

        let tmpPlanners = costs.planners;
        tmpPlanners.map((p) => {
            if (p.frontCoverId && !frontExist) {
                let frontCustomId = structure.frontCovers.find((f) => p.frontCoverId === f.id && f.customCover);
                if (frontCustomId !== undefined) {
                    frontExist = true;
                }
                if (p.quantity > 0 && p.frontCoverId > 0 && !(p.backCoverId > 0)) {
                    let bc = structure.backCovers.find(
                        (f) => f.sizeId === p.sizeId && f.title.startsWith("Back Cover (")
                    );
                    p.backCoverId = bc.id;
                }
            }
            if (p.backCoverId && !backExist) {
                let backCustomId = structure.backCovers.find((f) => p.backCoverId === f.id && f.customCover);
                if (backCustomId !== undefined) {
                    backExist = true;
                }
            }
        });
        setCosts({
            ...costs,
            customFrontCover: frontExist,
            customBackCover: backExist,
            planners: tmpPlanners,
        });
    };

    const handleCostChange = (e, maskedvalue, floatvalue) => {
        if (e.target.name === "unitPrice" || e.target.name === "schoolPagesBWPrice") {
            let index = costs.planners.findIndex((p) => p.templatePlannerId === parseInt(e.target.id));
            let newArray = [...costs.planners];

            newArray[index] = {
                ...newArray[index],
                [e.target.name]: floatvalue,
            };
            setCosts({
                ...costs,
                planners: newArray,
            });
        } else if (
            e.target.name === "unitPricePlasticHomeSchoolPockets" ||
            e.target.name === "unitPriceSnapInPageMarker" ||
            e.target.name === "unitPriceAntiBullyingSupplement" ||
            e.target.name === "unitPriceGoingGreenSupplement" ||
            e.target.name === "unitPriceNutritionSupplement" ||
            e.target.name === "unitPriceBoardInsert" ||
            e.target.name === "unitPriceCatholicResourcePages" ||
            e.target.name === "unitPriceChristianResourcePages"
        ) {
            setCosts({
                ...costs,
                [e.target.name]: floatvalue,
            });
            costs.planners.map((p) => {
                let exist = -1;
                if (p.supplements) {
                    exist = p.supplements.findIndex((s) => {
                        return s.templateSupplementId === parseInt(e.target.id);
                    });
                }

                if (exist > -1) {
                    let a = [...p.supplements];
                    p.supplements[exist] = {
                        ...p.supplements[exist],
                        unitPrice: floatvalue,
                    };
                }
            });
        } else {
            setCosts({
                ...costs,
                [e.target.name]: floatvalue,
            });
        }
    };

    const handleQuantity = (e, plan) => {
        let tmp = parseInt(e.target.value);
        if (!tmp) {
            tmp = 0;
        }
        if (tmp < 0) {
            tmp *= -1;
        }
        if (plan) {
            let index = costs.planners.findIndex((p) => {
                return plan.templatePlannerId === p.templatePlannerId;
            });
            let newArray = [...costs.planners];
            newArray[index] = {
                ...newArray[index],
                [e.target.name]: tmp,
            };
            setCosts({
                ...costs,
                planners: newArray,
            });
        } else {
            setCosts({
                ...costs,
                [e.target.name]: tmp,
            });
        }
    };

    const handleCheckbox = (e, plan, supId, supUnitPrice) => {
        let index2 = [];
        let tmpsup = {};
        if (supId !== undefined) {
            let index = costs.planners.findIndex((p) => {
                return plan.templatePlannerId === p.templatePlannerId;
            });
            if (props.contractStatus === "EDIT") {
                index2 = initPlan.find((pp) => {
                    return plan.templatePlannerId === pp.templatePlannerId;
                });
                if (index2 !== undefined) {
                    tmpsup = index2.supplements.find((ss) => ss.templateSupplementId === supId);
                }
            }

            let newArray = [...costs.planners];

            if (e.target.checked) {
                if (tmpsup !== undefined && Object.entries(tmpsup).length !== 0) {
                    newArray[index].supplements.push({
                        id: tmpsup.id,
                        templateSupplementId: supId,
                        unitPrice: supUnitPrice,
                    });
                } else {
                    newArray[index].supplements.push({
                        templateSupplementId: supId,
                        unitPrice: supUnitPrice,
                    });
                }

                setCosts({
                    ...costs,
                    planners: newArray,
                });
            } else {
                newArray[index].supplements = newArray[index].supplements.filter(
                    (sup) => sup.templateSupplementId !== supId
                );
                setCosts({
                    ...costs,
                    planners: newArray,
                });
            }
        } else {
            if (e.target.name === "materialHandbook") {
                if (e.target.checked) {
                    if (isGeneric()) {
                        setCosts({
                            ...costs,
                            [e.target.name]: e.target.checked,
                            materialHandbookDate: "2021-04-16T00:00:00",
                        });
                    } else {
                        setCosts({
                            ...costs,
                            [e.target.name]: e.target.checked,
                            materialHandbookDate: "",
                        });
                    }
                } else if (!e.target.checked) {
                    setCosts({
                        ...costs,
                        [e.target.name]: e.target.checked,
                        materialHandbookDate: "",
                    });
                }
            } else if (e.target.name === "materialCover") {
                if (e.target.checked) {
                    if (isGeneric()) {
                        setCosts({
                            ...costs,
                            [e.target.name]: e.target.checked,
                            materialCoverDate: "2021-04-16T00:00:00",
                        });
                    } else {
                        setCosts({
                            ...costs,
                            [e.target.name]: e.target.checked,
                            materialCoverDate: "",
                        });
                    }
                } else if (!e.target.checked) {
                    setCosts({
                        ...costs,
                        [e.target.name]: e.target.checked,
                        materialCoverDate: "",
                    });
                }
            } else {
                setCosts({
                    ...costs,
                    [e.target.name]: e.target.checked,
                });
            }
        }
    };

    const isGeneric = () => {
        let onePage = true;
        costs.planners.map((p) => {
            onePage = onePage && p.schoolPagesBWUnits < 2 && p.schoolPagesColourUnits < 2;
        });
        return onePage;
    };

    const loadContractHelper = (planners) => {
        let x = 1;
        while (x < 6) {
            let plantype = planners.filter((b) => b.plannerTypeId === x);
            if (plantype.length > 1) {
                if (plantype[0].sizeId !== plantype[1].sizeId) {
                    if (plantype[0].sizeId > 0) {
                        plantype[1].sizeId = plantype[0].sizeId;
                        planners.map((y) => y.templatePlannerId === plantype[1].templatePlannerId && plantype[1]);
                    } else {
                        plantype[0].sizeId = plantype[1].sizeId;
                        planners.map((y) => y.templatePlannerId === plantype[0].templatePlannerId && plantype[0]);
                    }
                }
            }
            x++;
        }
        return planners;
    };

    const handleFormInfo = (e, plan) => {
        if (e.target.value === "other" || e.target.value === "Other") {
            setCosts({
                ...costs,
                [e.target.name]: e.target.value,
                contractNotes: "'Invoice To' address: \n'Ship To' address: ",
            });
        } else {
            if (plan !== undefined && plan.templatePlannerId) {
                let index = costs.planners.findIndex((p) => {
                    return plan.templatePlannerId === p.templatePlannerId;
                });
                let newArray = [...costs.planners];
                newArray[index] = {
                    ...newArray[index],
                    [e.target.name]: e.target.value,
                };
                setCosts({
                    ...costs,
                    planners: newArray,
                });
            } else {
                setCosts({
                    ...costs,
                    [e.target.name]: e.target.value,
                });
            }
        }
    };

    const handleDelieveryWeek = (e) => {
        let startDate = null;
        let endDate = null;

        if (e.target.value === "asap") {
            // const dateObj = new Date();
            // const today =
            //     dateObj.getUTCFullYear() +
            //     "-" +
            //     (dateObj.getUTCMonth() + 1 < 10 ? "0" + (dateObj.getUTCMonth() + 1) : dateObj.getUTCMonth() + 1) +
            //     "-" +
            //     (dateObj.getUTCDate() < 10 ? "0" + dateObj.getUTCDate() : dateObj.getUTCDate()) +
            //     "T00:00:00";
            // startDate = moment(today).format();
            // endDate = moment(today).format();
            // setCosts({
            //     ...costs,
            //     deliveryWeekStartDate: today,
            //     deliveryWeekEndDate: today,
            // });
            //last week of 2022
            const futureDate = { start: "2022-12-26T00:00:00", end: "2022-12-30T00:00:00" };
            startDate = moment(futureDate.start).format();
            endDate = moment(futureDate.end).format();

            setCosts({
                ...costs,
                deliveryWeekStartDate: startDate,
                deliveryWeekEndDate: endDate,
            });
        } else if (e.target.value === "") {
            const tmp = Object.entries(costs);
            for (let i = 0; i < tmp.length; i++) {
                if (tmp[i][0] === "deliveryWeekStartDate") {
                    tmp.splice(i, 1);
                }
                if (tmp[i][0] === "deliveryWeekEndDate") {
                    tmp.splice(i, 1);
                }
            }
            const tmp2 = Object.fromEntries(tmp);
            setCosts({
                ...tmp2,
            });
        } else if (e.target.value === "n/a") {
            setCosts({
                ...costs,
                deliveryWeekStartDate: "",
                deliveryWeekEndDate: "",
            });
        } else {
            const tmpObj = JSON.parse(e.target.value);
            startDate = moment(tmpObj.start, "YYYY-MM-DD[T]HH:mm:ss");
            endDate = moment(tmpObj.end, "YYYY-MM-DD[T]HH:mm:ss");

            setCosts({
                ...costs,
                deliveryWeekStartDate: tmpObj.start,
                deliveryWeekEndDate: tmpObj.end,
            });
        }

        setValue([startDate, endDate]);
        setLocalCosts({
            ...localCosts,
            deliveryWeek: e.target.value,
        });
    };

    const returnValue = (plan, type, supId) => {
        const index = costs.planners.findIndex((p) => plan.templatePlannerId === p.templatePlannerId);
        const planner = costs.planners[index];

        return planner[type];
    };

    const handleSizeChange = (id, e) => {
        const index = costs.planners.findIndex((p) => p.plannerTypeId === id);
        const keep = costs.planners.filter((p) => p.plannerTypeId !== id);
        let change = costs.planners.filter((p) => p.plannerTypeId === id);
        let newList = [...keep];
        change = change.map((obj) => ({
            ...obj,
            sizeId: e.target.value,
        }));

        newList.splice(index, 0, ...change);

        setCosts({
            ...costs,
            planners: newList,
        });
    };

    const calculate = () => {
        // console.log(costs);
        let totalQuantity = 0,
            totalPrice = 0;

        if (costs.planners.length !== 0) {
            costs.planners.map((p) => {
                if (Object.keys(p).length > 1) {
                    totalQuantity += p.quantity;
                    totalPrice += p.quantity * p.unitPrice;
                }
            });
        }
        let frontTotalPrice = 0,
            backTotalPrice = 0;
        let containCustomFront = false,
            containCustomBack = false;

        let bwTotalPrice = 0,
            colourTotalPrice = 0;

        let totalPlasticHomeSchoolPockets = 0,
            quantityPlasticHomeSchoolPockets = 0;

        let totalSnapInPageMarker = 0,
            quantitySnapInPageMarker = 0;

        let quantityAntiBullyingSupplement = 0,
            totalAntiBullyingSupplement = 0;

        let totalGoingGreenSupplement = 0,
            quantityGoingGreenSupplement = 0;

        let totalNutritionSupplement = 0,
            quantityNutritionSupplement = 0;

        let totalBoardInsert = 0,
            quantityBoardInsert = 0;

        let totalCatholicResourcePages = 0,
            quantityCatholicResourcePages = 0;

        let totalChristianResourcePages = 0,
            quantityChristianResourcePages = 0;

        costs.planners.map((p) => {
            if (Object.keys(p).length > 1) {
                if (p.frontCoverId > 0 && p.frontCoverId !== null) {
                    if (
                        structure.frontCovers.find((c) => c.id === p.frontCoverId) &&
                        !structure.frontCovers.find((c) => c.id === p.frontCoverId).customCover
                    ) {
                        frontTotalPrice += p.quantity * costs.unitPriceFrontCover;
                    } else {
                        if (!containCustomFront) {
                            frontTotalPrice += costs.customFrontCoverPrice;
                            containCustomFront = true;
                        }
                    }
                }
                if (p.backCoverId > 0 && p.backCoverId !== null) {
                    if (
                        structure.backCovers.find((c) => c.id === p.backCoverId) &&
                        !structure.backCovers.find((c) => c.id === p.backCoverId).customCover
                    ) {
                        backTotalPrice += p.quantity * costs.unitPriceBackCover;
                    } else {
                        if (!containCustomBack) {
                            backTotalPrice += costs.customBackCoverPrice;
                            containCustomBack = true;
                        }
                    }
                }

                if (p.schoolPagesBWUnits > p.schoolPagesBWFree) {
                    bwTotalPrice +=
                        (Math.round((p.schoolPagesBWUnits - p.schoolPagesBWFree) * p.schoolPagesBWPrice * 100) / 100) *
                        p.quantity;
                }

                if (p.schoolPagesColourUnits > 0) {
                    colourTotalPrice += p.schoolPagesColourUnits * costs.unitPriceSchoolPageColour * p.quantity;
                }

                if (p.supplements.length > 0) {
                    p.supplements.map((s) => {
                        const supItem = structure.supplements.find((item) => {
                            return item.id === s.templateSupplementId;
                        });
                        if (supItem.title.replace(/\s|-/g, "") === "PlasticHomeSchoolPockets") {
                            totalPlasticHomeSchoolPockets += p.quantity * s.unitPrice;
                            quantityPlasticHomeSchoolPockets += p.quantity;
                        }
                        if (supItem.title.replace(/\s|-/g, "") === "SnapInPageMarker") {
                            totalSnapInPageMarker += p.quantity * s.unitPrice;
                            quantitySnapInPageMarker += p.quantity;
                        }
                        if (supItem.title.replace(/\s|-/g, "") === "AntiBullyingSupplement") {
                            totalAntiBullyingSupplement += p.quantity * s.unitPrice;
                            quantityAntiBullyingSupplement += p.quantity;
                        }
                        if (supItem.title.replace(/\s|-/g, "") === "GoingGreenSupplement") {
                            totalGoingGreenSupplement += p.quantity * s.unitPrice;
                            quantityGoingGreenSupplement += p.quantity;
                        }
                        if (supItem.title.replace(/\s|-/g, "") === "NutritionSupplement") {
                            totalNutritionSupplement += p.quantity * s.unitPrice;
                            quantityNutritionSupplement += p.quantity;
                        }
                        if (supItem.title.replace(/\s|-/g, "") === "BoardInsert") {
                            totalBoardInsert += p.quantity * s.unitPrice;
                            quantityBoardInsert += p.quantity;
                        }
                        if (supItem.title.replace(/\s|-/g, "") === "CatholicResourcePages") {
                            totalCatholicResourcePages += p.quantity * s.unitPrice;
                            quantityCatholicResourcePages += p.quantity;
                        }
                        if (supItem.title.replace(/\s|-/g, "") === "ChristianResourcePages") {
                            totalChristianResourcePages += p.quantity * s.unitPrice;
                            quantityChristianResourcePages += p.quantity;
                        }
                    });
                }
            }
        });

        let materialHandbookDate = costs.materialHandbookDate;
        let materialCoverDate = costs.materialCoverDate;
        if (costs.materialHandbook) {
            if (isGeneric()) {
                materialHandbookDate = "2021-04-16T00:00:00";
            }
        }
        if (costs.materialCover) {
            if (isGeneric()) {
                materialCoverDate = "2021-04-16T00:00:00";
            }
        }

        let pouchTotal = 0,
            teacherPlannerTotal = 0,
            staffMonthlyTotal = 0;
        if (costs.teacherPlannerQuantity > 0) {
            teacherPlannerTotal = costs.teacherPlannerQuantity * costs.teacherPlannerUnitPrice;
        }
        if (costs.staffMonthlyQuantity > 0) {
            staffMonthlyTotal = costs.staffMonthlyQuantity * costs.staffMonthlyUnitPrice;
        }
        if (costs.pouchQuantity > 0) {
            pouchTotal = costs.pouchQuantity * costs.pouchUnitPrice;
        }

        let baseFee = 0;
        let totalPlanners =
            totalQuantity + costs.pouchQuantity + costs.teacherPlannerQuantity + costs.staffMonthlyQuantity;

        let isBaseFeeFound = false;
        // console.log(structure.baseFees);
        for (let index = 0; index < structure.baseFees.length && !isBaseFeeFound; index++) {
            if (
                structure.baseFees[index].startRange <= totalPlanners &&
                totalPlanners <= structure.baseFees[index].endRange
            ) {
                baseFee = structure.baseFees[index].cost;
                isBaseFeeFound = true;
            }
        }
        // console.log(baseFee);

        const calcSubtotal =
            totalPrice +
            frontTotalPrice +
            backTotalPrice +
            totalPlasticHomeSchoolPockets +
            totalSnapInPageMarker +
            totalAntiBullyingSupplement +
            totalGoingGreenSupplement +
            totalNutritionSupplement +
            totalBoardInsert +
            totalCatholicResourcePages +
            totalChristianResourcePages +
            bwTotalPrice +
            colourTotalPrice +
            pouchTotal +
            teacherPlannerTotal +
            staffMonthlyTotal +
            baseFee -
            costs.baseFeeDiscount;

        let calcShipping = 0;
        let shippingType = costs.shippingType;
        if (shippingType === 3) {
            calcShipping = totalPlanners * 0.5;

            if (calcShipping < 15 && calcShipping > 0) {
                calcShipping = 15;
            }
        } else if (costs.shippingType === 1 || costs.shippingType === 2) {
            calcShipping = 0;
        }
        const subtotalShipping = calcSubtotal + calcShipping;

        const calcTotal = (costs.gst + costs.pst + costs.hst) * subtotalShipping + subtotalShipping;

        setCosts({
            ...costs,
            totalQuantity,
            totalPrice: Math.round(totalPrice * 100) / 100,
            frontTotalPrice: Math.round(frontTotalPrice * 100) / 100,
            backTotalPrice: Math.round(backTotalPrice * 100) / 100,
            quantityPlasticHomeSchoolPockets,
            totalPlasticHomeSchoolPockets: Math.round(totalPlasticHomeSchoolPockets * 100) / 100,
            quantitySnapInPageMarker,
            totalSnapInPageMarker: Math.round(totalSnapInPageMarker * 100) / 100,
            quantityAntiBullyingSupplement,
            totalAntiBullyingSupplement: Math.round(totalAntiBullyingSupplement * 100) / 100,
            quantityGoingGreenSupplement,
            totalGoingGreenSupplement: Math.round(totalGoingGreenSupplement * 100) / 100,
            quantityNutritionSupplement,
            totalNutritionSupplement: Math.round(totalNutritionSupplement * 100) / 100,
            quantityBoardInsert,
            totalBoardInsert: Math.round(totalBoardInsert * 100) / 100,
            quantityCatholicResourcePages,
            totalCatholicResourcePages: Math.round(totalCatholicResourcePages * 100) / 100,
            quantityChristianResourcePages,
            totalChristianResourcePages: Math.round(totalChristianResourcePages * 100) / 100,
            bwTotalPrice: Math.round(bwTotalPrice * 100) / 100,
            colourTotalPrice: Math.round(colourTotalPrice * 100) / 100,
            pouchTotal: Math.round(pouchTotal * 100) / 100,
            teacherPlannerTotal: Math.round(teacherPlannerTotal * 100) / 100,
            staffMonthlyTotal: Math.round(staffMonthlyTotal * 100) / 100,
            calcSubtotal: Math.round(calcSubtotal * 100) / 100,
            baseFee: Math.round(baseFee * 100) / 100,
            calcShipping: Math.round(calcShipping * 100) / 100,
            shippingType,
            calcGST: Math.round(costs.gst * subtotalShipping * 100) / 100,
            calcPST: Math.round(costs.pst * subtotalShipping * 100) / 100,
            calcHST: Math.round(costs.hst * subtotalShipping * 100) / 100,
            calcTotal: Math.round(calcTotal * 100) / 100,
            materialHandbookDate,
            materialCoverDate,
        });
    };
    const handleDeliveryDates = (newValue) => {
        setValue(newValue);

        let start = "";
        let end = "";
        if (newValue[0] !== null) {
            start =
                newValue[0]._d.getUTCFullYear() +
                "-" +
                (newValue[0]._d.getUTCMonth() + 1) +
                "-" +
                newValue[0]._d.getUTCDate() +
                "T00:00:00";
        }

        if (newValue[1] !== null) {
            end =
                newValue[1]._d.getUTCFullYear() +
                "-" +
                (newValue[1]._d.getUTCMonth() + 1) +
                "-" +
                newValue[1]._d.getUTCDate() +
                "T00:00:00";
        }

        setCosts({
            ...costs,
            deliveryWeekStartDate: start,
            deliveryWeekEndDate: end,
        });
    };

    useEffect(() => {
        if (save[0] === true) {
            let outputData = {
                ...costs,
                emailCustomer: save[1] === "send",
            };

            let tmpPlanner = [];
            let planners = costs.planners.filter((p) => p.quantity > 0);

            const token = localStorage.getItem("pes-crm-token");

            tmpPlanner.push(...planners);
            outputData.planners = [...tmpPlanner];

            if (formValidationMessage === "") {
                if (props.contractStatus === "CREATE") {
                    // console.log(JSON.stringify(outputData));
                    fetch(settings.api().contract, {
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                            Accept: "application/json",
                        },
                        body: JSON.stringify(outputData),
                    })
                        .then((res) => {
                            if (res.ok) {
                                props.closeDialog();
                                if (save[1] === "send") {
                                    props.success(
                                        "Contract successfully sent to client and saved under School Contracts Page."
                                    );
                                } else {
                                    props.success("Contract successfully saved under School Contracts Page.");
                                }
                            } else {
                                setDisableBtn(false);
                                return res.json();
                            }
                        })
                        .then((data) => {
                            if (data.message) {
                                setFormValidationMessage(data.message);
                            }
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                } else if (props.contractStatus === "EDIT") {
                    outputData = {
                        ...outputData,
                        contractId: costs.contractId,
                    };
                    // console.log(JSON.stringify(outputData));

                    fetch(settings.api().contract, {
                        method: "PUT",
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                            Accept: "application/json",
                        },
                        body: JSON.stringify(outputData),
                    })
                        .then((res) => {
                            if (res.ok) {
                                props.closeDialog();
                                if (save[1] === "send") {
                                    props.success(
                                        "Contract successfully sent to client and saved under the School Contracts Page."
                                    );
                                } else {
                                    props.success("Contract successfully saved under the School Contracts Page.");
                                }
                            } else {
                                setDisableBtn(false);
                                return res.json();
                            }
                        })
                        .then((data) => {
                            if (data.message) {
                                setFormValidationMessage(data.message);
                            }
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [save]);

    const submitContract = (e, saveORsend) => {
        e.preventDefault();
        calculate();
        setDisableBtn(true);
        if (isBWPagesSet()) {
            setSave([saveORsend[0], saveORsend[1]]);
        } else {
            setMissingBWPageMessage(["Missing B&W Pages", "Please fill in the # of B&W Pages."]);
            setDisableBtn(false);
        }
    };

    const getDateFormat = (date) => {
        const d = new Date(date);
        const year = d.getUTCFullYear();
        const month = d.getUTCMonth();
        const nDate = d.getUTCDate();

        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
        let dateFormat = months[month] + " " + nDate + ", " + year;
        return dateFormat;
    };

    //checks if BW pages are set, when free pages are set
    const isBWPagesSet = () => {
        let result = true;
        costs.planners.forEach((p) => {
            if (result && p.quantity > 0 && p.schoolPagesBWFree !== 0 && p.schoolPagesBWUnits === 0) {
                result = false;
            }
        });
        return result;
    };

    return (
        <>
            <Card className={classes.card}>
                <form>
                    <Grid container spacing={3}>
                        <Grid item md={6} sm={12} xs={12}>
                            <List onChange={handleFormInfo}>
                                <ListItem className={classes.contacts}>
                                    <Typography variant="h3" gutterBottom>
                                        {props.schoolInfo.schoolName}
                                    </Typography>
                                </ListItem>
                                <ListItem className={classes.contacts}>
                                    <Typography variant="h4" gutterBottom>
                                        {props.schoolInfo.boardName}
                                    </Typography>
                                </ListItem>

                                <ListItem className={classes.contacts}>
                                    <FormControl fullWidth required>
                                        <InputLabel id="primary">Primary</InputLabel>
                                        <Select
                                            labelId="primary"
                                            value={costs.contactPrimaryId}
                                            name="contactPrimaryId"
                                            onChange={handleFormInfo}
                                        >
                                            <MenuItem>--Select--</MenuItem>
                                            {contacts &&
                                                contacts.map((c) => (
                                                    <MenuItem value={c.id} key={c.id}>
                                                        {c.firstName} {c.lastName} ({c.title} - {c.email})
                                                    </MenuItem>
                                                ))}
                                            <Divider />
                                            <ListSubheader>
                                                <Button
                                                    fullWidth
                                                    style={{ textTransform: "none" }}
                                                    onClick={openCreateContact}
                                                >
                                                    <AddIcon />
                                                    New Contact
                                                </Button>
                                            </ListSubheader>
                                        </Select>
                                    </FormControl>

                                    {!!costs.contactPrimaryId && (
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            className={classes.contactBtn}
                                            onClick={() => handleClickEditContact(costs.contactPrimaryId)}
                                        >
                                            Edit
                                        </Button>
                                    )}
                                </ListItem>

                                <ListItem className={classes.contacts}>
                                    <FormControl margin="none" fullWidth>
                                        <InputLabel id="secondary">Secondary</InputLabel>
                                        <Select
                                            labelId="secondary"
                                            value={costs.contactSecondaryId}
                                            name="contactSecondaryId"
                                            onChange={handleFormInfo}
                                        >
                                            <ListSubheader>--Select--</ListSubheader>
                                            {contacts &&
                                                contacts.map((c) => (
                                                    <MenuItem value={c.id} key={c.id}>
                                                        {c.firstName} {c.lastName} ({c.title} - {c.email})
                                                    </MenuItem>
                                                ))}
                                            <Divider />
                                            <ListSubheader>
                                                <Button
                                                    fullWidth
                                                    style={{ textTransform: "none" }}
                                                    onClick={openCreateContact}
                                                >
                                                    <AddIcon />
                                                    New Contact
                                                </Button>
                                            </ListSubheader>
                                        </Select>
                                    </FormControl>

                                    {!!costs.contactSecondaryId && (
                                        <Button
                                            variant="outlined"
                                            className={classes.contactBtn}
                                            size="small"
                                            onClick={() => handleClickEditContact(costs.contactSecondaryId)}
                                        >
                                            Edit
                                        </Button>
                                    )}
                                </ListItem>
                                <ListItem className={classes.contacts}>
                                    <Grid container justify="space-between">
                                        <Grid item md={5}>
                                            <FormControl fullWidth margin="none">
                                                <TextField
                                                    label="PO Number"
                                                    onChange={handleFormInfo}
                                                    name="poNumber"
                                                    value={costs.poNumber}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={3}>
                                            <div>
                                                <InputLabel
                                                    id="secondary"
                                                    style={{
                                                        marginBottom: "5px",
                                                        color: !costs.customFrontCover && "#bdbdbd",
                                                    }}
                                                >
                                                    Custom Front Cover
                                                </InputLabel>
                                                <CurrencyInput
                                                    disabled={!costs.customFrontCover}
                                                    prefix="$"
                                                    precision={2}
                                                    name="customFrontCoverPrice"
                                                    value={costs.customFrontCoverPrice}
                                                    onChangeEvent={handleCostChange}
                                                    style={{
                                                        border: "none",
                                                        borderBottom: "0.5px solid #9e9e9e",
                                                        fontSize: "14px",
                                                        width: "100%",
                                                        padding: "5px",
                                                    }}
                                                    onFocus={selectAll}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item md={3}>
                                            <div>
                                                <InputLabel
                                                    id="secondary"
                                                    style={{
                                                        marginBottom: "5px",
                                                        color: !costs.customBackCover && "#bdbdbd",
                                                    }}
                                                >
                                                    Custom Back Cover
                                                </InputLabel>
                                                <CurrencyInput
                                                    disabled={!costs.customBackCover}
                                                    prefix="$"
                                                    precision={2}
                                                    name="customBackCoverPrice"
                                                    value={costs.customBackCoverPrice}
                                                    onChangeEvent={handleCostChange}
                                                    style={{
                                                        border: "none",
                                                        borderBottom: "0.5px solid #9e9e9e",
                                                        fontSize: "14px",
                                                        width: "100%",
                                                        padding: "5px",
                                                    }}
                                                    onFocus={selectAll}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            </List>
                        </Grid>

                        <Grid item md={6} sm={12} xs={12}>
                            <List>
                                <ListItem className={classes.contacts}>
                                    <Grid container>
                                        <Grid item md={6}>
                                            <FormControl
                                                className={classes.spaceRight}
                                                fullWidth
                                                margin="none"
                                                required
                                            >
                                                <InputLabel id="contractStatus">Contract Status</InputLabel>
                                                <Select
                                                    fullWidth
                                                    labelId="contractStatus"
                                                    onChange={handleFormInfo}
                                                    name="contractStatus"
                                                    value={costs.contractStatus}
                                                >
                                                    <MenuItem value="New">New</MenuItem>
                                                    <MenuItem value="Renewal">Renewal</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={6}>
                                            <FormControl className={classes.spaceLeft} fullWidth margin="none" required>
                                                <InputLabel id="series">Series</InputLabel>
                                                <Select
                                                    fullWidth
                                                    labelId="series"
                                                    value={costs.contractSeries}
                                                    name="contractSeries"
                                                    onChange={handleFormInfo}
                                                >
                                                    <MenuItem value={"First Series"}>First Series</MenuItem>
                                                    <MenuItem value={"Faith Series"}>Faith Series</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                                <ListItem>
                                    <Grid container>
                                        <Grid item md={6}>
                                            <FormControl
                                                fullWidth
                                                margin="none"
                                                required
                                                className={classes.spaceRight}
                                            >
                                                <InputLabel id="invoice">Invoice To:</InputLabel>
                                                <Select
                                                    fullWidth
                                                    labelId="invoice"
                                                    name="invoiceTo"
                                                    value={costs.invoiceTo}
                                                    onChange={handleFormInfo}
                                                >
                                                    <MenuItem value="">--Select--</MenuItem>
                                                    <MenuItem value={"School"}>School</MenuItem>
                                                    <MenuItem value={"Board"}>Board</MenuItem>
                                                    <MenuItem value={"Other"}>Other (Add address in notes)</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={6}>
                                            <FormControl required fullWidth margin="none" className={classes.spaceLeft}>
                                                <InputLabel id="ship">Ship To:</InputLabel>
                                                <Select
                                                    fullWidth
                                                    labelId="ship"
                                                    required
                                                    value={costs.shipTo}
                                                    onChange={handleFormInfo}
                                                    name="shipTo"
                                                >
                                                    <MenuItem value="">--Select--</MenuItem>
                                                    <MenuItem value="School">School</MenuItem>
                                                    <MenuItem value="Board">Board</MenuItem>
                                                    <MenuItem value="Other">Other (Add address in notes)</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                                <ListItem>
                                    <Grid container justify="space-between" spacing={1}>
                                        <Grid item md={6}>
                                            <FormControl fullWidth margin="dense" className={classes.notesBox}>
                                                <TextField
                                                    label="Notes:"
                                                    multiline
                                                    rows="6"
                                                    InputProps={{ disableUnderline: true }}
                                                    placeholder="Type to enter notes"
                                                    value={costs.contractNotes}
                                                    name="contractNotes"
                                                    onChange={handleFormInfo}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={6}>
                                            <FormControl fullWidth margin="dense" className={classes.notesBox2}>
                                                <TextField
                                                    color="secondary"
                                                    label="Internal Notes:"
                                                    className={classes.internalNotes}
                                                    multiline
                                                    rows="6"
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        classes: {
                                                            input: classes.internalNotes,
                                                        },
                                                    }}
                                                    placeholder="Type to enter notes"
                                                    value={costs.internalNotes}
                                                    name="internalNotes"
                                                    onChange={handleFormInfo}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                    {!loading ? (
                        <Table size="small" className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableBorder}>Items</TableCell>
                                    <TableCell className={classes.tableBorder} align="center">
                                        Unit Price
                                    </TableCell>
                                    {structure.plannerTypes.length !== 0 &&
                                        structure.plannerTypes.map((type) => {
                                            const tmp = structure.planners.find(
                                                (p) => p.sizes && p.plannerTypeId === type.id
                                            );
                                            return (
                                                <TableCell
                                                    key={type.id}
                                                    className={classes.tableBorder}
                                                    colSpan={2}
                                                    align="center"
                                                >
                                                    <div>{type.title}</div>

                                                    {tmp.sizes.length === 1 && <div>{tmp.sizes[0].title}</div>}
                                                    {tmp.sizes.length > 1 && (
                                                        <>
                                                            <Select
                                                                disableUnderline
                                                                onChange={(e) => handleSizeChange(type.id, e)}
                                                                padding="none"
                                                                value={
                                                                    costs.planners.find((item) => {
                                                                        return (
                                                                            item.sizeId &&
                                                                            item.plannerTypeId === type.id
                                                                        );
                                                                    })
                                                                        ? costs.planners.find((item) => {
                                                                              return (
                                                                                  item.sizeId &&
                                                                                  item.plannerTypeId === type.id
                                                                              );
                                                                          }).sizeId
                                                                        : 0
                                                                }
                                                            >
                                                                {structure.planners
                                                                    .find((p) => p.sizes && p.plannerTypeId === type.id)
                                                                    .sizes.map((s) => {
                                                                        return (
                                                                            <MenuItem key={s.id} value={s.id}>
                                                                                {s.title}
                                                                            </MenuItem>
                                                                        );
                                                                    })}
                                                            </Select>
                                                        </>
                                                    )}
                                                </TableCell>
                                            );
                                        })}
                                    <TableCell className={classes.tableBorder} align="center">
                                        Total Quantity
                                    </TableCell>
                                    <TableCell className={classes.tableBorder}>Total Price</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={clsx(classes.disabledCell, classes.tableBorder)} />
                                    <TableCell className={classes.tableBorder} align="center">
                                        <CurrencyInput
                                            prefix="$"
                                            precision={2}
                                            value={localCosts.overrideUnitPrice}
                                            onChangeEvent={overrideAllPlannersUnitPrice}
                                            style={{
                                                border: "none",
                                                textAlign: "center",
                                                width: "50px",
                                            }}
                                            onFocus={selectAll}
                                        />
                                    </TableCell>
                                    {costs.planners.length !== 0 &&
                                        costs.planners.map((p) => {
                                            // let tmp = costs.planners.find((type) => {
                                            //   return type.templatePlannerId === p.templatePlannerId;
                                            // });
                                            let tmp = p;
                                            // console.log(Object.keys(p));
                                            if (Object.keys(p).length === 1) {
                                                tmp = undefined;
                                            }
                                            // console.log(tmp);
                                            return tmp ? (
                                                <TableCell
                                                    key={p.templatePlannerId}
                                                    className={clsx(
                                                        classes.tableBorder,
                                                        !p.english && classes.frenchCol
                                                    )}
                                                    align="center"
                                                >
                                                    <div>{p.english ? "English" : "French"}</div>
                                                    <div>
                                                        <CurrencyInput
                                                            prefix="$"
                                                            precision={2}
                                                            id={tmp.templatePlannerId}
                                                            className={!p.english ? classes.frenchCol : undefined}
                                                            name="unitPrice"
                                                            value={returnValue(tmp, "unitPrice")}
                                                            onChangeEvent={handleCostChange}
                                                            style={{
                                                                border: "none",
                                                                textAlign: "center",
                                                                width: "50px",
                                                            }}
                                                            onFocus={selectAll}
                                                        />
                                                    </div>
                                                </TableCell>
                                            ) : (
                                                <TableCell
                                                    key={p.templatePlannerId}
                                                    className={clsx(classes.disabledCell, classes.tableBorder)}
                                                ></TableCell>
                                            );
                                        })}
                                    <TableCell className={clsx(classes.disabledCell, classes.tableBorder)} />
                                    <TableCell className={clsx(classes.disabledCell, classes.tableBorder)} />
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableBorder}>Quantity</TableCell>
                                    <TableCell className={clsx(classes.disabledCell, classes.tableBorder)} />
                                    {costs.planners.length !== 0 &&
                                        costs.planners.map((p) => {
                                            let tmp = p;
                                            if (Object.keys(p).length === 1) {
                                                tmp = undefined;
                                            }
                                            return tmp ? (
                                                <TableCell
                                                    key={p.templatePlannerId}
                                                    className={clsx(
                                                        classes.tableBorder,
                                                        !p.english && classes.frenchCol
                                                    )}
                                                >
                                                    <TextField
                                                        size="small"
                                                        name="quantity"
                                                        value={returnValue(tmp, "quantity")}
                                                        onChange={(e) => handleQuantity(e, tmp)}
                                                        inputProps={{
                                                            style: { textAlign: "center" },
                                                        }}
                                                        InputProps={{ disableUnderline: true }}
                                                        onFocus={selectAll}
                                                    />
                                                </TableCell>
                                            ) : (
                                                <TableCell
                                                    key={p.templatePlannerId}
                                                    className={clsx(classes.disabledCell, classes.tableBorder)}
                                                ></TableCell>
                                            );
                                        })}
                                    <TableCell
                                        align="center"
                                        name="totalQuantity"
                                        className={clsx(classes.tableBorder, classes.noneditField)}
                                    >
                                        {costs.totalQuantity !== undefined && costs.totalQuantity}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        name="totalPrice"
                                        className={clsx(classes.tableBorder, classes.noneditField)}
                                    >
                                        {costs.totalPrice !== undefined && `$${costs.totalPrice.toFixed(2)}`}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableBorder}>Front Cover</TableCell>
                                    <TableCell className={classes.tableBorder} align="center">
                                        <CurrencyInput
                                            name="unitPriceFrontCover"
                                            precision={2}
                                            prefix="$"
                                            value={costs.unitPriceFrontCover}
                                            onChangeEvent={handleCostChange}
                                            style={{
                                                border: "none",
                                                textAlign: "center",
                                                width: "60px",
                                            }}
                                            onFocus={selectAll}
                                        />
                                    </TableCell>

                                    {costs.planners.length !== 0 &&
                                        costs.planners.map((p) => {
                                            let tmp = p;
                                            if (Object.keys(p).length === 1) {
                                                tmp = undefined;
                                            }
                                            return tmp ? (
                                                <TableCell
                                                    key={p.templatePlannerId}
                                                    className={clsx(
                                                        classes.tableBorder,
                                                        classes.selectPadding,
                                                        !p.english && classes.frenchCol
                                                    )}
                                                    align="center"
                                                >
                                                    <FormControl className={classes.selectPadding}>
                                                        <Select
                                                            disableUnderline
                                                            className={!p.english ? classes.frenchCol : null}
                                                            name="frontCoverId"
                                                            value={returnValue(tmp, "frontCoverId")}
                                                            onChange={(e) => handleFormInfo(e, tmp)}
                                                        >
                                                            {tmp.sizeId > 0 && (
                                                                <ListSubheader>--SELECT--</ListSubheader>
                                                            )}
                                                            {tmp.sizeId > 0 && <Divider />}
                                                            {tmp.sizeId > 0 ? (
                                                                structure.frontCovers
                                                                    .filter(
                                                                        (c) =>
                                                                            (c.sizeId === tmp.sizeId &&
                                                                                c.english === tmp.english) ||
                                                                            (c.sizeId === tmp.sizeId &&
                                                                                c.french === !tmp.english)
                                                                    )
                                                                    .map((cover) => (
                                                                        <MenuItem key={cover.id} value={cover.id}>
                                                                            {cover.title}
                                                                        </MenuItem>
                                                                    ))
                                                            ) : (
                                                                <ListSubheader>Select Size</ListSubheader>
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </TableCell>
                                            ) : (
                                                <TableCell
                                                    key={p.templatePlannerId}
                                                    className={clsx(classes.disabledCell, classes.tableBorder)}
                                                ></TableCell>
                                            );
                                        })}
                                    <TableCell className={clsx(classes.disabledCell, classes.tableBorder)}></TableCell>
                                    <TableCell
                                        className={clsx(classes.tableBorder, classes.noneditField)}
                                        align="right"
                                    >
                                        {costs.frontTotalPrice !== undefined && `$${costs.frontTotalPrice.toFixed(2)}`}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableBorder}>Back Cover</TableCell>
                                    <TableCell className={classes.tableBorder} align="center">
                                        <CurrencyInput
                                            name="unitPriceBackCover"
                                            precision={2}
                                            prefix="$"
                                            value={costs.unitPriceBackCover}
                                            onChangeEvent={handleCostChange}
                                            style={{
                                                border: "none",
                                                textAlign: "center",
                                                width: "60px",
                                            }}
                                            onFocus={selectAll}
                                        />
                                    </TableCell>
                                    {costs.planners.length !== 0 &&
                                        costs.planners.map((p) => {
                                            let tmp = p;
                                            if (Object.keys(p).length === 1) {
                                                tmp = undefined;
                                            }
                                            return tmp ? (
                                                <TableCell
                                                    key={p.templatePlannerId}
                                                    className={clsx(
                                                        classes.tableBorder,
                                                        classes.selectPadding,
                                                        !p.english && classes.frenchCol
                                                    )}
                                                    align="center"
                                                >
                                                    <FormControl className={classes.selectPadding}>
                                                        <Select
                                                            disableUnderline
                                                            name="backCoverId"
                                                            className={!p.english ? classes.frenchCol : null}
                                                            value={returnValue(tmp, "backCoverId")}
                                                            onChange={(e) => handleFormInfo(e, tmp)}
                                                        >
                                                            {tmp.sizeId > 0 && (
                                                                <ListSubheader>--SELECT--</ListSubheader>
                                                            )}
                                                            {tmp.sizeId > 0 && <Divider />}
                                                            {tmp.sizeId > 0 ? (
                                                                structure.backCovers
                                                                    .filter(
                                                                        (c) =>
                                                                            (c.sizeId === tmp.sizeId &&
                                                                                c.english === tmp.english) ||
                                                                            (c.sizeId === tmp.sizeId &&
                                                                                c.french === !tmp.english)
                                                                    )
                                                                    .map((cover) => (
                                                                        <MenuItem key={cover.id} value={cover.id}>
                                                                            {cover.title}
                                                                        </MenuItem>
                                                                    ))
                                                            ) : (
                                                                <ListSubheader>Select Size</ListSubheader>
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </TableCell>
                                            ) : (
                                                <TableCell
                                                    key={p.templatePlannerId}
                                                    className={clsx(classes.disabledCell, classes.tableBorder)}
                                                ></TableCell>
                                            );
                                        })}

                                    <TableCell className={clsx(classes.disabledCell, classes.tableBorder)} />
                                    <TableCell
                                        className={clsx(classes.tableBorder, classes.noneditField)}
                                        align="right"
                                    >
                                        {costs.backTotalPrice !== undefined && `$${costs.backTotalPrice.toFixed(2)}`}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                            {structure.supplements && (
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableBorder} colSpan={4 + costs.planners.length}>
                                            Supplements
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            )}

                            {structure.supplements && (
                                <TableBody>
                                    {structure.supplements.map((s) => {
                                        const item = s.title.replace(/\s|-/g, "");
                                        return (
                                            <TableRow key={s.id}>
                                                <TableCell className={classes.tableBorder}>{s.title}</TableCell>
                                                <TableCell className={classes.tableBorder} align="center">
                                                    <CurrencyInput
                                                        name={`unitPrice${item}`}
                                                        id={s.id}
                                                        precision={2}
                                                        prefix="$"
                                                        value={costs[`unitPrice${item}`]}
                                                        onChangeEvent={handleCostChange}
                                                        style={{
                                                            border: "none",
                                                            textAlign: "center",
                                                            width: "60px",
                                                        }}
                                                        onFocus={selectAll}
                                                    />
                                                </TableCell>
                                                {costs.planners.map((p) => {
                                                    let tmp = p;
                                                    // let tmp = costs.planners.find((plan) => {
                                                    //   return (
                                                    //     plan.templatePlannerId === p.templatePlannerId
                                                    //   );
                                                    // });
                                                    let exist = undefined;
                                                    if (Object.keys(p).length === 1) {
                                                        tmp = undefined;
                                                    } else {
                                                        exist = tmp.supplements.find(
                                                            (i) => i.templateSupplementId === s.id
                                                        );
                                                    }
                                                    if (tmp) {
                                                        return (s.english && tmp.english) ||
                                                            (s.french && !tmp.english) ? (
                                                            <TableCell
                                                                key={p.templatePlannerId}
                                                                className={clsx(
                                                                    classes.tableBorder,
                                                                    !p.english && classes.frenchCol
                                                                )}
                                                                align="center"
                                                            >
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={exist !== undefined}
                                                                    onChange={(e) =>
                                                                        handleCheckbox(
                                                                            e,
                                                                            tmp,
                                                                            s.id,
                                                                            costs[`unitPrice${item}`]
                                                                        )
                                                                    }
                                                                />
                                                            </TableCell>
                                                        ) : (
                                                            <TableCell
                                                                key={p.templatePlannerId}
                                                                className={clsx(
                                                                    classes.disabledCell,
                                                                    classes.tableBorder
                                                                )}
                                                            ></TableCell>
                                                        );
                                                    } else {
                                                        return (
                                                            <TableCell
                                                                key={p.templatePlannerId}
                                                                className={clsx(
                                                                    classes.tableBorder,
                                                                    classes.disabledCell
                                                                )}
                                                            />
                                                        );
                                                    }
                                                })}
                                                <TableCell
                                                    align="center"
                                                    className={clsx(classes.tableBorder, classes.noneditField)}
                                                >
                                                    {costs[`quantity${item}`]}
                                                </TableCell>
                                                <TableCell
                                                    className={clsx(classes.tableBorder, classes.noneditField)}
                                                    align="right"
                                                >
                                                    {costs[`total${item}`] !== undefined &&
                                                        `$${costs[`total${item}`].toFixed(2)}`}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            )}

                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableBorder} colSpan={4 + costs.planners.length}>
                                        School Pages (1 page is 0.35 cents)
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.tableBorder}>B&W Unit Cost</TableCell>
                                    <TableCell className={classes.tableBorder} align="center">
                                        <CurrencyInput
                                            name="unitPriceSchoolPageBW"
                                            precision={3}
                                            prefix="$"
                                            value={costs.unitPriceSchoolPageBW}
                                            onChangeEvent={overrideBWUnitPrice}
                                            style={{
                                                border: "none",
                                                textAlign: "center",
                                                width: "60px",
                                            }}
                                            onFocus={selectAll}
                                        />
                                    </TableCell>
                                    {costs.planners.length !== 0 &&
                                        costs.planners.map((p) => {
                                            let tmp = p;
                                            if (Object.keys(p).length === 1) {
                                                tmp = undefined;
                                            }
                                            return tmp ? (
                                                <TableCell
                                                    key={p.templatePlannerId}
                                                    className={clsx(
                                                        classes.tableBorder,
                                                        !p.english && classes.frenchCol
                                                    )}
                                                    align="center"
                                                >
                                                    <CurrencyInput
                                                        precision={3}
                                                        prefix="$"
                                                        id={tmp.templatePlannerId}
                                                        className={!p.english ? classes.frenchCol : undefined}
                                                        name="schoolPagesBWPrice"
                                                        value={returnValue(tmp, "schoolPagesBWPrice")}
                                                        onChangeEvent={handleCostChange}
                                                        style={{
                                                            border: "none",
                                                            textAlign: "center",
                                                            width: "60px",
                                                        }}
                                                        onFocus={selectAll}
                                                    />
                                                </TableCell>
                                            ) : (
                                                <TableCell
                                                    key={p.templatePlannerId}
                                                    className={clsx(classes.tableBorder, classes.disabledCell)}
                                                />
                                            );
                                        })}
                                    <TableCell className={clsx(classes.tableBorder, classes.disabledCell)} />
                                    <TableCell
                                        className={clsx(classes.tableBorder, classes.disabledCell)}
                                        align="right"
                                    >
                                        {/* {costs.bwTotalPrice !== undefined &&
                      `$${costs.bwTotalPrice.toFixed(2)}`} */}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableBorder}># of Free B&W Pages</TableCell>
                                    <TableCell
                                        className={clsx(classes.tableBorder, classes.noneditField)}
                                        align="center"
                                    >
                                        Board Units: {costs.schoolPageBWBoardUnits}
                                    </TableCell>
                                    {costs.planners.length !== 0 &&
                                        costs.planners.map((p) => {
                                            let tmp = p;
                                            if (Object.keys(p).length === 1) {
                                                tmp = undefined;
                                            }
                                            return tmp ? (
                                                <TableCell
                                                    key={p.templatePlannerId}
                                                    className={clsx(
                                                        classes.tableBorder,
                                                        !p.english && classes.frenchCol
                                                    )}
                                                    align="center"
                                                >
                                                    <TextField
                                                        size="small"
                                                        inputProps={{
                                                            style: { textAlign: "center" },
                                                        }}
                                                        InputProps={{ disableUnderline: true }}
                                                        name="schoolPagesBWFree"
                                                        value={returnValue(tmp, "schoolPagesBWFree")}
                                                        onChange={(e) => handleQuantity(e, tmp)}
                                                        onFocus={selectAll}
                                                    />
                                                </TableCell>
                                            ) : (
                                                <TableCell
                                                    key={p.templatePlannerId}
                                                    className={clsx(classes.tableBorder, classes.disabledCell)}
                                                />
                                            );
                                        })}
                                    <TableCell className={clsx(classes.tableBorder, classes.disabledCell)} />
                                    <TableCell className={clsx(classes.tableBorder, classes.disabledCell)} />
                                </TableRow>

                                <TableRow>
                                    <TableCell className={classes.tableBorder}>
                                        Total # of B&W Pages (Free & Paid)
                                    </TableCell>
                                    <TableCell className={clsx(classes.tableBorder, classes.disabledCell)} />
                                    {costs.planners.length !== 0 &&
                                        costs.planners.map((p) => {
                                            let tmp = p;
                                            if (Object.keys(p).length === 1) {
                                                tmp = undefined;
                                            }
                                            return tmp ? (
                                                <TableCell
                                                    key={p.templatePlannerId}
                                                    className={clsx(
                                                        classes.tableBorder,
                                                        !p.english && classes.frenchCol
                                                    )}
                                                    align="center"
                                                >
                                                    <TextField
                                                        size="small"
                                                        inputProps={{
                                                            style: { textAlign: "center" },
                                                        }}
                                                        InputProps={{ disableUnderline: true }}
                                                        name="schoolPagesBWUnits"
                                                        value={returnValue(tmp, "schoolPagesBWUnits")}
                                                        onChange={(e) => handleQuantity(e, tmp)}
                                                        onFocus={selectAll}
                                                    />
                                                </TableCell>
                                            ) : (
                                                <TableCell
                                                    key={p.templatePlannerId}
                                                    className={clsx(classes.tableBorder, classes.disabledCell)}
                                                />
                                            );
                                        })}
                                    <TableCell className={clsx(classes.tableBorder, classes.disabledCell)} />
                                    <TableCell
                                        className={clsx(classes.tableBorder, classes.noneditField)}
                                        align="right"
                                    >
                                        {costs.bwTotalPrice !== undefined && `$${costs.bwTotalPrice.toFixed(2)}`}
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell className={classes.tableBorder}># of Colour Pages</TableCell>
                                    <TableCell className={classes.tableBorder} align="center">
                                        <CurrencyInput
                                            name="unitPriceSchoolPageColour"
                                            precision={2}
                                            prefix="$"
                                            value={costs.unitPriceSchoolPageColour}
                                            onChangeEvent={handleCostChange}
                                            style={{
                                                border: "none",
                                                textAlign: "center",
                                                width: "60px",
                                            }}
                                            onFocus={selectAll}
                                        />
                                    </TableCell>
                                    {costs.planners.length !== 0 &&
                                        costs.planners.map((p) => {
                                            let tmp = p;
                                            if (Object.keys(p).length === 1) {
                                                tmp = undefined;
                                            }
                                            return tmp ? (
                                                <TableCell
                                                    key={p.templatePlannerId}
                                                    className={clsx(
                                                        classes.tableBorder,
                                                        !p.english && classes.frenchCol
                                                    )}
                                                    align="center"
                                                >
                                                    <TextField
                                                        size="small"
                                                        inputProps={{ style: { textAlign: "center" } }}
                                                        InputProps={{ disableUnderline: true }}
                                                        onFocus={selectAll}
                                                        name="schoolPagesColourUnits"
                                                        value={returnValue(tmp, "schoolPagesColourUnits")}
                                                        onChange={(e) => handleQuantity(e, tmp)}
                                                    />
                                                </TableCell>
                                            ) : (
                                                <TableCell
                                                    key={p.templatePlannerId}
                                                    className={clsx(classes.disabledCell, classes.tableBorder)}
                                                />
                                            );
                                        })}
                                    <TableCell className={clsx(classes.disabledCell, classes.tableBorder)} />
                                    <TableCell
                                        className={clsx(classes.tableBorder, classes.noneditField)}
                                        align="right"
                                    >
                                        {costs.colourTotalPrice !== undefined &&
                                            `$${costs.colourTotalPrice.toFixed(2)}`}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableBorder}>Total Per Planner</TableCell>
                                    <TableCell
                                        className={clsx(classes.tableBorder, classes.disabledCell)}
                                        align="center"
                                    />
                                    {costs.planners.length !== 0 &&
                                        costs.planners.map((p) => {
                                            let tmp = p;
                                            if (Object.keys(p).length === 1) {
                                                tmp = undefined;
                                            }
                                            return tmp ? (
                                                <TableCell
                                                    key={p.templatePlannerId}
                                                    className={clsx(
                                                        classes.tableBorder,
                                                        classes.noneditField,
                                                        !p.english && classes.frenchCol
                                                    )}
                                                    align="center"
                                                >
                                                    ${/* {p.schoolPagesBWUnits > 0 */}
                                                    {p.schoolPagesBWUnits > p.schoolPagesBWFree
                                                        ? (
                                                              Math.round(
                                                                  ((p.schoolPagesBWUnits - p.schoolPagesBWFree) *
                                                                      p.schoolPagesBWPrice +
                                                                      p.schoolPagesColourUnits *
                                                                          costs.unitPriceSchoolPageColour) *
                                                                      100
                                                              ) / 100
                                                          ).toFixed(2)
                                                        : (
                                                              Math.round(
                                                                  (0 +
                                                                      p.schoolPagesColourUnits *
                                                                          costs.unitPriceSchoolPageColour) *
                                                                      100
                                                              ) / 100
                                                          ).toFixed(2)}
                                                </TableCell>
                                            ) : (
                                                <TableCell
                                                    key={p.templatePlannerId}
                                                    className={clsx(classes.disabledCell, classes.tableBorder)}
                                                />
                                            );
                                        })}
                                    <TableCell className={clsx(classes.disabledCell, classes.tableBorder)} />
                                    <TableCell
                                        className={clsx(classes.tableBorder, classes.noneditField)}
                                        align="right"
                                    >
                                        {costs.colourTotalPrice !== undefined &&
                                            `$${(costs.colourTotalPrice + costs.bwTotalPrice).toFixed(2)}`}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableBorder} colSpan={4 + costs.planners.length}>
                                        Others
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.tableBorder}>Communication Pouch</TableCell>
                                    <TableCell className={classes.tableBorder} align="center">
                                        <CurrencyInput
                                            name="pouchUnitPrice"
                                            precision={2}
                                            prefix="$"
                                            value={costs.pouchUnitPrice}
                                            onChangeEvent={handleCostChange}
                                            style={{
                                                border: "none",
                                                textAlign: "center",
                                                width: "60px",
                                            }}
                                            onFocus={selectAll}
                                        />
                                    </TableCell>

                                    <TableCell className={classes.tableBorder} colSpan={costs.planners.length}>
                                        <TextField
                                            placeholder="Enter notes"
                                            fullWidth
                                            InputProps={{ disableUnderline: true }}
                                            value={costs.pouchNote}
                                            name="pouchNote"
                                            onChange={handleFormInfo}
                                        />
                                    </TableCell>
                                    <TableCell className={classes.tableBorder} align="center">
                                        <TextField
                                            size="small"
                                            inputProps={{ style: { textAlign: "center" } }}
                                            InputProps={{ disableUnderline: true }}
                                            placeholder="Qty"
                                            value={costs.pouchQuantity}
                                            name="pouchQuantity"
                                            onChange={handleQuantity}
                                            onFocus={selectAll}
                                        />
                                    </TableCell>
                                    <TableCell
                                        className={clsx(classes.tableBorder, classes.noneditField)}
                                        align="right"
                                    >
                                        {costs.pouchTotal !== undefined && `$${costs.pouchTotal.toFixed(2)}`}
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell className={clsx(classes.divideTable, classes.tableBorder)}>
                                        Teacher Planner
                                    </TableCell>
                                    <TableCell className={classes.tableBorder} align="center">
                                        <CurrencyInput
                                            name="teacherPlannerUnitPrice"
                                            precision={2}
                                            prefix="$"
                                            value={costs.teacherPlannerUnitPrice}
                                            onChangeEvent={handleCostChange}
                                            style={{
                                                border: "none",
                                                textAlign: "center",
                                                width: "60px",
                                            }}
                                            onFocus={selectAll}
                                        />
                                    </TableCell>
                                    <TableCell className={classes.tableBorder} align="center">
                                        <FormControl style={{ borderBottom: "none" }}>
                                            <Select
                                                disableUnderline
                                                value={costs.teacherPlannerCover}
                                                name="teacherPlannerCover"
                                                onChange={handleFormInfo}
                                            >
                                                <ListSubheader>Covers</ListSubheader>
                                                <Divider />
                                                {structure.teacherPlannerTypes.map((t) => (
                                                    <MenuItem key={t} value={t}>
                                                        {t}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell className={classes.tableBorder} colSpan={costs.planners.length - 1}>
                                        <TextField
                                            placeholder="Enter notes"
                                            fullWidth
                                            InputProps={{ disableUnderline: true }}
                                            name="teacherPlannerNote"
                                            value={costs.teacherPlannerNote}
                                            onChange={handleFormInfo}
                                        />
                                    </TableCell>
                                    <TableCell className={classes.tableBorder} align="center">
                                        <TextField
                                            size="small"
                                            value={costs.teacherPlannerQuantity}
                                            name="teacherPlannerQuantity"
                                            inputProps={{ style: { textAlign: "center" } }}
                                            InputProps={{ disableUnderline: true }}
                                            placeholder="Qty"
                                            onChange={handleQuantity}
                                            onFocus={selectAll}
                                        />
                                    </TableCell>
                                    <TableCell
                                        className={clsx(classes.tableBorder, classes.noneditField)}
                                        align="right"
                                    >
                                        {costs.teacherPlannerTotal !== undefined &&
                                            `$${costs.teacherPlannerTotal.toFixed(2)}`}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableBorder}>Staff Monthly</TableCell>
                                    <TableCell className={classes.tableBorder} align="center">
                                        <CurrencyInput
                                            name="staffMonthlyUnitPrice"
                                            precision={2}
                                            prefix="$"
                                            value={costs.staffMonthlyUnitPrice}
                                            onChangeEvent={handleCostChange}
                                            style={{
                                                border: "none",
                                                textAlign: "center",
                                                width: "60px",
                                            }}
                                            onFocus={selectAll}
                                        />
                                    </TableCell>

                                    <TableCell className={classes.tableBorder} colSpan={costs.planners.length}>
                                        <TextField
                                            placeholder="Enter notes"
                                            fullWidth
                                            InputProps={{ disableUnderline: true }}
                                            value={costs.staffMonthlyNote}
                                            name="staffMonthlyNote"
                                            onChange={handleFormInfo}
                                        />
                                    </TableCell>
                                    <TableCell className={classes.tableBorder} align="center">
                                        <TextField
                                            size="small"
                                            inputProps={{ style: { textAlign: "center" } }}
                                            InputProps={{ disableUnderline: true }}
                                            placeholder="Qty"
                                            value={costs.staffMonthlyQuantity}
                                            name="staffMonthlyQuantity"
                                            onChange={handleQuantity}
                                            onFocus={selectAll}
                                        />
                                    </TableCell>
                                    <TableCell
                                        className={clsx(classes.tableBorder, classes.noneditField)}
                                        align="right"
                                    >
                                        {costs.staffMonthlyTotal !== undefined &&
                                            `$${costs.staffMonthlyTotal.toFixed(2)}`}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                            <TableBody>
                                <TableRow>
                                    <TableCell
                                        rowSpan={4}
                                        colSpan={5}
                                        align="center"
                                        className={classes.noDefaultBorder}
                                    >
                                        <Button variant="outlined" onClick={calculate}>
                                            Calculate
                                        </Button>
                                    </TableCell>

                                    <TableCell rowSpan={9} align="center" colSpan={costs.planners.length - 5}>
                                        <img src="/images/deadlinedates2024.png" alt="2024 deadline dates" />
                                    </TableCell>

                                    <TableCell colSpan={2}>Less than 100 units fee:</TableCell>
                                    <TableCell align="right" colSpan={2}>
                                        ${costs.baseFee.toFixed(2)}
                                    </TableCell>
                                    {/* <TableCell colSpan={2}>Subtotal:</TableCell>
                                    <TableCell align="right" colSpan={2}>
                                        ${costs.calcSubtotal.toFixed(2)}
                                    </TableCell> */}
                                </TableRow>
                                {/* <TableRow>
                                    <TableCell colSpan={2}>Less than 100 units fee:</TableCell>
                                    <TableCell align="right" colSpan={2}>
                                        ${costs.calcAddMinFee.toFixed(2)}
                                    </TableCell>
                                </TableRow> */}
                                <TableRow>
                                    <TableCell colSpan={2}>Discount:</TableCell>
                                    <TableCell align="right" colSpan={2}>
                                        <CurrencyInput
                                            name="baseFeeDiscount"
                                            precision={2}
                                            prefix="-$"
                                            value={costs.baseFeeDiscount}
                                            onChangeEvent={handleCostChange}
                                            style={{
                                                border: "none",
                                                textAlign: "right",
                                            }}
                                            onFocus={selectAll}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2}>Subtotal:</TableCell>
                                    <TableCell align="right" colSpan={2}>
                                        ${costs.calcSubtotal.toFixed(2)}
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell colSpan={2}>
                                        Shipping:{" "}
                                        <Select
                                            value={costs.shippingType}
                                            name="shippingType"
                                            disableUnderline
                                            onChange={handleFormInfo}
                                        >
                                            <MenuItem value={3}>($0.50/planner)</MenuItem>
                                            <MenuItem value={1}>FREE</MenuItem>
                                            <MenuItem value={2}>TBD</MenuItem>
                                        </Select>
                                    </TableCell>
                                    <TableCell align="right" colSpan={2}>
                                        ${costs.calcShipping.toFixed(2)}
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell colSpan={5}>
                                        <Typography variant="subtitle2" display="inline">
                                            Contract ID:{" "}
                                        </Typography>

                                        <Typography variant="subtitle1" display="inline">
                                            {costs.contractId}
                                        </Typography>
                                    </TableCell>

                                    <TableCell colSpan={2}>Sub+Shipping:</TableCell>
                                    <TableCell align="right" colSpan={2}>
                                        ${(costs.calcSubtotal + costs.calcShipping).toFixed(2)}
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell colSpan={5}>
                                        <Typography variant="subtitle2" display="inline">
                                            Contract State:{" "}
                                        </Typography>
                                        <Typography variant="subtitle1" display="inline">
                                            {costs.contractState}
                                        </Typography>
                                    </TableCell>
                                    <TableCell colSpan={2}>GST ({(costs.gst * 100).toFixed(2)}%):</TableCell>
                                    <TableCell align="right" colSpan={2}>
                                        ${costs.calcGST.toFixed(2)}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={5}>
                                        <Typography variant="subtitle2" display="inline">
                                            Signed:{" "}
                                        </Typography>
                                        <Typography variant="subtitle1" display="inline">
                                            {costs.signingName}
                                        </Typography>
                                    </TableCell>
                                    <TableCell colSpan={2}>PST ({(costs.pst * 100).toFixed(2)}%):</TableCell>
                                    <TableCell align="right" colSpan={2}>
                                        ${costs.calcPST.toFixed(2)}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={5}>
                                        <Typography variant="subtitle2" display="inline">
                                            Signed Date:{" "}
                                        </Typography>
                                        <Typography variant="subtitle1" display="inline">
                                            {costs.signingDate && getDateFormat(costs.signingDate)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell colSpan={2}>HST ({(costs.hst * 100).toFixed(2)}%):</TableCell>
                                    <TableCell align="right" colSpan={2}>
                                        ${costs.calcHST.toFixed(2)}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={5}>
                                        <Typography variant="subtitle2" display="inline">
                                            Change Requests:{" "}
                                        </Typography>
                                        <Typography variant="subtitle1" display="inline">
                                            {costs.clientChangeRequests}
                                        </Typography>
                                    </TableCell>
                                    <TableCell colSpan={2}>Grand Total:</TableCell>
                                    <TableCell align="right" colSpan={2}>
                                        ${costs.calcTotal.toFixed(2)}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    ) : (
                        <CircularProgress className={classes.loading} />
                    )}
                    <Box>
                        <Grid container justify="center" spacing={2}>
                            <Grid item md={4} sm={12} xs={12}>
                                <div className={classes.box}>
                                    <Typography variant="subtitle1">Incentives:</Typography>
                                    <List>
                                        <ListItem className={classes.noPadding}>
                                            <Checkbox
                                                name="materialHandbook"
                                                checked={costs.materialHandbook}
                                                onChange={handleCheckbox}
                                            />
                                            Handbook and/or Quantities:
                                            <FormControl variant="standard" margin="none" fullWidth>
                                                <Select
                                                    fullWidth
                                                    disabled={costs.materialHandbookDate === "2021-04-16T00:00:00"}
                                                    padding="none"
                                                    name="materialHandbookDate"
                                                    displayEmpty
                                                    value={costs.materialHandbookDate}
                                                    onChange={handleFormInfo}
                                                >
                                                    {costs.materialHandbook && <MenuItem value={""}>N/A</MenuItem>}
                                                    {structure.materialDeadlines && costs.materialHandbook ? (
                                                        structure.materialDeadlines.map((date) => (
                                                            <MenuItem value={date.deadline}>
                                                                {getDateFormat(date.deadline)}
                                                            </MenuItem>
                                                        ))
                                                    ) : (
                                                        <ListSubheader>
                                                            Inform Client about Incentive Dates
                                                        </ListSubheader>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </ListItem>
                                        <ListItem className={classes.noPadding}>
                                            <Checkbox
                                                name="materialCover"
                                                checked={costs.materialCover}
                                                onChange={handleCheckbox}
                                            />
                                            Covers and Quantities confirmed:
                                            <FormControl variant="standard" margin="none" fullWidth>
                                                <Select
                                                    fullWidth
                                                    disabled={costs.materialCoverDate === "2021-04-16T00:00:00"}
                                                    padding="none"
                                                    name="materialCoverDate"
                                                    displayEmpty
                                                    value={costs.materialCoverDate}
                                                    onChange={handleFormInfo}
                                                >
                                                    {costs.materialCover && <MenuItem value={""}>N/A</MenuItem>}
                                                    {structure.materialDeadlines && costs.materialCover ? (
                                                        structure.materialDeadlines.map((date) => (
                                                            <MenuItem value={date.deadline}>
                                                                {getDateFormat(date.deadline)}
                                                            </MenuItem>
                                                        ))
                                                    ) : (
                                                        <ListSubheader>
                                                            Inform Client about Incentive Dates
                                                        </ListSubheader>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </ListItem>
                                    </List>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={5}
                                sm={12}
                                xs={12}
                                className={classes.box}
                                style={{ textAlign: "center", margin: "auto" }}
                            >
                                {costs.materialCover && costs.materialHandbook && (
                                    <div className={classes.box}>
                                        <Typography variant="subtitle1">Delivery the week of:</Typography>
                                        <FormControl className={classes.formControl} fullWidth>
                                            <Select
                                                autoWidth
                                                name="deliveryWeek"
                                                value={localCosts.deliveryWeek}
                                                onChange={handleDelieveryWeek}
                                            >
                                                <MenuItem value={""}>--SELECT---</MenuItem>
                                                <MenuItem value={"asap"}>ASAP</MenuItem>
                                                <MenuItem value={"n/a"}>N/A</MenuItem>
                                                {structure.deliveryWeeks &&
                                                    structure.deliveryWeeks.map((date) => (
                                                        <MenuItem
                                                            value={`{"start": "${date.startDate}","end": "${date.endDate}"}`}
                                                        >
                                                            {date.title}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                        <FormControl className={classes.formControl} fullWidth>
                                            <LocalizationProvider dateAdapter={MomentUtils}>
                                                <DateRangePicker
                                                    onError={([startReason, endReason], [start, end]) => {
                                                        if (startReason === "invalidRange" && start === end) {
                                                            setDateError([null, null]);
                                                            return;
                                                        }
                                                        setDateError([startReason, endReason]);
                                                    }}
                                                    disabled={localCosts.deliveryWeek === "n/a" ? false : true}
                                                    startText="Start Date:"
                                                    endText="End Date:"
                                                    value={value}
                                                    onChange={(newValue) => handleDeliveryDates(newValue)}
                                                    renderInput={(startProps, endProps) => (
                                                        <>
                                                            <TextField
                                                                {...startProps}
                                                                fullWidth
                                                                error={Boolean(dateError[0])}
                                                                variant="standard"
                                                            />
                                                            <DateRangeDelimiter> to </DateRangeDelimiter>
                                                            <TextField
                                                                {...endProps}
                                                                fullWidth
                                                                error={Boolean(dateError[1])}
                                                                variant="standard"
                                                            />
                                                        </>
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </div>
                                )}
                            </Grid>
                            <Grid item md={3} sm={12} xs={12}>
                                <div className={classes.box}>
                                    <List>
                                        {/* <ListItem className={classes.noPadding}>
                                            <FormControlLabel
                                                // className={classes.signedCheck}
                                                control={
                                                    <Checkbox
                                                        name="quote"
                                                        checked={costs.quote}
                                                        onChange={handleCheckbox}
                                                        color="secondary"
                                                    />
                                                }
                                                label={<Typography align="left">Quote</Typography>}
                                            />
                                        </ListItem> */}
                                        <ListItem className={classes.noPadding}>
                                            <FormControlLabel
                                                // className={classes.signedCheck}
                                                control={
                                                    <Checkbox
                                                        name="hardCopy"
                                                        checked={costs.hardCopy}
                                                        onChange={handleCheckbox}
                                                        color="primary"
                                                    />
                                                }
                                                label={
                                                    <Typography align="left">
                                                        Signed order form already on file received from client, do not
                                                        resend for signature.
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    </List>
                                </div>
                                <div style={{ textAlign: "center", margin: "auto" }}>
                                    <Button
                                        disabled={disableBtn || costs.contractState === "Completed"}
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className={classes.submitButton}
                                        onClick={(e) => submitContract(e, [true, "save"])}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        disabled={disableBtn || costs.hardCopy || costs.contractState === "Completed"}
                                        variant="contained"
                                        color="primary"
                                        onClick={(e) => {
                                            calculate();
                                            if (isBWPagesSet()) {
                                                setSendConfirmation(true);
                                            } else {
                                                setMissingBWPageMessage([
                                                    "Missing B&W Pages",
                                                    "Please fill in the # of B&W Pages.",
                                                ]);
                                                setDisableBtn(false);
                                            }
                                        }}
                                        className={classes.submitButton}
                                    >
                                        Save & Send
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </Card>
            {/* create */}
            <Dialog fullScreen open={createContactOpen} TransitionComponent={Transition} onClose={openCreateContact}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={openCreateContact} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography componet="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                            {newContact ? "Add Contact" : "Edit Contact"}
                        </Typography>
                    </Toolbar>
                </AppBar>
                {newContact ? (
                    <CreateContactForm schoolId={props.schoolInfo.id} saved={openCreateContact} />
                ) : (
                    <ContactForm
                        schoolId={props.schoolInfo.schoolId}
                        contactId={contactId}
                        contactDetail={contact}
                        updated={openCreateContact}
                    />
                )}
            </Dialog>
            <Dialog open={formValidationMessage !== ""} onClose={() => setFormValidationMessage("")}>
                <DialogTitle>
                    <Typography variant="subtitle1">Please check that all required(*) fields are filled.</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText variant="subtitle1">Error message: {formValidationMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setFormValidationMessage("")} color="primary">
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={sendConfirmation}>
                <DialogContent>
                    <DialogContentText variant="subtitle1">
                        Are you sure you want to send this{" "}
                        {costs.calcTotal === 0 && costs.shippingType !== 2 && <strong>FREE</strong>}
                        {costs.calcTotal === 0 && costs.shippingType !== 2 && " order ($0.00)"} to the client?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setSendConfirmation(false)} color="primary">
                        No
                    </Button>
                    <Button
                        onClick={(e) => {
                            setSendConfirmation(false);
                            submitContract(e, [true, "send"]);
                        }}
                        color="primary"
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={missingBWPageMessage.length !== 0}>
                <DialogTitle>
                    <Typography variant="subtitle1">{missingBWPageMessage[0]}</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText variant="subtitle1">Error message: {missingBWPageMessage[1]}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setMissingBWPageMessage([])} color="primary">
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default OrderRequest;
