import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';

import HomePanel from './HomePanel';


const useStyles = makeStyles((theme) => ({
    image: {
        backgroundImage: 'url(/images/bg.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.grey[50],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }
}));

export default function Home() {

    const classes = useStyles();

    return (
        <div>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                className={classes.image}
                style={{ minHeight: '100vh' }}
            >
                <HomePanel />
            </Grid>
        </div>
    );
}
