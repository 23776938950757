const Settings = {

}

Settings.list = {
    signonBackgrounds: [
        {
            image: "/images/login-bg1.jpg",
            alt: "",
        },
        {
            image: "/images/login-bg2.jpg",
            alt: "",
        },
        {
            image: "/images/login-bg3.jpg",
            alt: "",
        }
    ],
}

export default Settings;