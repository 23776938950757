import Settings from '../../constants/settings';

// Get a random signon background form the project settings file
export function RandomSignonBackground() {
    return getRandomBackground(Settings.list.signonBackgrounds);
}

// Randomly pick an image out of an array of images
function getRandomBackground(inList) {
    const index = Math.floor( Math.random() * inList.length);
    return inList[index].image;
}
