import React, { useState, useEffect } from "react";
import {
    Avatar,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    TableFooter,
    Typography,
    TextField,
    //SelectField ,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Dialog,
    AppBar,
    Toolbar,
    Slide,
    IconButton,
} from "@material-ui/core";
//import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from "@material-ui/icons/Add";

import ListIcon from "@material-ui/icons/School";
import CloseIcon from "@material-ui/icons/Close";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";

import settings from "../../../settings";
import Title from "../../Helpers/Titles";

import Progress from "../../Helpers/Progress";

import SchoolForm from "../Schools/SchoolForm";
import SchoolCreateForm from "../Schools/CreateSchoolForm";

const useStyles = makeStyles((theme) => ({
    root: {},
    content: {
        padding: 0,
    },
    inner: {
        minWidth: 700,
    },
    nameCell: {
        display: "flex",
        alignItems: "center",
    },
    avatar: {
        height: 42,
        width: 42,
        backgroundColor: blue[500],
        marginRight: theme.spacing(1),
    },
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

export default function Schools() {
    const classes = useStyles();
    const [schools, setSchools] = useState([]);
    const [boards, setBoards] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [salesReps, setSalesReps] = useState([]);

    const [showProgress, setshowProgress] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setrowsPerPage] = useState(10);
    //const [TotalCount, setTotalCount] = useState(0);
    const [filterschool, setFilterschool] = useState("");
    const [filterboard, setFilterboard] = useState("");
    const [filtercity, setFiltercity] = useState("");
    const [filtersalesrep, setFiltersalesrep] = useState("");

    const [filterprovince, setFilterprovince] = useState("");

    const [dialogTitle, setDialogTitle] = useState("");
    const [open, setOpen] = React.useState(false);
    const [openNew, setOpenNew] = React.useState(false);

    const [firstLoad, setfirstLoad] = React.useState(true);

    const [pagination, setPagination] = useState({});
    const token = localStorage.getItem("pes-crm-token");
    let mounted = true;
    /*let localfilterschool ='';
    let localfilterboard = '';
    let localfiltercity = '';
    let localfilterprovince = '';*/
    const txt_School_Changed = (event, value) => {
        setfirstLoad(false);
        localStorage.setItem("filterschool", event.target.value);
        setFilterschool(event.target.value);
        setPage(0);
        //setrowsPerPage(10);
        fetchSchools(0, rowsPerPage, event.target.value, filterboard, filtercity, filterprovince, filtersalesrep);
    };
    const Board_Change = (event, value) => {
        if (event.target.value !== undefined && event.target.value !== "" && event.target.value !== " ") {
            localStorage.setItem("filterboard", event.target.value);
            setFilterboard(event.target.value);
            setPage(0);
            //setrowsPerPage(10);
            fetchSchools(0, rowsPerPage, filterschool, event.target.value, filtercity, filterprovince, filtersalesrep);
        } else if (event.target.value === "" || event.target.value === " ") {
            localStorage.setItem("filterboard", "");
            setFilterboard("");
            setPage(0);
            //setrowsPerPage(10);
            fetchSchools(0, rowsPerPage, filterschool, event.target.value, filtercity, filterprovince, filtersalesrep);
        } else {
            setFilterboard(event.target.value);
        }
    };
    const txt_City_Changed = (event, value) => {
        localStorage.setItem("filtercity", event.target.value);
        setFiltercity(event.target.value);
        setPage(0);
        //setrowsPerPage(10);
        fetchSchools(0, rowsPerPage, filterschool, filterboard, event.target.value, filterprovince, filtersalesrep);
    };

    const sales_Rep_Changed = (event, value) => {
        if (event.target.value !== undefined && event.target.value !== "" && event.target.value !== " ") {
            localStorage.setItem("filtersalesrep", event.target.value);
            setFiltersalesrep(event.target.value);
            setPage(0);
            //setrowsPerPage(10);
            fetchSchools(0, rowsPerPage, filterschool, filterboard, filtercity, filterprovince, event.target.value);
        } else if (event.target.value === "" || event.target.value === " ") {
            localStorage.setItem("filtersalesrep", "");
            setFiltersalesrep("");
            setPage(0);
            //setrowsPerPage(10);
            fetchSchools(0, rowsPerPage, filterschool, filterboard, filtercity, filterprovince, "");
        }
    };

    const Provinces_Changed = (event, value) => {
        if (event.target.value !== undefined && event.target.value !== "" && event.target.value !== " ") {
            localStorage.setItem("filterprovince", event.target.value);
            setFilterprovince(event.target.value);
            setPage(0);
            //setrowsPerPage(10);
            fetchSchools(0, rowsPerPage, filterschool, filterboard, filtercity, event.target.value, filtersalesrep);
        } else if (event.target.value === "" || event.target.value === " ") {
            localStorage.setItem("filterprovince", "");
            setFilterprovince("");
            setPage(0);
            //setrowsPerPage(10);
            fetchSchools(0, rowsPerPage, filterschool, filterboard, filtercity, "", filtersalesrep);
        }
    };
    //
    const handleChangePage = (event, page) => {
        setPage(page);
        setshowProgress(true);
        fetchSchools(page, rowsPerPage, filterschool, filterboard, filtercity, filterprovince, filtersalesrep);
    };

    const handleChangePageSize = (event) => {
        setrowsPerPage(event.target.value);
        setshowProgress(true);
        fetchSchools(page, event.target.value, filterschool, filterboard, filtercity, filterprovince, filtersalesrep);
    };
    const fetchSchools = (
        pageL,
        rowsPerPageL,
        filterschoolL,
        filterboardL,
        filtercityL,
        filterprovinceL,
        filtersalesrepL
    ) => {
        pageL = pageL + 1;
        let filterQuerystring =
            (filterschoolL !== null && filterschoolL !== undefined && filterschoolL !== ""
                ? "&School=" + filterschoolL
                : "") +
            (filterboardL !== null && filterboardL !== undefined && filterboardL !== ""
                ? "&Board=" + filterboardL
                : "") +
            (filtercityL !== null && filtercityL !== undefined && filtercityL !== "" ? "&City=" + filtercityL : "") +
            (filtersalesrepL !== null && filtersalesrepL !== undefined && filtersalesrepL !== ""
                ? "&SalesRepId=" + filtersalesrepL
                : "") +
            (filterprovinceL !== null && filterprovinceL !== undefined && filterprovinceL !== ""
                ? "&Province=" + filterprovinceL
                : "");

        setshowProgress(true);
        fetch(settings.api().schools + "?PageNumber=" + pageL + "&PageSize=" + rowsPerPageL + filterQuerystring, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                for (var pair of response.headers.entries()) {
                    if (pair[0] === settings.api().pagination) {
                        setPagination(JSON.parse(pair[1]));
                    }
                }
                return response.json();
            })
            .then((data) => {
                if (mounted) {
                    setSchools(data);
                    setshowProgress(false);
                }
            });
    };

    const clearFilter = () => {
        localStorage.setItem("filtercity", "");
        localStorage.setItem("filterprovince", "");
        localStorage.setItem("filterboard", "");
        localStorage.setItem("filterschool", "");
        localStorage.setItem("filtersalesrep", "");
        setFilterschool("");
        setFiltercity("");
        setFilterprovince("");
        setFilterboard("");
        setFiltersalesrep("");
        setPage(0);
        setrowsPerPage(10);

        fetchSchools(0, 10, "", "", "", "");
    };

    const fetchBoards = () => {
        fetch(settings.api().meta.boards, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                let arrBoard = [];
                let keys = Object.keys(data);
                for (let i = 0; i < keys.length; i++) {
                    if (data[i]) {
                        arrBoard.push({ id: keys[i], value: data[i] });
                    }
                }
                arrBoard = arrBoard.sort((a, b) => a.value.localeCompare(b.value));
                setBoards(arrBoard);
                setFilterboard(localStorage.getItem("filterboard"));
            });

        fetch(settings.api().meta.provinces, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                //let i=1;
                let arrProvinces = [];
                let keys = Object.keys(data);
                for (let i = 0; i < keys.length; i++) {
                    arrProvinces.push({ id: keys[i], value: keys[i] });
                }
                setProvinces(arrProvinces);
                setFilterprovince(localStorage.getItem("filterprovince"));
            });
        fetchSalesRep();
        //
    };
    const fetchSalesRep = () => {
        fetch(settings.api().meta.salesrep, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                let i = 1;
                let arrSales = [];
                while (data[i] !== undefined) {
                    arrSales.push({ id: i, value: data[i] });
                    i++;
                }
                setSalesReps(arrSales);
                setFiltersalesrep(localStorage.getItem("filtersalesrep"));
            });
    };

    useEffect(() => {
        setPage(0);
        setrowsPerPage(10);
        setshowProgress(true);
        /*
        let localfilterschool = localStorage.getItem('filterschool');        
        let localfilterboard = localStorage.getItem('filterboard');        
        let localfiltercity = localStorage.getItem('filtercity');        
        let localfilterprovince = localStorage.getItem('filterprovince');        
        setFilterschool2(localfilterschool);
        setFiltercity();
*/
        setFilterschool(localStorage.getItem("filterschool"));
        setFiltercity(localStorage.getItem("filtercity"));
        setFiltersalesrep(localStorage.getItem("filtersalesrep"));
        fetchSchools(
            page,
            rowsPerPage,
            localStorage.getItem("filterschool"),
            localStorage.getItem("filterboard"),
            localStorage.getItem("filtercity"),
            localStorage.getItem("filterprovince"),
            localStorage.getItem("filtersalesrep")
        );
        fetchBoards();
        return () => {
            mounted = false;
        };
    }, []);
    const handleClickOpenSchool = () => {
        localStorage.removeItem("school");
        setDialogTitle("New School");
        setOpen(true);
    };
    const handleClickOpenNewSchool = () => {
        setDialogTitle("New School");
        setOpenNew(true);
    };
    const handleClickCloseNewSchool = () => {
        setOpenNew(false);
    };
    const handleCreateSchool = (data) => {
        setshowProgress(true);
        fetch(settings.api().schools, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((data) => {
                if (mounted) {
                    setshowProgress(false);
                }
            })
            .catch((err) => {
                alert("data can not be saved");
            });
        handleClickCloseNewSchool();
    };

    const handleClickOpenSchoolEdit = (schoolIdL) => {
        //setDialogTitle('Edit School');
        //setOpen(true);
        setshowProgress(true);
        fetch(settings.api().schools + "/" + schoolIdL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (mounted) {
                    setDialogTitle("Edit School");
                    localStorage.setItem("school", JSON.stringify(data));
                    setOpen(true);
                    setshowProgress(false);
                }
            });
        //localStorage.setItem('schoolId',schoolidL);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleSchoolSave = () => {
        setOpen(false);
        fetchSchools(0, rowsPerPage, filterschool, filterboard, filtercity, filterprovince, filtersalesrep);
    };
    const txt_keyup = (event, mode) => {
        if (event !== null && event.key === "Enter") {
            if (mode === 1) {
                txt_School_Changed(event);
            } else if (mode === 2) {
                txt_City_Changed(event);
            }
            //event.target.value
        }
    };

    return (
        <div>
            <Title
                title="Schools List"
                subtitle="Schools"
                desc="This page lists all Schools available in PES. You can use filters to search for a particular school, add or edit schools, or view individual school details."
            />

            <Card>
                <CardHeader title="Search"></CardHeader>
                <Divider />
                <CardContent className={classes.content}>
                    <div></div>
                    <table>
                        <tbody>
                            <TableRow colSpan={2}>
                                <TableCell width={200}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        aria-label="Add School"
                                        onClick={handleClickOpenNewSchool}
                                    >
                                        <AddIcon /> Add School{/*onClick={handleClickOpen}*/}
                                    </Button>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell width={200}>
                                    <TextField
                                        width={200}
                                        label="School"
                                        id="txt_School"
                                        onChange={txt_School_Changed}
                                        value={filterschool}
                                        onKeyUp={(e) => txt_keyup(e, 1)}
                                    />
                                </TableCell>
                                <TableCell width={200}>
                                    <FormControl width={200} fullWidth className={classes.formControl}>
                                        <InputLabel>Board</InputLabel>
                                        <Select onChange={Board_Change} displayEmpty value={filterboard}>
                                            <MenuItem value="" primaryText=""></MenuItem>
                                            <MenuItem value=" " primaryText="">
                                                --Select--
                                            </MenuItem>
                                            {boards.map((option) => (
                                                <MenuItem
                                                    key={option.id}
                                                    value={option.value}
                                                    primaryText={option.value}
                                                >
                                                    {option.value}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell width={200}>
                                    <TextField
                                        width={200}
                                        label="City"
                                        id="txt_City"
                                        onChange={txt_City_Changed}
                                        value={filtercity}
                                        onKeyUp={(e) => txt_keyup(e, 2)}
                                    />
                                </TableCell>

                                <TableCell width={200}>
                                    <FormControl width={200} fullWidth className={classes.formControl}>
                                        <InputLabel>Provinces</InputLabel>
                                        <Select onChange={Provinces_Changed} displayEmpty value={filterprovince}>
                                            <MenuItem value="" primaryText=""></MenuItem>
                                            <MenuItem value=" " primaryText="">
                                                --Select--
                                            </MenuItem>
                                            {provinces.map((option) => (
                                                <MenuItem
                                                    key={option.id}
                                                    value={option.value}
                                                    primaryText={option.value}
                                                >
                                                    {option.value}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell width={200}>
                                    <FormControl width={200} fullWidth className={classes.formControl}>
                                        <InputLabel>Sales Rep</InputLabel>
                                        <Select onChange={sales_Rep_Changed} displayEmpty value={filtersalesrep}>
                                            <MenuItem value="" primaryText=""></MenuItem>
                                            <MenuItem value=" " primaryText="">
                                                --Select--
                                            </MenuItem>
                                            {salesReps.map((option) => (
                                                <MenuItem key={option.id} value={option.id} primaryText={option.value}>
                                                    {option.value}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell width={100}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={(e) => {
                                            clearFilter();
                                        }}
                                    >
                                        Clear
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </tbody>
                    </table>
                </CardContent>
                <Divider />
            </Card>
            <Card>
                <CardHeader title="All Schools"></CardHeader>
                <Divider />

                <CardContent className={classes.content}>
                    <div className={classes.inner}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>School</TableCell>
                                    <TableCell>Board</TableCell>
                                    <TableCell>City</TableCell>
                                    <TableCell>Province</TableCell>
                                    <TableCell>Sales Rep</TableCell>
                                    <TableCell align="center">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {showProgress === true ? (
                                    <TableRow>
                                        <TableCell colSpan={5} align="center">
                                            <Progress />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    schools.map((school) => (
                                        <TableRow hover key={school.id}>
                                            <TableCell component={RouterLink} to={`/crm/schools/${school.id}`}>
                                                <div className={classes.nameCell}>
                                                    <Avatar className={classes.avatar}>
                                                        <ListIcon />
                                                    </Avatar>
                                                    <Typography variant="h6">{school.schoolName}</Typography>
                                                </div>
                                            </TableCell>
                                            <TableCell>{school.boardName}</TableCell>
                                            <TableCell>{school.city}</TableCell>
                                            <TableCell>{school.province}</TableCell>
                                            <TableCell>{school.salesRepName}</TableCell>
                                            <TableCell align="center">
                                                <Button
                                                    color="primary"
                                                    component={RouterLink}
                                                    size="small"
                                                    to={`/crm/schools/${school.id}`}
                                                    variant="outlined"
                                                    style={{ marginTop: "5px", marginLeft: "5px" }}
                                                >
                                                    View
                                                </Button>

                                                <Button
                                                    color="primary"
                                                    onClick={() => handleClickOpenSchoolEdit(school.id)}
                                                    size="small"
                                                    variant="outlined"
                                                    value={school.id}
                                                    style={{ marginTop: "5px", marginLeft: "5px" }}
                                                >
                                                    Edit
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        colSpan={6}
                                        count={pagination.totalCount}
                                        rowsPerPage={rowsPerPage}
                                        rowsPerPageOptions={[5, 10, 25, 50]}
                                        page={page}
                                        backIconButtonProps={{
                                            "aria-label": "Previous Page",
                                        }}
                                        nextIconButtonProps={{
                                            "aria-label": "Next Page",
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangePageSize}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </div>
                </CardContent>
            </Card>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography componet="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                            {dialogTitle}
                        </Typography>
                    </Toolbar>
                </AppBar>

                <SchoolForm saved={handleSchoolSave} />
            </Dialog>

            <Dialog fullScreen open={openNew} onClose={handleClickCloseNewSchool} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClickCloseNewSchool} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography componet="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                            {dialogTitle}
                        </Typography>
                    </Toolbar>
                </AppBar>

                <SchoolCreateForm save={handleCreateSchool} />
            </Dialog>
        </div>
    );
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
