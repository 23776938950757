import React, { useState, useEffect } from 'react';
import {
    Avatar,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';
import ListIcon from '@material-ui/icons/Person';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';

import settings from '../../../settings';
import Title from '../../Helpers/Titles';
import Progress from '../../Helpers/Progress';
const useStyles = makeStyles((theme) => ({
    root: {},
    content: {
        padding: 0
    },
    inner: {
        minWidth: 700   },
    nameCell: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        height: 42,
        width: 42,
        backgroundColor: blue[500],
        marginRight: theme.spacing(1)
    }
}));

export default function SalesReps() {

    const classes = useStyles();
    const [reps, setReps] = useState([]);
    const [showProgress, setshowProgress] = useState([]);
    useEffect(() => {
        let mounted = true;

        const token = localStorage.getItem('pes-crm-token')
        setshowProgress(true);
        const fetchReps = () => {
            fetch(settings.api().reps, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(response => response.json())
            .then((data) => {
                if (mounted) {
                    setReps(data);
                    setshowProgress(false);
                }
            })
        }

        fetchReps();

        return () => {
            mounted = false;
        };

    }, []);

    return (
        <div>
            <Title title="Sales Representative" subtitle="Sales" desc="This page lists all sales representative available in PES. Currently we are listing the top 11 sales representative. Later this page would include filters, pagination and any other features required at the level." />
            <Card>
                <CardHeader title="All Sales Representative"></CardHeader>
                <Divider />
                <CardContent className={classes.content}>
                    <div className={classes.inner}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sales Rep</TableCell>
                                    <TableCell>CRM Enabled</TableCell>
                                    <TableCell>Active</TableCell>
                                    <TableCell align="right">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {showProgress === true ? 
                 <TableRow>
                 <TableCell colSpan={4} align="center" ><Progress /></TableCell>
                 </TableRow>
                :reps.map((rep) => (
                                    <TableRow hover key={rep.id}>
                                        <TableCell>
                                            <div className={classes.nameCell}>
                                                <Avatar className={classes.avatar}>
                                                    <ListIcon />
                                                </Avatar>
                                                <Typography variant="h6">
                                                    {rep.first + " " + rep.last}
                                                </Typography>                                                
                                            </div>
                                        </TableCell>
                                        <TableCell>{rep.crmEnabled.toString().toUpperCase()}</TableCell>
                                        <TableCell>{rep.active.toString().toUpperCase()}</TableCell>
                                        <TableCell align="right">
                                            <Button color="primary"
                                                component={RouterLink}
                                                size="small"
                                                to={`/crm/reps/${rep.id}`}
                                                variant="outlined"
                                                >
                                                View
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}