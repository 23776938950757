import React, { Component } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import HttpsRedirect from 'react-https-redirect';
import "./App.css";
import theme from './theme';
import AppRouter from './routes/AppRouter';


class App extends Component {
  render() {
    return (
      <HttpsRedirect>
        <ThemeProvider theme={theme} >
          <CssBaseline />
          <AppRouter />
        </ThemeProvider>
      </HttpsRedirect>
    );
  }
}

export default App;