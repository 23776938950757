// Higher-order component that calles the Express API route checkToken to see if the user is authenticated.
// If so, the child compnent is allowed to be accessed, if not it does not allow child to be returned.
// This is used to authenticate secure pages in the system.

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import settings from './settings';

export default function withAuth(ComponentToProtect) {
    
    return class extends Component {

        constructor() {
            super();
            this.state = {
                loading: true,
                redirect: false,
            };
        }

        componentDidMount() {
            const token = localStorage.getItem('pes-crm-token');
            fetch(settings.api().checkToken, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(res => {
                if (res.ok) {
                    this.setState({ loading: false });
                }
                else {
                    const error = new Error(res.error);
                    throw error;
                }
            })
            .catch(err => {
                this.setState({ loading: false, redirect: true });
            });
        }

        render() {
            const { loading, redirect } = this.state;
            if (loading) {
                return null;
            }
            if (redirect) {
                return <Redirect to="/login" />;
            }
            return (
                <React.Fragment>
                    <ComponentToProtect {...this.props} />
                </React.Fragment>
            )
        }
    }

}

