import React, { useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import "./Map.css";
import { makeStyles, Typography } from "@material-ui/core";
import settings from "../../../settings";
// import { MapLabel } from "js-map-label";
// import MarkerClusterer from "@google/markerclusterer";
import MarkerClusterer from "@googlemaps/markerclustererplus";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    legend: {
        background: "#fff",
        padding: theme.spacing(1),
        margin: theme.spacing(2, 0),
    },
    legendTitle: {
        margin: theme.spacing(0),
    },
    icon: {
        marginRight: "8px",
    },
    listItem: {
        padding: 0,
    },
    radio: {
        padding: theme.spacing(0, 2),
    },
    nested: {
        backgroundColor: "#eeeeee",
        paddingLeft: theme.spacing(4),
    },
}));

export default function SimpleMap(props) {
    const classes = useStyles();
    const mapRef = useRef();
    const mapsRef = useRef();

    const [idle, setIdle] = useState(0);
    const [finishLoading, setFinishLoading] = useState(false);
    const [mapDefaults, setMapDefaults] = useState({
        zoom: 12,
        center: { lat: 51.01438827567616, lng: -114.03277644337317 }, // first class location
    });
    const [schools, setSchools] = useState([]);
    const [polygons, setPolygons] = useState([]);
    const [markers, setMarkers] = useState(null);
    // const history = useHistor
    const theme = useTheme();

    var styles = [
        {
            width: 30,
            height: 30,
            className: "custom-clustericon-1",
        },
    ];

    useEffect(() => {
        // dynamically import script
        const script = document.createElement("script");
        script.src =
            "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js";
        script.async = true;
        document.body.appendChild(script);

        // set center
        if (props.history.location.search) {
            const params = props.history.location.search.substring(1);
            const center = params.split("&");
            const lat = parseFloat(center[0].split("=")[1]);
            const lng = parseFloat(center[1].split("=")[1]);

            setMapDefaults({
                zoom: 16,
                center: {
                    lat,
                    lng,
                },
            });
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleMapChange(bounds) {
        setFinishLoading(false);

        if (idle) {
            clearTimeout(idle);
        }
        setIdle(
            setTimeout(() => {
                const token = localStorage.getItem("pes-crm-token");
                const boundsString = `?neLat=${bounds.ne.lat}&neLng=${bounds.ne.lng}&swLat=${bounds.sw.lat}&swLng=${bounds.sw.lng}`;
                const url = settings.api().mapping_schools + boundsString;
                if (markers) {
                    markers.clearMarkers();
                    setMarkers(null);
                }
                if (polygons && polygons.length > 0) {
                    // const tmptmp = setPolygons((polys) => {
                    //     polys.forEach((p) => {
                    //         p.label.setMap(null);
                    //         p.polygon.setMap(null);
                    //     });
                    // });
                    // const tmptmp =
                    polygons.forEach((p) => {
                        p.label.setMap(null);
                        p.polygon.setMap(null);
                    });
                    setPolygons([]);
                }
                fetch(url, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                    .then((res) => {
                        if (res.ok) {
                            return res.json();
                        } else {
                            const error = new Error(res.error);
                            throw error;
                        }
                    })
                    .then((data) => {
                        setSchools([...data.schools]);
                        const finish1 = createMarkers(data.schools);
                        const finish2 = createZones(data.zones);

                        if (finish1 && finish2) {
                            setFinishLoading(true);
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }, 2300)
        );
    }

    function createMarkers(list) {
        const markers = list.map((s, i) => {
            const marker = new mapsRef.current.Marker({
                position: { lat: s.lat, lng: s.lng },
                icon: createMarkerIcon(s.pinColourHex),
            });
            const content = `<p>ID: ${s.schoolId}</p><p>${s.schoolName}</p><p>${s.schoolAddress}</p>`;
            const infowindow = new mapsRef.current.InfoWindow({
                content: content,
            });
            mapsRef.current.event.addListener(
                marker,
                "click",
                (function (marker, content, infowindow) {
                    return function () {
                        infowindow.setContent(content);
                        infowindow.open(mapRef.current, marker);
                    };
                })(marker, content, infowindow)
            );
            return marker;
        });
        try {
            const markerClusterer = new MarkerClusterer(mapRef.current, markers, {
                styles: styles,
                clusterClass: "custom-clustericon",
            });
            setMarkers(markerClusterer);
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    // const handleFilterChange = (value) => {
    //     clearAllMarkers();
    //     createMarkersBasedOnFilters(value);
    //     setFilters({
    //         ...filters,
    //         type: value,
    //     });
    // };

    // function createMarkersBasedOnFilters(type) {
    //     locations &&
    //         locations.forEach((l) => {
    //             handleAddMarkers(type, l);
    //             // setMarkers(handleAddMarkers(mapRef.current, mapsRef.current, l));
    //         });
    //     if (type === "salesrep") {
    //         if (polygons.length !== areas.length) {
    //             let tmp = [];
    //             areas &&
    //                 areas.forEach((a) => {
    //                     tmp = [...tmp, addZone(a)];
    //                 });
    //             setPolygons(tmp);
    //         }
    //     } else {
    //         polygons.length > 0 &&
    //             polygons.forEach((p) => {
    //                 p.label && p.label.setMap(null);
    //                 p.polygon && p.polygon.setMap(null);
    //             });
    //         setPolygons([]);
    //     }
    // }

    // function clearAllMarkers() {
    //     markers.forEach((m) => {
    //         m.setMap(null);
    //     });
    // }
    // function handleFilterType(e) {
    //     // const { value } = e.target;
    // }
    // const handleZone = (e) => {
    //     const { name, checked } = e.target;
    //     setFilters({
    //         ...filters,
    //         [name]: checked,
    //     });

    //     if (checked) {
    //         const newZone = areas.find((a) => a.name === name);
    //         addZone(newZone);
    //     } else {
    //         removeZone(name);
    //     }
    // };

    // const addZone = (zone) => {
    //     const tmp = zone.coords.map((c) => {
    //         return { lat: parseFloat(c.latitude), lng: parseFloat(c.longitude) };
    //     });

    //     const drawnZone = new mapsRef.current.Polygon({
    //         paths: tmp,
    //         strokeColor: zone.foregroundColour,
    //         strokeOpacity: 0.8,
    //         strokeWeight: 2,
    //         fillColor: zone.foregroundColour,
    //         fillOpacity: 0.35,
    //         map: mapRef.current,
    //     });

    //     let content = `<p>${zone.title}</p>`;
    //     const infowindow = new mapsRef.current.InfoWindow({
    //         content: content,
    //     });

    //     var zoneBounds = new mapsRef.current.LatLngBounds();
    //     for (let i = 0; i < tmp.length; i++) {
    //         zoneBounds.extend(tmp[i]);
    //     }

    //     // creates center label
    //     const centerLabel = new mapsRef.current.Marker({
    //         position: zoneBounds.getCenter(),
    //         label: { text: `${zone.code}: ${zone.title}`, color: zone.foregroundColour },
    //         map: mapRef.current,
    //         icon: {
    //             path: mapsRef.current.SymbolPath.CIRCLE,
    //             scale: 0,
    //             labelOrigin: new mapsRef.current.Point(12, 9),
    //         },
    //     });

    //     mapsRef.current.event.addListener(drawnZone, "click", function (event) {
    //         // var contentString = this.name;
    //         var contentString = zone.title;
    //         infowindow.setContent(contentString);
    //         infowindow.setPosition(event.latLng);
    //         infowindow.open(mapRef.current);
    //     });

    //     const isExist = polygons.find((p) => p.identifyer === zone.title);
    //     return (
    //         !isExist && {
    //             identifyer: zone.title,
    //             label: centerLabel,
    //             polygon: drawnZone,
    //         }
    //     );
    // };

    const createZones = (zones) => {
        const areas = zones.map((zone) => {
            // console.log(zone.coords);
            // const tmp = zone.coords.map((c) => {
            //     return { lat: parseFloat(c.lat), lng: parseFloat(c.lng) };
            // });
            // console.log("tmp: ", tmp);

            var zoneBounds = new mapsRef.current.LatLngBounds();
            for (let i = 0; i < zone.coords.length; i++) {
                zoneBounds.extend(zone.coords[i]);
            }
            // creates center label
            const centerLabel = new mapsRef.current.Marker({
                position: zoneBounds.getCenter(),
                label: {
                    text: `${zone.code}: ${zone.title}`,
                    fontWeight: "500",
                    color: `#212121`,
                },
                icon: {
                    path: mapsRef.current.SymbolPath.CIRCLE,
                    scale: 0,
                    labelOrigin: new mapsRef.current.Point(12, 9),
                },
            });
            const drawnZone = new mapsRef.current.Polygon({
                paths: zone.coords,
                strokeColor: `#212121`,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: `#${zone.backgroundColour}`,
                fillOpacity: 0.35,
                marker: centerLabel,
            });
            //  const infowindow = new mapsRef.current.InfoWindow({
            //                 content: `<p>${zone.title}</p>`,
            //             });
            // mapsRef.current.event.addListener(drawnZone, "click", function (event) {
            //     // var contentString = this.name;
            //     var contentString = zone.title;
            //     infowindow.setContent(contentString);
            //     infowindow.setPosition(event.latLng);
            //     infowindow.open(mapRef.current);
            // });

            drawnZone.setMap(mapRef.current);
            centerLabel.setMap(mapRef.current);

            return {
                label: centerLabel,
                polygon: drawnZone,
            };
        });
        setPolygons(areas);
        return areas.length === zones.length;
    };

    function createMarkerIcon(color) {
        // customize icon
        var pinSVGHole =
            "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z";
        // var pinSVGFilled =
        //     "M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z";
        return {
            path: pinSVGHole,
            anchor: new mapsRef.current.Point(12, 17),
            fillOpacity: 1,
            fillColor: `#${color}`,
            strokeWeight: 1,
            strokeColor: "#424242",
            scale: 1.6,
            labelOrigin: new mapsRef.current.Point(12, 9),
        };
    }

    return (
        <div>
            <Typography component="h2" variant="overline" gutterBottom>
                Map View
            </Typography>
            <Typography style={{ marginBottom: theme.spacing(1) }} component="h1" variant="h3">
                Zones
            </Typography>
            <div>
                <Typography className={classes.title} variant="body1">
                    Zones in View: {polygons.length}
                </Typography>
            </div>
            <div>
                <Typography className={classes.title} variant="body1">
                    Schools in View: {schools.length}
                </Typography>
            </div>

            {finishLoading ? (
                <Typography style={{ fontSize: "18px" }} align="center">
                    Done!
                </Typography>
            ) : (
                <Typography style={{ fontSize: "18px" }} align="center">
                    Loading . . .
                </Typography>
            )}

            <div style={{ height: "70vh", width: "100%" }}>
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
                    }}
                    defaultZoom={mapDefaults.zoom}
                    defaultCenter={mapDefaults.center}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => {
                        mapRef.current = map;
                        mapsRef.current = maps;
                    }}
                    onChange={({ bounds }) => handleMapChange(bounds)}
                ></GoogleMapReact>
            </div>
        </div>
    );
}

// HELPER FUNCTIONS
// const handleAddMarkers = (type, zone) => {
//     let markersList = [];
//     let marker;
//     let content;
//     zone.coords.map((point, i) => {
//         let l = LABELS[i++ % LABELS.length];
//         if (type === "priority") {
//             // creates marker
//             marker = new mapsRef.current.Marker({
//                 position: { lat: point.lat, lng: point.lng },
//                 label: { text: l, color: "white" },
//                 map: mapRef.current,
//                 icon: createMarkerIcon(
//                     // point.priority === "A" ? "#aa2e25" : point.priority === "B" ? "#2c387e" : "#00695f"
//                     "red"
//                 ),
//             });

//             content = `<p>School ${l}</p>` + `<p>Priority ${point.priority}</p>` + `<p>${point.address}<p>`; // adds info box to marker
//         } else if (type === "salesrep") {
//             // creates marker
//             marker = new mapsRef.current.Marker({
//                 position: point,
//                 label: LABELS[i++ % LABELS.length],
//                 map: mapRef.current,
//                 icon: createMarkerIcon("red"),
//                 // zone.fill
//             });
//             content = point.address;
//         } else if (type === "allschools") {
//             marker = new mapsRef.current.Marker({
//                 position: point,
//                 label: LABELS[i++ % LABELS.length],
//                 map: mapRef.current,
//             });
//             content = `<p>School ${l}</p>`;
//         }

//         const infowindow = new mapsRef.current.InfoWindow({
//             content: content,
//         });
//         // add listener for click events
//         // marker.addListener("click", () => {
//         //     infowindow.open(marker.get("map"), marker);
//         // });

//         mapsRef.current.event.addListener(
//             marker,
//             "click",
//             (function (marker, content, infowindow) {
//                 return function () {
//                     infowindow.setContent(content);
//                     infowindow.open(mapRef.current, marker);
//                 };
//             })(marker, content, infowindow)
//         );

//         markersList.push(marker);
//     });
//     // return markersList;
//     setMarkers([...markersList]);
// };

// for clusters
// const markers = zone1.map((point, i) => {
//     return new maps.Marker({
//         position: point,
//         label: i,
//     });
// });

// new MarkerClusterer(map, markers, {
//     imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
// });

// const findCenterPoint = (points) => {
//     if (points.length === null || points.length === 0) {
//         return null;
//     }

//     let lat = 0;
//     let lng = 0;
//     points.forEach((p) => {
//         lat = +p.lat;
//         lng += p.lng;
//     });
//     lat /= points.length;
//     lng /= points.length;
//     return { lat, lng };
// };

// ========================== using data layer ============================
// function handleMapChange(bounds) {
//     setFinishLoading(false);

//     if (idle) {
//         clearTimeout(idle);
//         setIdle(0);
//     }
//     setIdle(
//         setTimeout(() => {
//             console.log("hello");
//             const token = localStorage.getItem("pes-crm-token");
//             const boundsString = `?neLat=${bounds.ne.lat}&neLng=${bounds.ne.lng}&swLat=${bounds.sw.lat}&swLng=${bounds.sw.lng}`;
//             const url = settings.api().mapping_schools + boundsString;
//             if (markers) {
//                 markers.clearMarkers();
//                 setMarkers(null);
//             }
//             mapRef.current &&
//                 mapRef.current.data.forEach((f) => {
//                     mapRef.current.data.remove(f);
//                 });
//             fetch(url, {
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                 },
//             })
//                 .then((res) => {
//                     if (res.ok) {
//                         return res.json();
//                     } else {
//                         const error = new Error(res.error);
//                         throw error;
//                     }
//                 })
//                 .then((data) => {
//                     isLoaded.current && setSchools(data.schools);
//                     const finish1 = createMarkers(data.schools);
//                     const finish2 = createZones(data.zones);

//                     if (finish1 && finish2) {
//                         setFinishLoading(true);
//                     }
//                 })
//                 .catch((err) => {
//                     console.error(err);
//                 });
//         }, 2300)
//     );

//     setMapBounds(bounds);
// }

// const createZones = (zones) => {
//     const areas = zones.map((zone) => {
//         // console.log(zone);
//         return {
//             ...zone,
//             coords: zone.coords.map((c) => {
//                 return { lat: parseFloat(c.latitude), lng: parseFloat(c.longitude) };
//                 // return [parseFloat(c.latitude), parseFloat(c.longitude)];
//             }),
//         };
//     });
//     // console.log(areas);
//     const tmpList = areas.map((a) => a.coords);
//     // console.log(tmpList);
//     mapRef.current.data.add({
//         geometry: new mapsRef.current.Data.Polygon(tmpList),
//     });
// };
