import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
//import Button from '@material-ui/core/Button';
//import Dialog from '@material-ui/core/Dialog';
//import ListItemText from '@material-ui/core/ListItemText';
//import ListItem from '@material-ui/core/ListItem';
//import List from '@material-ui/core/List';
import Divider from "@material-ui/core/Divider";
//import AppBar from '@material-ui/core/AppBar';
//import Toolbar from '@material-ui/core/Toolbar';
//import IconButton from '@material-ui/core/IconButton';
//import Typography from '@material-ui/core/Typography';
//import CloseIcon from '@material-ui/icons/Close';
//import Slide from '@material-ui/core/Slide';
import {
    TextField,
    Grid,
    Card,
    CardContent,
    //    CardHeader,
    Checkbox,
    FormControl,
    FormHelperText,
    InputLabel,
    Select,
    MenuItem,
    Button,
} from "@material-ui/core";

import settings from "../../../settings";
//import Title from '../../Helpers/Titles';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

export default function SchoolCreateForm(props) {
    const classes = useStyles();
    const [boards, setBoards] = useState([]);
    const [BoardID, setBoardValue] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [ProvCode, setProvincesValue] = useState([]);
    const [school, setSchool] = useState({});
    const [schoolError, setSchoolError] = useState({});
    const token = localStorage.getItem("pes-crm-token");
    const fetchBoards = () => {
        fetch(settings.api().meta.boards, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                let arrBoard = [];
                let keys = Object.keys(data);
                for (let i = 0; i < keys.length; i++) {
                    if (data[i]) arrBoard.push({ id: keys[i], value: data[i] });
                }
                arrBoard = arrBoard.sort((a, b) => a.value.localeCompare(b.value));
                setBoards(arrBoard);
                setSchool({});
            });

        fetch(settings.api().meta.provinces, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                //let i=1;
                let arrProvinces = [];
                let keys = Object.keys(data);
                for (let i = 0; i < keys.length; i++) {
                    arrProvinces.push({ id: keys[i], value: keys[i] });
                }
                setProvinces(arrProvinces);
            });
        //
    };

    const province_change = (event, value) => {
        if (event.target.value !== undefined && event.target.value !== "" && event.target.value !== " ") {
            school.ProvCode = event.target.value;
            setSchool(school);
            delete schoolError.provCodeError;
            setSchoolError(schoolError);
        }
    };
    const board_change = (event, value) => {
        if (event.target.value !== undefined && event.target.value !== "" && event.target.value !== " ") {
            school.BoardID = event.target.value;
            setSchool(school);
            delete schoolError.boardError;
            setSchoolError(schoolError);
        }
    };
    const isactive_change = (event, value) => {
        school.Active = event.target.checked;
        setSchool(school);
    };
    const handleSubmit = () => {
        school.WarningFlag = true;
        let error = {};
        let isError = false;
        if (school.SchoolName === "" || school.SchoolName === undefined || school.SchoolName === null) {
            error.nameError = true;
            error.helperText = "Please Provide Name";
            isError = true;
        }
        if (school.BoardID === "" || school.BoardID === undefined || school.BoardID === null) {
            error.boardError = true;
            error.boardHelperText = "Please Provide Board";
            isError = true;
        }
        if (school.Phone === "" || school.Phone === undefined || school.Phone === null) {
            error.phoneError = true;
            error.phoneHelperText = "Please Provide Phone number";
            isError = true;
        }
        if (school.Postal === "" || school.Postal === undefined || school.Postal === null) {
            error.postalError = true;
            error.postalHelperText = "Please Provide Postal";
            isError = true;
        }
        if (school.ProvCode === "" || school.ProvCode === undefined || school.ProvCode === null) {
            error.provCodeError = true;
            error.provCodeHelperText = "Please Provide Provinces";
            isError = true;
        }
        if (school.Address1 === "" || school.Address1 === undefined || school.Address1 === null) {
            error.address1Error = true;
            error.address1HelperText = "Please Provide Provinces";
            isError = true;
        }
        setSchoolError(error);

        if (!isError) {
            props.save(school);
        }
    };
    const fieldChange = (event, value) => {
        if (event.target.value !== undefined && event.target.value !== "" && event.target.value !== " ") {
            school[event.target.id] = event.target.value;
            setSchool(school);
        }
    };

    useEffect(() => {
        fetchBoards();
    }, []);

    return (
        <div>
            <Grid container spacing={2} direction="column" alignItems="center" style={{ minHeight: "100vh" }}>
                <Grid item xs={10}>
                    <Card style={{ "padding-top": "20px" }}>
                        <CardContent className={classes.content}>
                            <br />
                            <Grid xs={12} container spacing={1}>
                                <Grid xs={5} container spacing={1}>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="SchoolName"
                                            onChange={fieldChange}
                                            fullWidth
                                            label="School Name"
                                            value={school.SchoolName}
                                            helperText={schoolError.helperText}
                                            error={schoolError.nameError}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl
                                            width={300}
                                            fullWidth
                                            className={classes.formControl}
                                            error={schoolError.boardError}
                                        >
                                            <InputLabel>Board</InputLabel>
                                            <Select
                                                id="BoardID"
                                                displayEmpty
                                                onChange={board_change}
                                                value={school.BoardID}
                                            >
                                                <MenuItem value="" primaryText=""></MenuItem>
                                                {boards.map((option) => (
                                                    <MenuItem value={option.id} primaryText={option.value}>
                                                        {option.value}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {schoolError.boardError ? (
                                                <FormHelperText>{schoolError.boardHelperText}</FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="Address1"
                                            fullWidth
                                            label="Address 1"
                                            onChange={fieldChange}
                                            value={school.Address1}
                                            helperText={schoolError.address1helperText}
                                            error={schoolError.address1Error}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            id="Address2"
                                            fullWidth
                                            label="Address 2"
                                            onChange={fieldChange}
                                            value={school.Address2}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="City"
                                            fullWidth
                                            label="City"
                                            onChange={fieldChange}
                                            value={school.City}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl
                                            width={300}
                                            fullWidth
                                            className={classes.formControl}
                                            error={schoolError.provCodeError}
                                        >
                                            <InputLabel>Provinces</InputLabel>
                                            <Select
                                                id="ProvCode"
                                                onChange={province_change}
                                                displayEmpty
                                                value={school.ProvCode}
                                            >
                                                <MenuItem value="" primaryText=""></MenuItem>
                                                {provinces.map((option) => (
                                                    <MenuItem value={option.value} primaryText={option.value}>
                                                        {option.value}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {schoolError.provCodeError ? (
                                                <FormHelperText>{schoolError.provCodeHelperText}</FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="Postal"
                                            onChange={fieldChange}
                                            fullWidth
                                            label="Postal"
                                            value={school.Postal}
                                            helperText={schoolError.postalHelperText}
                                            error={schoolError.postalError}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid xs={1} container spacing={1}></Grid>
                                <Grid xs={5} container spacing={1}>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="Phone"
                                            type="number"
                                            onChange={fieldChange}
                                            fullWidth
                                            label="Phone"
                                            value={school.Phone}
                                            helperText={schoolError.phoneHelperText}
                                            error={schoolError.phoneError}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="Fax"
                                            type="number"
                                            onChange={fieldChange}
                                            fullWidth
                                            label="Fax"
                                            value={school.Fax}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Checkbox
                                            checked={school.Active}
                                            label="Active"
                                            color="primary"
                                            id="Active"
                                            onChange={isactive_change}
                                            value={school.Active}
                                        />{" "}
                                        Active
                                    </Grid>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="SchoolNote"
                                            multiline
                                            fullWidth
                                            label="Notes"
                                            variant="outlined"
                                            rows="4"
                                            onChange={fieldChange}
                                            value={school.SchoolNote}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            aria-label="Save"
                                            onClick={handleSubmit}
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <div></div>
                </Grid>
            </Grid>
        </div>
    );
}
