import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import withAuth from '../withAuth';

import Home from '../components/Home/Home';
import Login from '../components/Login/Login';
import ErrorPage from '../components/Errors/404';
import Portal from '../components/Portal/Portal';

class AppRouter extends Component {
    render() {
      return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/crm" component={withAuth(Portal)} />
                <Route path="/login" component={Login} />
                <Route path="*" component={ErrorPage} />
            </Switch>
        </BrowserRouter>
      );
    }
  }

  export default AppRouter;