import React, { useState, useEffect } from "react";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import { withStyles, makeStyles } from "@material-ui/core/styles";

//import Chart from '../../Graphs/SampleChart';
import Progress from "../../Helpers/Progress";
import settings from "../../../settings";
import {
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Tabs,
    Tab,
    Box,
    AppBar,
    CardHeader,
} from "@material-ui/core";
import Chart from "react-apexcharts";
import BasicDateRangePicker from "../../Helpers/DateRangePicker";
import Moment from "moment";
import { Link as RouterLink } from "react-router-dom";

import PropTypes from "prop-types";
import ManagerData from "./ManagerData";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    fixedHeight: {
        height: 240,
    },
    descText: {
        marginBottom: 10,
    },
    content: {
        fontSize: 16,
        // margin: 16,

        // Use flex layout with column direction for components in the card
        // (CardContent and CardActions)
        display: "flex",
        flexDirection: "column",

        // Justify the content so that CardContent will always be at the top of the card,
        // and CardActions will be at the bottom
        justifyContent: "space-between",
    },
    cardHeader: {
        fontSize: 28,
        paddingLeft: 0,
        fontWeight: 700,
    },
    orderHeight: {
        maxHeight: 350,
        minHeight: 350,
    },
    orderContainerHeight: {
        minHeight: 530,
    },
    managers: {
        margin: theme.spacing(2, -1, 3),
        padding: 0,
        borderRadius: "5px",
    },
    manage: {
        background: "#f4f6f8",
    },
    tabs: {
        backgroundColor: "white",
    },
}));

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: "rgba(1, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 14,
    },
}))(Tooltip);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
export default function Dashboard() {
    const classes = useStyles();

    const [salesRapReport, setSalesRapReport] = useState({});
    // const [salesByProvince, setSalesByProvince] = useState([]);
    // const [salesBySalesRep, setSalesBySalesRep] = useState([]);
    const [salesOrder, setSalesOrder] = useState([]);
    const [salesRepActivity, setSalesRepActivity] = useState([]);
    const [barChart, setBarChart] = useState({});
    const [donutChart, setDonutChart] = useState({});
    const [userData, setUserData] = useState({});
    let mounted = true;
    const token = localStorage.getItem("pes-crm-token");
    const [showProgress, setshowProgress] = useState([]);
    const [selectedDate, setSelectedDate] = useState([null]);
    const [managerSelectedDate, setManagerSelectedDate] = useState([null]);
    const [isManager, setIsManager] = useState(false);
    const getSalesRapReports = async (startDate, endDate) => {
        let endpoint = settings.api().reports + "/salesRepOverview";
        endpoint = endpoint + "?startDate=" + startDate + "&endDate=" + endDate;
        fetch(endpoint, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (mounted) {
                    setSalesRapReport(data);
                    setshowProgress(false);
                }
            })
            .catch((err) => {
                console.log("something went wrong");
            });
    };
    const getSalesByProvince = async (startDate, endDate) => {
        let endpoint = settings.api().graph + "/salesByProvince";
        endpoint = endpoint + "?startDate=" + startDate + "&endDate=" + endDate;
        fetch(endpoint, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (mounted) {
                    // setSalesByProvince(data);
                    let totalSales = [];
                    let salesRep = [];
                    let category = [];
                    if (data && data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            totalSales.push(data[i].totalSales);
                            salesRep.push(data[i].salesRep);
                            category.push(data[i].province);
                        }
                    }

                    let barChart = {
                        series: [
                            {
                                name: "Total Sales",
                                data: totalSales,
                            },
                            {
                                name: "Your Sales",
                                data: salesRep,
                            },
                        ],
                        options: {
                            chart: {
                                type: "bar",
                                height: 360,
                            },
                            plotOptions: {
                                bar: {
                                    horizontal: false,
                                    columnWidth: "55%",
                                    endingShape: "flat",
                                },
                            },
                            dataLabels: {
                                enabled: false,
                            },
                            stroke: {
                                show: true,
                                width: 2,
                                colors: ["transparent"],
                            },
                            xaxis: {
                                categories: category,
                            },
                            yaxis: {
                                title: {
                                    text: "Total Sales ($)",
                                },
                            },
                            fill: {
                                opacity: 1,
                            },
                            tooltip: {
                                y: {
                                    formatter: function (val) {
                                        return "$ " + formatNumber(val);
                                    },
                                },
                            },
                        },
                    };
                    setBarChart(barChart);
                    setshowProgress(false);
                }
            })
            .catch((err) => {
                console.log("something went wrong");
            });
    };
    const getSalesBySalesRep = async (startDate, endDate) => {
        let endpoint = settings.api().graph + "/salesBySalesRep";
        endpoint = endpoint + "?startDate=" + startDate + "&endDate=" + endDate;
        fetch(endpoint, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (mounted) {
                    // console.log(data);
                    // setSalesBySalesRep(data);
                    let series = [];
                    let labels = [];
                    if (data && data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            series.push(data[i].salesPercentage);
                            labels.push(data[i].repName);
                        }
                    }
                    let donutChart = {
                        series: series,

                        options: {
                            labels: labels,
                            chart: {
                                type: "donut",
                            },
                            responsive: [
                                {
                                    breakpoint: 480,
                                    options: {
                                        chart: {
                                            width: 200,
                                        },
                                        legend: {
                                            position: "bottom",
                                        },
                                    },
                                },
                            ],
                        },
                    };
                    setDonutChart(donutChart);
                    setshowProgress(false);
                }
            })
            .catch((err) => {
                console.log("something went wrong");
            });
    };
    const getSalesOrder = async (startDate, endDate) => {
        let endpoint = settings.api().reports + "/mostRecentOrders";
        endpoint = endpoint + "?startDate=" + startDate + "&endDate=" + endDate;
        fetch(endpoint, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (mounted) {
                    setSalesOrder(data);
                    setshowProgress(false);
                }
            })
            .catch((err) => {
                console.log("something went wrong");
            });
    };
    const getSalesRepActivity = async (startDate, endDate) => {
        let endpoint = settings.api().reports + "/salesRepSignonActivity";
        endpoint = endpoint + "?startDate=" + startDate + "&endDate=" + endDate;
        fetch(endpoint, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (mounted) {
                    setSalesRepActivity(data);
                    setshowProgress(false);
                }
            })
            .catch((err) => {
                console.log("something went wrong");
            });
    };

    const handleChangeDate = (data) => {
        let startDate = Moment(data[0]._d).format("YYYY-MM-DD");
        let endDate = Moment(data[1]._d).format("YYYY-MM-DD");
        let dates = [Moment(startDate, "YYYY-MM-DD"), Moment(endDate, "YYYY-MM-DD")];
        setSelectedDate(dates);
        setshowProgress(true);
        getSalesRapReports(startDate, endDate);
        getSalesBySalesRep(startDate, endDate);
        getSalesByProvince(startDate, endDate);
        getSalesOrder(startDate, endDate);
        getSalesRepActivity(startDate, endDate);
    };

    const handleChangeManagerDate = (data) => {
        let startDate = Moment(data[0]._d).format("DD-MM-YYYY");
        let endDate = Moment(data[1]._d).format("DD-MM-YYYY");
        let dates = [Moment(startDate, "DD-MM-YYYY"), Moment(endDate, "DD-MM-YYYY")];
        setManagerSelectedDate(dates);
        //setshowProgress(true);
        /*getSalesRapReports(startDate, endDate);
    	getSalesBySalesRep(startDate, endDate);
    	getSalesByProvince(startDate, endDate);
    	getSalesOrder(startDate, endDate);
    	getSalesRepActivity(startDate, endDate);*/
    };

    const formatNumber = (num) => {
        if (num) return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        else return num;
    };
    const getDaysFormate = (date) => {
        if (date) {
            let s = date.split(".")[0];
            s = s.replace("T", " ");
            s = s + " UTC";
            let startDate = Moment(new Date(s));
            let endDate = Moment(new Date());

            if (endDate.isSame(startDate, "day")) {
                return endDate.diff(startDate, "hours") + " hours";
                //return Moment.duration(endDate.diff(startDate)).asHours() + " hours";
            } else {
                return endDate.diff(startDate, "days") + " days";
            }
        } else return date;
    };

    const getFormatedDate = (date) => {
        if (date) {
            let s = date.split(".")[0];
            s = s.replace("T", " ");
            s = s + " UTC";
            return Moment(new Date(s)).format("MM/DD/YYYY HH:mm:ss");
        } else return date;
    };

    useEffect(() => {
        setshowProgress(true);
        // let startDate = "01-11-" + (new Date().getFullYear() - 1);
        // let endDate = "31-10-" + new Date().getFullYear();

        // let dates = [
        //   //   Moment(startDate, "DD-MM-YYYY"),
        //   //   Moment(endDate, "DD-MM-YYYY"),
        // ];
        // setSelectedDate(dates);
        // setManagerSelectedDate(dates);
        // getSalesRapReports(startDate, endDate);
        // getSalesBySalesRep(startDate, endDate);
        // getSalesByProvince(startDate, endDate);
        // getSalesOrder(startDate, endDate);
        // getSalesRepActivity(startDate, endDate);

        let userData = localStorage.getItem("user-data");
        setIsManager(false);
        if (userData) {
            userData = JSON.parse(userData);
            setUserData(userData);
            if (userData.crmRoleId === 2) {
                setIsManager(true);
            }
        }
        fetch(settings.api().signon, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                if (mounted) {
                    let startDate = data.dashboardStartDate;
                    let endDate = data.dashboardEndDate;
                    let dates = [
                        Moment(new Date(data.dashboardStartDate), "DD-MM-YYYY"),
                        Moment(new Date(data.dashboardEndDate), "DD-MM-YYYY"),
                    ];
                    setIsManager(data.crmRoleId === 2);
                    setSelectedDate(dates);
                    setManagerSelectedDate(dates);
                    getSalesRapReports(startDate, endDate);
                    getSalesBySalesRep(startDate, endDate);
                    getSalesByProvince(startDate, endDate);
                    getSalesOrder(startDate, endDate);
                    getSalesRepActivity(startDate, endDate);
                }
            })
            .catch((err) => {
                console.log("something went wrong");
            });

        return () => {
            mounted = false;
        };
    }, []);

    const [currentTab, setCurrentTab] = useState("2021");
    const [expand, setExpand] = useState(false);
    const tabs = [{ value: "2021" }, { value: "2020" }, { value: "2019" }];

    const handleTabsChange = (event, newValue) => {
        setCurrentTab(newValue);
    };
    const handleExpand = () => {
        setExpand(!expand);
    };

    return (
        <div>
            {showProgress === false ? (
                <div>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <h1>Welcome to your Dashboard {userData.firstName}</h1>
                            <h4>Last Login: {getFormatedDate(userData.lastLogin)}</h4>
                        </Grid>
                    </Grid>
                    {isManager ? (
                        <Accordion className={clsx(classes.managers)} onClick={handleExpand}>
                            <AccordionSummary
                                // expandIcon={<ExpandMoreIcon />}
                                className={expand ? classes.manage : null}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                // style={{ padding: 0 }}
                            >
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={9} md={9} lg={9}>
                                        <h1 style={{ color: "#546e7a" }}>Manager's Data (Coming Soon)</h1>
                                        {/* <p>
                                            Welcome to the PES CRM Portal Dashboard. The information displayed here
                                            relates directly to your sales data and the date range selected.
                                        </p> */}
                                    </Grid>
                                    {/* <Grid item xs={3} md={3} lg={3}>
                                        <BasicDateRangePicker
                                            date={managerSelectedDate}
                                            callBack={handleChangeManagerDate}
                                        />
                                    </Grid> */}
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails style={{ backgroundColor: "#f4f6f8", padding: "0", margin: "1 0" }}>
                                <div style={{ width: "100%" }}>
                                    <AppBar position="static" color="default">
                                        <Tabs
                                            className={classes.tabs}
                                            value={currentTab}
                                            onChange={handleTabsChange}
                                            indicatorColor="primary"
                                        >
                                            {tabs.map((tab) => (
                                                <Tab key={tab.value} label={tab.value} value={tab.value} />
                                            ))}
                                        </Tabs>
                                    </AppBar>
                                    <div>
                                        {currentTab === "2021" && <ManagerData />}
                                        {currentTab === "2020" && <div>Coming Soon</div>}
                                        {currentTab === "2019" && <div>Coming Soon</div>}
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    ) : (
                        ""
                    )}

                    <Grid container justifyContent="space-between">
                        <Grid item xs={9}>
                            <h1>Your Personal Result</h1>
                            <p>
                                Welcome to the PES CRM Portal Dashboard. The information displayed here relates directly
                                to your sales data and the date range selected.
                            </p>
                        </Grid>
                        <Grid item xs={3} align="right">
                            <BasicDateRangePicker date={selectedDate} callBack={handleChangeDate} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <Card align="center">
                                <CardContent>
                                    <span>Total Sales</span>
                                    <h3 className={classes.cardHeader}>$ {formatNumber(salesRapReport.sumOfTotals)}</h3>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={3}>
                            <Card align="center">
                                <CardContent>
                                    <span>Total Schools</span>
                                    <h3 className={classes.cardHeader}>{salesRapReport.totalSchools}</h3>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={3}>
                            <Card align="center">
                                <CardContent>
                                    <span>Total Boards</span>
                                    <h3 className={classes.cardHeader}>{salesRapReport.totalBoards}</h3>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={3}>
                            <Card align="center">
                                <CardContent>
                                    <span>Planners</span>
                                    <h3 className={classes.cardHeader}>{salesRapReport.totalPlanners}</h3>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs>
                            <Card>
                                <CardHeader title={<h1 className={classes.cardHeader}>Sales Overview</h1>} />
                                <CardContent className={classes.content}>
                                    {/* <h1 className={classes.cardHeader}>Sales Overview</h1> */}
                                    <Grid container item xs={12} spacing={3}>
                                        <Grid container item xs={12}>
                                            <Grid item xs={5}>
                                                <LightTooltip title={salesRapReport.totalSchoolsDesc}>
                                                    <Typography>Total Schools</Typography>
                                                </LightTooltip>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <LightTooltip title={salesRapReport.totalSchoolsDesc}>
                                                    <Typography>{salesRapReport.totalSchools}</Typography>
                                                </LightTooltip>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <LightTooltip title={salesRapReport.newSchoolsDesc}>
                                                    <Typography>New Schools</Typography>
                                                </LightTooltip>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <LightTooltip title={salesRapReport.newSchoolsDesc}>
                                                    <Typography>{salesRapReport.newSchools}</Typography>
                                                </LightTooltip>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <LightTooltip title={salesRapReport.totalBoardsDesc}>
                                                    <Typography>Boards</Typography>
                                                </LightTooltip>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <LightTooltip title={salesRapReport.totalBoardsDesc}>
                                                    <Typography>{salesRapReport.totalBoards}</Typography>
                                                </LightTooltip>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <LightTooltip title={salesRapReport.totalUnitsDesc}>
                                                    <Typography>Total # of Units</Typography>
                                                </LightTooltip>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <LightTooltip title={salesRapReport.totalUnitsDesc}>
                                                    <Typography>{formatNumber(salesRapReport.totalUnits)}</Typography>
                                                </LightTooltip>
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={12} md={6}>
                                            <Grid item xs={7}>
                                                <LightTooltip title={salesRapReport.sumOfSubtotalsDesc}>
                                                    <Typography>Sum of Subtotals</Typography>
                                                </LightTooltip>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <LightTooltip title={salesRapReport.sumOfSubtotalsDesc}>
                                                    <Typography>
                                                        $ {formatNumber(salesRapReport.sumOfSubtotals)}
                                                    </Typography>
                                                </LightTooltip>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <LightTooltip title={salesRapReport.sumOfTaxesDesc}>
                                                    <Typography>Sum of Tax</Typography>
                                                </LightTooltip>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <LightTooltip title={salesRapReport.sumOfTaxesDesc}>
                                                    <Typography>$ {formatNumber(salesRapReport.sumOfTaxes)}</Typography>
                                                </LightTooltip>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <LightTooltip title={salesRapReport.sumOfShippingDesc}>
                                                    <Typography>Sum of Shipping</Typography>
                                                </LightTooltip>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <LightTooltip title={salesRapReport.sumOfShippingDesc}>
                                                    <Typography>
                                                        $ {formatNumber(salesRapReport.sumOfShipping)}
                                                    </Typography>
                                                </LightTooltip>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <LightTooltip title={salesRapReport.sumOfTotalsDesc}>
                                                    <Typography>Sum of Totals</Typography>
                                                </LightTooltip>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <LightTooltip title={salesRapReport.sumOfTotalsDesc}>
                                                    <Typography>
                                                        $ {formatNumber(salesRapReport.sumOfTotals)}
                                                    </Typography>
                                                </LightTooltip>
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={12} md={6}>
                                            <Grid item xs={5}>
                                                <LightTooltip title={salesRapReport.cancelledSchoolsDesc}>
                                                    <Typography>Cancelled Schools</Typography>
                                                </LightTooltip>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <LightTooltip title={salesRapReport.cancelledSchoolsDesc}>
                                                    <Typography>{salesRapReport.cancelledSchools}</Typography>
                                                </LightTooltip>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <LightTooltip title={salesRapReport.schoolsOnHoldDesc}>
                                                    <Typography>Schools On Hold</Typography>
                                                </LightTooltip>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <LightTooltip title={salesRapReport.schoolsOnHoldDesc}>
                                                    <Typography>{salesRapReport.schoolsOnHold}</Typography>
                                                </LightTooltip>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <LightTooltip title={salesRapReport.sumOfLostSalesDesc}>
                                                    <Typography>Sum of Lost Sales</Typography>
                                                </LightTooltip>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <LightTooltip title={salesRapReport.sumOfLostSalesDesc}>
                                                    <Typography>
                                                        $ {formatNumber(salesRapReport.sumOfLostSales)}
                                                    </Typography>
                                                </LightTooltip>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <LightTooltip title={salesRapReport.totalPlannersDesc}>
                                                    <Typography>Total Planners</Typography>
                                                </LightTooltip>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <LightTooltip title={salesRapReport.totalPlannersDesc}>
                                                    <Typography>
                                                        {formatNumber(salesRapReport.totalPlanners)}
                                                    </Typography>
                                                </LightTooltip>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        {barChart.options ? (
                            <Grid item xs={12} md={8} lg={8}>
                                <Card>
                                    <CardHeader title={<h1 className={classes.cardHeader}>Sales By Province</h1>} />
                                    <CardContent className={classes.content}>
                                        {/* <h1 className={classes.cardHeader}>Sales By Province</h1> */}
                                        <Typography>
                                            Graph shows your sales compared to total sales for that province (for
                                            selected date range - excluding Cancelled and On Hold orders).
                                        </Typography>
                                        <Chart
                                            options={barChart.options}
                                            series={barChart.series}
                                            type="bar"
                                            height="250"
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>
                        ) : (
                            ""
                        )}
                        {donutChart.options ? (
                            <Grid item xs={12} md={4} lg={4}>
                                <Card>
                                    <CardHeader title={<h1 className={classes.cardHeader}>Sales by Rep (%)</h1>} />

                                    <CardContent className={classes.content} style={{ minHeight: "305px" }}>
                                        {/* <h1 className={classes.cardHeader}>Sales by Rep (%)</h1> */}
                                        <Typography>
                                            Graph shows percentage of sales by sales rep for selected date range
                                            (excluding Cancelled and On Hold orders).
                                        </Typography>
                                        <Chart
                                            options={donutChart.options}
                                            series={donutChart.series}
                                            type="donut"
                                            height="240"
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>
                        ) : (
                            ""
                        )}
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12} md={8} lg={8}>
                            <Card className={classes.orderContainerHeight}>
                                <CardHeader title={<h1 className={classes.cardHeader}>Most Recent Orders</h1>} />

                                <CardContent className={classes.content}>
                                    {/* <h1 className={classes.cardHeader}>Most Recent Orders</h1> */}
                                    <Typography className={classes.descText}>
                                        Table shows your most recently created orders (not affected by date range)
                                    </Typography>
                                    <TableContainer className={classes.orderHeight}>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Order</TableCell>
                                                    <TableCell>School</TableCell>
                                                    <TableCell>Total</TableCell>
                                                    <TableCell>Status</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {salesOrder.map((order) => (
                                                    <TableRow>
                                                        <TableCell>{order.orderId}</TableCell>
                                                        <TableCell
                                                            component={RouterLink}
                                                            to={`/crm/schools/${order.schoolId}`}
                                                        >
                                                            {order.schoolName}
                                                        </TableCell>
                                                        <TableCell>$ {formatNumber(order.total)}</TableCell>
                                                        <TableCell>
                                                            <span
                                                                style={{
                                                                    "background-color": `#${order.orderStatusBackColour}`,
                                                                    color: `#${order.orderStatusForeColour}`,
                                                                    padding: "3px",
                                                                }}
                                                            >
                                                                {order.orderStatus}
                                                            </span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Button
                                                                color="primary"
                                                                variant="outlined"
                                                                component={RouterLink}
                                                                to={`/crm/orders/${order.orderId}`}
                                                            >
                                                                View
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                            <Card className={classes.orderContainerHeight}>
                                <CardHeader title={<h1 className={classes.cardHeader}>Sales Rep Activity</h1>} />

                                <CardContent className={classes.content}>
                                    {/* <h1 className={classes.cardHeader}>Sales Rep Activity</h1> */}
                                    <Typography className={classes.descText}>
                                        Table shows most recent CRM activity by sales rep (not affected by date range)
                                    </Typography>
                                    <TableContainer className={classes.orderHeight}>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableBody>
                                                {salesRepActivity.map((activity, i) => (
                                                    <TableRow key={i}>
                                                        <TableCell>{activity.salesRepName}</TableCell>
                                                        <TableCell>{getDaysFormate(activity.lastSignonUtc)}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            ) : (
                <Progress />
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
