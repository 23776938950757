import React from "react";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
// import settings from "../../settings";

// function getSetting(isProdConnected) {
//     if (settings.settings.env === "DEV" && isProdConnected) {
//         return '<div class="stag-footer">Staging environment</div>';
//     } else if (settings.settings.env === "PROD" && !isProdConnected) {
//         return '<div class="stag-footer">Staging environment</div>';
//     }
// }

export default function Copyright(props) {
    // let stagingDiv = "";
    // if (props && props.isStaging === true) {
    //     stagingDiv = getSetting(props.isStaging);
    // }

    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            {new Date().getFullYear()}{" "}
            <Link target="_blank" color="inherit" href="https://www.firstclassplanners.ca/">
                First Class Planners
            </Link>
            <br />
            {"Website By "}
            <Link target="_blank" color="inherit" href="https://www.saphera.com/">
                Saphera Software
            </Link>
            {/* <span dangerouslySetInnerHTML={{ __html: stagingDiv }}></span> */}
        </Typography>
    );
}
