import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {

    },
    title: {
        marginBottom: theme.spacing(1)
    },
    desc: {
        marginBottom: theme.spacing(4)
    }
}));

export default function Title(props) {

    const classes = useStyles();

    return (
        <React.Fragment>
            <Typography component="h2" variant="overline" gutterBottom>
                {props.subtitle}
            </Typography>
            <Typography className={classes.title} component="h1" variant="h3">
                {props.title}
            </Typography>
            <Typography className={classes.desc} variant="body1" gutterBottom>
                {props.desc}
            </Typography>
        </React.Fragment>
    );
}



