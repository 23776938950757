import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import Progress from "../../Helpers/Progress";

import {
    TextField,
    Grid,
    Card,
    CardContent,
    Button,
    FormControl,
    FormHelperText,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
} from "@material-ui/core";
import settings from "../../../settings";

// const useStyles = makeStyles((theme) => ({
//     appBar: {
//         position: "relative",
//     },
//     title: {
//         marginLeft: theme.spacing(2),
//         flex: 1,
//     },
// }));

class CreateContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contact: {},
            showProgress: false,
            error: {},
            titles: [],
            roles: [],
            school: props.schoolId,
        };
    }
    componentDidMount = async (event) => {
        this.getContactRoles();
        this.getContactTitles();
    };
    getContactTitles = () => {
        const token = localStorage.getItem("pes-crm-token");
        fetch(settings.api().meta.contactTitles, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                let titles = [];
                let keys = Object.keys(data);
                for (let i = 0; i < keys.length; i++) {
                    titles.push({ id: keys[i], value: data[i] });
                }
                this.setState({ titles: titles });
            });
    };
    getContactRoles = () => {
        const token = localStorage.getItem("pes-crm-token");
        fetch(settings.api().meta.contactroles, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                let roles = [];
                let keys = Object.keys(data);
                for (let i = 0; i < keys.length; i++) {
                    roles.push({ id: keys[i], value: data[i] });
                }
                this.setState({ roles: roles });
            });
    };

    updateContact = () => {
        this.setState({ error: {} });
        let isError = false;
        if (
            this.state.contact.firstName === "" ||
            this.state.contact.firstName === undefined ||
            this.state.contact.firstName === null
        ) {
            this.setState((prevState) => ({
                error: {
                    ...prevState.error,
                    nameError: true,
                    helperText: "Please Provide Fisrt Name",
                },
            }));
            isError = true;
        }
        if (
            this.state.contact.email === "" ||
            this.state.contact.email === undefined ||
            this.state.contact.email === null ||
            !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                this.state.contact.email
            )
        ) {
            this.setState((prevState) => ({
                error: {
                    ...prevState.error,
                    emailError: true,
                    emailHelperText: "Please Provide Valid Email",
                },
            }));
            isError = true;
        }
        if (
            this.state.contact.lastName === "" ||
            this.state.contact.lastName === undefined ||
            this.state.contact.lastName === null
        ) {
            this.setState((prevState) => ({
                error: {
                    ...prevState.error,
                    lastNameError: true,
                    lastNameHelperText: "Please Provide Last Name",
                },
            }));
            isError = true;
        }
        if (
            this.state.contact.role === "" ||
            this.state.contact.role === undefined ||
            this.state.contact.role === null
        ) {
            this.setState((prevState) => ({
                error: {
                    ...prevState.error,
                    roleError: true,
                    roleHelperText: "Please Provide Role",
                },
            }));
            isError = true;
        }
        if (
            this.state.contact.title === "" ||
            this.state.contact.title === undefined ||
            this.state.contact.title === null
        ) {
            this.setState((prevState) => ({
                error: {
                    ...prevState.error,
                    titleError: true,
                    titleHelperText: "Please Provide Title",
                },
            }));
            isError = true;
        }
        if (
            this.state.contact.phone === "" ||
            this.state.contact.phone === undefined ||
            this.state.contact.phone === null
        ) {
            this.setState((prevState) => ({
                error: {
                    ...prevState.error,
                    phoneError: true,
                    phoneHelperText: "Please Provide Phone",
                },
            }));
            isError = true;
        }

        if (isError) return;
        let token = localStorage.getItem("pes-crm-token");
        let contactData = this.state.contact;
        fetch(settings.api().schools + "/" + this.state.school + "/contacts", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(contactData),
        })
            .then((data) => {
                this.props.saved();
            })
            .catch((err) => {
                alert("data can not be saved");
            });
    };
    fieldChange = (event, value) => {
        if (
            event.target.value !== undefined &&
            // event.target.value !== "" &&
            event.target.value !== " "
        ) {
            let key = event.target.id;
            let value = event.target.value;
            this.setState((prevState) => ({
                contact: { ...prevState.contact, [key]: value },
            }));
            // this.state.contact = contact;
        }
    };
    title_change = (event, value) => {
        if (event.target.value !== undefined && event.target.value !== "" && event.target.value !== " ") {
            let value = event.target.value;
            this.setState((prevState) => ({
                contact: { ...prevState.contact, title: value },
            }));
        }
    };
    role_change = (event, value) => {
        if (event.target.value !== undefined && event.target.value !== "" && event.target.value !== " ") {
            let value = event.target.value;
            this.setState((prevState) => ({
                contact: { ...prevState.contact, role: value },
            }));
        }
    };
    isactive_change = (event, value) => {
        let active = event.target.checked;
        this.setState((prevState) => ({
            contact: { ...prevState.contact, active: active },
        }));
    };

    render() {
        return (
            <div>
                <Grid container spacing={2} direction="column" alignItems="center" style={{ minHeight: "100vh" }}>
                    {this.state.showProgress === true ? (
                        <Grid item xs={6}>
                            <Progress />
                        </Grid>
                    ) : (
                        <Grid item xs={6}>
                            <Card style={{ "padding-top": "20px" }}>
                                <CardContent>
                                    <br />
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            {" "}
                                            <TextField
                                                id="firstName"
                                                onChange={this.fieldChange}
                                                fullWidth
                                                label="First Name"
                                                value={this.state.contact.firstName}
                                                helperText={this.state.error.helperText}
                                                error={this.state.error.nameError}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="lastName"
                                                onChange={this.fieldChange}
                                                value={this.state.contact.lastName}
                                                fullWidth
                                                label="Last Name"
                                                helperText={this.state.error.lastNameHelperText}
                                                error={this.state.error.lastNameError}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            {/* <TextField
                        id="title"
                        onChange={this.fieldChange}
                        value={this.state.contact.title}
                        fullWidth
                        label="Title"
                        helperText={this.state.error.titleHelperText}
                        error={this.state.error.titleError}
                      /> */}
                                            <FormControl width={300} fullWidth error={this.state.error.titleError}>
                                                <InputLabel>Title</InputLabel>
                                                <Select
                                                    id="title"
                                                    displayEmpty
                                                    onChange={this.title_change}
                                                    value={this.state.contact.title}
                                                >
                                                    <MenuItem value="" primaryText=""></MenuItem>
                                                    {this.state.titles.map((option) => (
                                                        <MenuItem value={option.value} primaryText={option.value}>
                                                            {option.value}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {this.state.error.titleError ? (
                                                    <FormHelperText>{this.state.error.titleHelperText}</FormHelperText>
                                                ) : (
                                                    ""
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl width={300} fullWidth error={this.state.error.roleError}>
                                                <InputLabel>Role</InputLabel>
                                                <Select
                                                    id="role"
                                                    displayEmpty
                                                    onChange={this.role_change}
                                                    value={this.state.contact.role}
                                                >
                                                    <MenuItem value="" primaryText=""></MenuItem>
                                                    {this.state.roles.map((option) => (
                                                        <MenuItem value={option.value} primaryText={option.value}>
                                                            {option.value}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {this.state.error.roleError ? (
                                                    <FormHelperText>{this.state.error.roleHelperText}</FormHelperText>
                                                ) : (
                                                    ""
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="email"
                                                onChange={this.fieldChange}
                                                value={this.state.contact.email}
                                                fullWidth
                                                label="Email"
                                                type="email"
                                                helperText={this.state.error.emailHelperText}
                                                error={this.state.error.emailError}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="phone"
                                                onChange={this.fieldChange}
                                                value={this.state.contact.phone}
                                                fullWidth
                                                label="Phone"
                                                helperText={this.state.error.phoneHelperText}
                                                error={this.state.error.phoneError}
                                            />
                                        </Grid>
                                        <Grid item xs={12}></Grid>
                                        <Grid item xs={12}>
                                            <Checkbox
                                                value={this.state.contact.active}
                                                id="active"
                                                onChange={this.isactive_change}
                                                selected={this.state.contact.active === true ? true : false}
                                                label="Active"
                                                color="primary"
                                            />{" "}
                                            Active
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="notes"
                                                onChange={this.fieldChange}
                                                value={this.state.contact.notes}
                                                multiline
                                                fullWidth
                                                label="Notes"
                                                variant="outlined"
                                                rows="4"
                                                style={{ marginBottom: 10 }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                aria-label="Update"
                                                onClick={this.updateContact}
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                </Grid>
            </div>
        );
    }
}
export default CreateContactForm;
