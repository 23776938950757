import React from 'react';
import Typography from '@material-ui/core/Typography';

export default function PortalError() {
    return (
        <div>
            <Typography component="h1" variant="h5">
                404 - Page does not exist!
            </Typography>
        </div>
    );
}
