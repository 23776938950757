import React from "react";
import {
  Button,
  makeStyles,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  newFile: {
    margin: theme.spacing(2, 0),
  },
}));

const Files = () => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h3" align="center" style={{ padding: "10px" }}>
        This section is under construction ...
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Files</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Uploaded</TableCell>
          </TableRow>
        </TableHead>
      </Table>
      <Tooltip title="Unavailable">
        <Button variant="contained" color="primary" className={classes.newFile}>
          Add File
        </Button>
      </Tooltip>
    </>
  );
};

export default Files;
