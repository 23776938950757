import React, { useState, useEffect } from "react";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    TableFooter,
    Typography,
    TextField,
    //SelectField ,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Dialog,
    AppBar,
    Toolbar,
    Slide,
    IconButton,
} from "@material-ui/core";
//import Autocomplete from '@material-ui/lab/Autocomplete';
// import AddIcon from "@material-ui/icons/Add";
// import ListIcon from "@material-ui/icons/School";
import CloseIcon from "@material-ui/icons/Close";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";

import settings from "../../../settings";
import Title from "../../Helpers/Titles";

import Progress from "../../Helpers/Progress";
import Moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {},
    content: {
        padding: 0,
    },
    inner: {
        minWidth: 700,
    },
    nameCell: {
        display: "flex",
        alignItems: "center",
    },
    avatar: {
        height: 42,
        width: 42,
        backgroundColor: blue[500],
        marginRight: theme.spacing(1),
    },
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

export default function Orders() {
    const classes = useStyles();
    const [orders, setOrders] = useState([]);
    const [boards, setBoards] = useState([]);

    const [showProgress, setshowProgress] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setrowsPerPage] = useState(10);
    //const [TotalCount, setTotalCount] = useState(0);
    const [filterorderNumber, setFilterorderNumber] = useState("");
    const [filterschool, setFilterschool] = useState("");
    const [filterboard, setFilterboard] = useState("");

    const [dialogTitle, setDialogTitle] = useState("");
    const [open, setOpen] = React.useState(false);
    const [openNew, setOpenNew] = React.useState(false);

    const [firstLoad, setfirstLoad] = React.useState(true);

    const [pagination, setPagination] = useState({});
    const token = localStorage.getItem("pes-crm-token");
    let mounted = true;

    const txt_School_Changed = (event, value) => {
        setfirstLoad(false);
        localStorage.setItem("filterschool", event.target.value);
        setFilterschool(event.target.value);
        setPage(0);
        //setrowsPerPage(10);
        fetchorders(0, rowsPerPage, event.target.value, filterboard, filterorderNumber);
    };
    const Board_Change = (event, value) => {
        if (event.target.value !== undefined && event.target.value !== "" && event.target.value !== " ") {
            localStorage.setItem("filterboard", event.target.value);
            setFilterboard(event.target.value);
            setPage(0);
            //setrowsPerPage(10);
            fetchorders(0, rowsPerPage, filterschool, event.target.value, filterorderNumber);
        } else if (event.target.value === "" || event.target.value === " ") {
            localStorage.setItem("filterboard", "");
            setFilterboard("");
            setPage(0);
            //setrowsPerPage(10);
            fetchorders(0, rowsPerPage, filterschool, event.target.value, filterorderNumber);
        } else {
            setFilterboard(event.target.value);
        }
    };

    const txt_Order_Changed = (event, value) => {
        localStorage.setItem("filterorderNumber", event.target.value);
        setFilterorderNumber(event.target.value);
        setPage(0);
        //setrowsPerPage(10);
        fetchorders(0, rowsPerPage, filterschool, filterboard, event.target.value);
    };

    //
    const handleChangePage = (event, page) => {
        setPage(page);
        setshowProgress(true);
        fetchorders(page, rowsPerPage, filterschool, filterboard, filterorderNumber);
    };
    const handleChangePageSize = (event) => {
        setrowsPerPage(event.target.value);
        setshowProgress(true);
        fetchorders(page, event.target.value, filterschool, filterboard, filterorderNumber);
    };
    const fetchorders = (pageL, rowsPerPageL, filterschoolL, filterboardL, filterorderNumberL) => {
        pageL = pageL + 1;
        let filterQuerystring =
            (filterorderNumberL !== null && filterorderNumberL !== undefined && filterorderNumberL !== ""
                ? "&OrderId=" + filterorderNumberL
                : "") +
            (filterboardL !== null && filterboardL !== undefined && filterboardL !== ""
                ? "&Board=" + filterboardL
                : "") +
            (filterschoolL !== null && filterschoolL !== undefined && filterschoolL !== ""
                ? "&School=" + filterschoolL
                : "");

        setshowProgress(true);
        fetch(settings.api().orders + "?PageNumber=" + pageL + "&PageSize=" + rowsPerPageL + filterQuerystring, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                for (var pair of response.headers.entries()) {
                    console.log(pair[0] + ": " + pair[1]);
                    if (pair[0] === settings.api().pagination) {
                        setPagination(JSON.parse(pair[1]));
                    }
                }
                return response.json();
            })
            .then((data) => {
                if (mounted) {
                    setOrders(data);
                    setshowProgress(false);
                }
            });
    };

    const clearFilter = () => {
        localStorage.setItem("filterschool", "");
        localStorage.setItem("filterboard", "");
        localStorage.setItem("filterorderNumber", "");
        setFilterorderNumber("");
        setFilterschool("");
        setFilterboard("");
        setPage(0);
        setrowsPerPage(10);

        fetchorders(0, 10, "", "", "");
    };

    const fetchBoards = () => {
        fetch(settings.api().meta.boards, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                let arrBoard = [];
                let keys = Object.keys(data);
                for (let i = 0; i < keys.length; i++) {
                    if (data[i]) {
                        arrBoard.push({ id: keys[i], value: data[i] });
                    }
                }
                arrBoard = arrBoard.sort((a, b) => a.value.localeCompare(b.value));
                setBoards(arrBoard);
                setFilterboard(localStorage.getItem("filterboard"));
            });
    };

    useEffect(() => {
        setPage(0);
        setrowsPerPage(10);
        setshowProgress(true);

        setFilterorderNumber(localStorage.getItem("filterorderNumber"));
        setFilterschool(localStorage.getItem("filterschool"));
        fetchorders(
            page,
            rowsPerPage,
            localStorage.getItem("filterschool"),
            localStorage.getItem("filterboard"),
            localStorage.getItem("filterorderNumber")
        );
        fetchBoards();
        return () => {
            mounted = false;
        };
    }, []);
    const handleClickOpenOrder = () => {
        localStorage.removeItem("order");
        setDialogTitle("New Order");
        setOpen(true);
    };
    const handleClickOpenNewOrder = () => {
        setDialogTitle("New Order");
        setOpenNew(true);
    };
    const handleClickCloseNewOrder = () => {
        setOpenNew(false);
    };
    const handleCreateOrder = (data) => {
        setshowProgress(true);
        fetch(settings.api().orders, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((data) => {
                if (mounted) {
                    setshowProgress(false);
                }
            })
            .catch((err) => {
                alert("data can not be saved");
            });
        handleClickCloseNewOrder();
    };

    const handleClickOpenOrderEdit = (schoolIdL) => {
        //setDialogTitle('Edit School');
        //setOpen(true);
        setshowProgress(true);
        fetch(settings.api().orders + "/" + schoolIdL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (mounted) {
                    setDialogTitle("Edit School");
                    localStorage.setItem("school", JSON.stringify(data));
                    setOpen(true);
                    setshowProgress(false);
                }
            });
        //localStorage.setItem('schoolId',schoolidL);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const txt_keyup = (event, mode) => {
        if (event !== null && event.key === "Enter") {
            if (mode === 1) {
                txt_School_Changed(event);
            } else if (mode === 2) {
                txt_Order_Changed(event);
            }
            //event.target.value
        }
    };
    return (
        <div>
            <Title
                title="Orders List"
                subtitle="Orders"
                desc="This page lists all Orders available in PES. You can use filters to find particular orders then view order details by following the provided links."
            />

            <Card>
                <CardHeader title="Search"></CardHeader>
                <Divider />
                <CardContent className={classes.content}>
                    <div></div>
                    <table>
                        <tbody>
                            {/*<TableRow colSpan={2}>
                            <TableCell width={200} >
                            <Button variant="contained" color="primary" aria-label="Add School" 
                            onClick={handleClickOpenNewOrder} > 
                          <AddIcon /> Add School
                        </Button>
                            </TableCell>
					</TableRow>*/}
                            <TableRow>
                                <TableCell width={300}>
                                    <TextField
                                        style={{ width: "100%" }}
                                        label="School"
                                        id="txt_School"
                                        onChange={txt_School_Changed}
                                        value={filterschool}
                                        onKeyUp={(e) => txt_keyup(e, 1)}
                                    />
                                </TableCell>
                                <TableCell width={300}>
                                    <FormControl width={300} fullWidth className={classes.formControl}>
                                        <InputLabel>Board</InputLabel>
                                        <Select onChange={Board_Change} displayEmpty value={filterboard}>
                                            <MenuItem value="" primaryText=""></MenuItem>
                                            <MenuItem value=" " primaryText="">
                                                --Select--
                                            </MenuItem>
                                            {boards.map((option) => (
                                                <MenuItem value={option.value} primaryText={option.value}>
                                                    {option.value}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell width={300}>
                                    <TextField
                                        style={{ width: "100%" }}
                                        label="Order Number"
                                        id="txt_Order"
                                        onChange={txt_Order_Changed}
                                        value={filterorderNumber}
                                        onKeyUp={(e) => txt_keyup(e, 2)}
                                    />
                                </TableCell>
                                <TableCell width={100}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={(e) => {
                                            clearFilter();
                                        }}
                                    >
                                        Clear
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </tbody>
                    </table>
                </CardContent>
                <Divider />
            </Card>
            <Card>
                <CardHeader title="All orders"></CardHeader>
                <Divider />

                <CardContent className={classes.content}>
                    <div className={classes.inner}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Order#</TableCell>
                                    <TableCell>Order Date</TableCell>
                                    <TableCell>School</TableCell>
                                    <TableCell>Boards</TableCell>
                                    <TableCell align="center">Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {showProgress === true ? (
                                    <TableRow>
                                        <TableCell colSpan={5} align="center">
                                            <Progress />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    orders.map((order) => (
                                        <TableRow hover key={order.id}>
                                            <TableCell component={RouterLink} to={`/crm/orders/${order.orderId}`}>
                                                <Typography variant="h6">{order.orderId}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                {Moment(order.oshawaReleaseDate).local().format("DD-MMM-YYYY")}
                                            </TableCell>
                                            <TableCell component={RouterLink} to={`/crm/schools/${order.schoolId}`}>
                                                <Typography variant="h6">{order.schoolName}</Typography>
                                            </TableCell>
                                            <TableCell>{order.boardName}</TableCell>
                                            <TableCell align="center">
                                                <span
                                                    style={{
                                                        "background-color": `#${order.orderStatusBackColour}`,
                                                        color: `#${order.orderStatusForeColour}`,
                                                        padding: "3px",
                                                    }}
                                                >
                                                    {order.orderStatus}
                                                </span>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        colSpan={5}
                                        count={pagination.totalCount}
                                        rowsPerPage={rowsPerPage}
                                        rowsPerPageOptions={[5, 10, 25, 50]}
                                        page={page}
                                        backIconButtonProps={{
                                            "aria-label": "Previous Page",
                                        }}
                                        nextIconButtonProps={{
                                            "aria-label": "Next Page",
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangePageSize}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </div>
                </CardContent>
            </Card>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography componet="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                            {dialogTitle}
                        </Typography>
                        <Button autoFocus color="inherit" onClick={handleClose}>
                            save
                        </Button>
                    </Toolbar>
                </AppBar>
            </Dialog>

            <Dialog fullScreen open={openNew} onClose={handleClickCloseNewOrder} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClickCloseNewOrder} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography componet="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                            {dialogTitle}
                        </Typography>
                    </Toolbar>
                </AppBar>
            </Dialog>
        </div>
    );
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
