import React from "react";
import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({}));

export default function ManagerData() {
    const classes = useStyles();

    return (
        <div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Rep</TableCell>
                        <TableCell align="center">Units</TableCell>
                        <TableCell align="center">Units(%)</TableCell>
                        <TableCell align="center">Rev($)</TableCell>
                        <TableCell align="center">AUP</TableCell>
                        <TableCell align="center">Rev(%)</TableCell>
                        <TableCell align="center">Schools</TableCell>
                        <TableCell align="center">School Renewed(%)</TableCell>
                        <TableCell align="center">New Units</TableCell>
                        <TableCell align="center">New Schools</TableCell>
                        <TableCell align="center">Cover Units</TableCell>
                        <TableCell>Cover %</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>Lisa</TableCell>
                        <TableCell align="center">67,466</TableCell>
                        <TableCell align="center">96%</TableCell>
                        <TableCell align="center">322,662.00</TableCell>
                        <TableCell align="center">4.78</TableCell>
                        <TableCell align="center">100%</TableCell>
                        <TableCell align="center">256</TableCell>
                        <TableCell align="center">116%</TableCell>
                        <TableCell align="center">13,983</TableCell>
                        <TableCell align="center">51</TableCell>
                        <TableCell align="center">46,451</TableCell>
                        <TableCell>66%</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Totalv</TableCell>
                        <TableCell align="center">67,466</TableCell>
                        <TableCell align="center">96%</TableCell>
                        <TableCell align="center">322,662.00</TableCell>
                        <TableCell align="center">4.78</TableCell>
                        <TableCell align="center">100%</TableCell>
                        <TableCell align="center">256</TableCell>
                        <TableCell align="center">116%</TableCell>
                        <TableCell align="center">13,983</TableCell>
                        <TableCell align="center">51</TableCell>
                        <TableCell align="center">46,451</TableCell>
                        <TableCell>66%</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    );
}
