import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import WarningIcon from "@material-ui/icons/Warning";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";

//import DescriptionIcon from '@material-ui/icons/Description';

import Moment from "moment";
//import { useDispatch, useSelector } from "react-redux";

import {
    //    Avatar,
    Button,
    Card,
    CardContent,
    //    CardHeader,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Tabs,
    Tab,
    AppBar,
    Box,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Dialog,
    Slide,
    Toolbar,
    TextField,
    DialogActions,
    DialogContentText,
    DialogContent,
    DialogTitle,
} from "@material-ui/core";

import settings from "../../../settings";
//import Title from '../../Helpers/Titles';
import Progress from "../../Helpers/Progress";
//import { green, orange } from '@material-ui/core/colors';
//import fileDownload from 'js-file-download';
//import { saveAs } from 'file-saver';

import { Link as RouterLink } from "react-router-dom";

import ContactForm from "../Contacts/ContactForm";
import CreateContactForm from "../Contacts/CreateContactForm";
import NoteForm from "../Notes/NoteForm";
import SchoolForm from "../Schools/SchoolForm";
import Contracts from "./School/Contracts";
import Files from "./School/Files";
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));
const tabs = [
    {
        title: "School",
        index: 0,
    },
    {
        title: "Contacts",
        index: 1,
    },
    {
        title: "Notes",
        index: 2,
    },
    {
        title: "Contracts",
        index: 3,
    },
    {
        title: "Files (Coming soon)",
        index: 4,
    },
];

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function indexProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}
/**/
export default function School({ match }) {
    const classes = useStyles();
    // const initialSchool = {
    //     id: 1,
    //     School: "St. John Bosco Catholic School",
    //     Board: "C-ON-Renfrew County Catholic District School Board",
    //     City: "Barrys Bay",
    //     Province: "ON",
    // };

    const schoolId = match.params.id;
    const token = localStorage.getItem("pes-crm-token");
    let mounted = true;

    const [school, setSchool] = useState({});
    const [schoolorders, setSchoolorders] = useState([]);
    const [schoolcontacts, setSchoolcontacts] = useState([]); //
    const [schoolnotes, setSchoolnotes] = useState([]); //
    const [schoolyears, setSchoolyears] = useState([]); //
    const [showProgress, setshowProgress] = useState([]);
    const [tabValue, setTabValue] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState("");
    const [isNote, setIsNote] = React.useState();
    const [isSchool, setIsSchool] = React.useState();

    const [openAlert, setOpenAlert] = React.useState(false);
    const [openAlertMessage, setOpenAlertMessage] = React.useState("");
    const [contactId, setContactId] = React.useState("");
    const [contact, setContact] = React.useState({});
    const [createContactOpen, setCreateContactOpen] = React.useState(false);

    const [salesRep, setSalesRep] = useState("");

    /*const handleClickOpenAlert = () => {
    setOpenAlert(true);
  };*/

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };
    const handleSchoolyearsChange = (event, value) => {
        fetchNotes(event.target.value);
    };
    // const handleClickOpen = () => {
    //     setDialogTitle("New Contact");
    //     setIsNote(false);
    //     setIsSchool(false);
    //     setOpen(true);
    // };
    /*const handleClickEdit = () => {
    setDialogTitle('Edit Contact')
    setIsNote(false);    
    setIsSchool(false);
    setOpen(true);
  };*/
    //
    const handleClickOpenNotes = () => {
        setDialogTitle("New Notes");
        setIsNote(true);
        setIsSchool(false);
        setOpen(true);
    };

    const handleClickOpenSchoolEdit = () => {
        setDialogTitle("Edit School");
        setIsSchool(true);
        setOpen(true);
    };
    const handleClickEditContact = (contactId) => {
        const token = localStorage.getItem("pes-crm-token");
        fetch(settings.api().contacts + "/" + contactId, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setDialogTitle("Edit Contact");
                setIsNote(false);
                setIsSchool(false);
                setContactId(contactId);
                setContact(data);
                setOpen(true);
            });
    };
    const handleClose = () => {
        setOpen(false);
        if (!isSchool && !isNote) {
            fetch(settings.api().schools + "/" + schoolId + "/contacts", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    if (mounted) {
                        setSchoolcontacts(data);
                        setshowProgress(false);
                    }
                });
        }
    };
    const handleSchoolSave = () => {
        setOpen(false);
        fetchSchools(schoolId);
    };
    const handleContactSaveClose = () => {
        setCreateContactOpen(false);
        fetch(settings.api().schools + "/" + schoolId + "/contacts", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (mounted) {
                    setSchoolcontacts(data);
                    setshowProgress(false);
                }
            });
    };
    const openCreateContact = () => {
        setDialogTitle("Add Contact");
        setCreateContactOpen(true);
    };
    const handleNoteFormSaved = (data) => {
        setOpenAlertMessage(data.message);
        setOpen(false);
        setOpenAlert(true);
        fetchSchools(schoolId);
    };

    const fetchNotes = (yearId) => {
        fetch(
            settings.api().schools +
                "/" +
                schoolId +
                "/notes" +
                (yearId !== undefined && yearId !== "" && yearId !== " " ? "?schoolYearId=" + yearId : ""),
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
            .then((response) => response.json())
            .then((data) => {
                if (mounted) {
                    setSchoolnotes(data);
                    setshowProgress(false);
                }
            });
    };
    const fetchSchools = (schoolIdL) => {
        setshowProgress(true);
        //school API
        fetch(settings.api().schools + "/" + schoolIdL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (mounted) {
                    setSchool(data);
                    localStorage.setItem("school", JSON.stringify(data));
                    setshowProgress(false);
                }
            });
        //Order API
        fetch(settings.api().schools + "/" + schoolIdL + "/orders", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (mounted) {
                    setSchoolorders(data);
                    setshowProgress(false);
                }
            });
        //Contact API
        fetch(settings.api().schools + "/" + schoolIdL + "/contacts", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (mounted) {
                    setSchoolcontacts(data);
                    setshowProgress(false);
                }
            });

        //Notes API
        fetch(settings.api().schools + "/" + schoolIdL + "/notes", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (mounted) {
                    setSchoolnotes(data);
                    setshowProgress(false);
                }
            });
    };
    const fetchMeta = () => {
        fetch(settings.api().meta.schoolyears, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                let i = 1;
                let arrSchoolyears = [];
                while (data[i] !== undefined) {
                    arrSchoolyears.push({ id: i, value: data[i] });
                    i++;
                }
                setSchoolyears(arrSchoolyears);
            });
    };
    const fetchSalesRep = (sId) => {
        if (school) {
            fetch(settings.api().reps, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    const sr = data.find((d) => d.id === sId);
                    if (sr !== undefined) {
                        setSalesRep(sr.first + " " + sr.last);
                    }
                });
        }
    };
    useEffect(() => {
        setshowProgress(true);
        fetchSchools(schoolId);
        fetchMeta();

        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {
        if (Object.entries(school).length > 0) {
            fetchSalesRep(school.salesRepId);
        }
    }, [school]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const downloadInvoice = async (orderId) => {
        const response = await fetch(settings.api().orders + "/" + orderId + "/invoice", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        response.blob().then((blob) => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = url;
            a.download = orderId + ".pdf";
            a.click();
        });
        /*if(response.headers.get('content-disposition')) {
     const filename = response.headers.get('content-disposition')
        .split(';')
        .find(n => n.includes('filename='))
        .replace('filename=', '')
        .trim()
      ;

      const blob = await response.blob();

      // Download the file
      saveAs(blob, filename);
    }
    fileDownload(response.body, orderId+'.pdf');*/
    };

    return (
        <div>
            <Card>
                <CardContent className={classes.content}>
                    {showProgress === true ? (
                        <div>
                            {" "}
                            <Progress />
                        </div>
                    ) : (
                        <div>
                            {/*<Title title={"School: " + initialSchool.School} subtitle="School" desc="Once integrated with the API, this page will list PES Schools. Currently we're listing static sample data only. The old system listed Users. Not sure how these relate to PES." />
                             */}

                            <div>
                                <Grid container spacing={0}>
                                    <Grid item xs={9}>
                                        {/* <p> */}
                                        <h1>{school.schoolName}</h1>
                                        {/* </p>
                                        <p> */}
                                        <h2>{school.boardName}</h2>
                                        {/* </p> */}
                                    </Grid>
                                    <Grid item xs={3} style={{ textAlign: "right" }}>
                                        {school.warningFlag === false ? (
                                            <IconButton
                                                aria-label="Go"
                                                variant="contained"
                                                disabled
                                                style={{ color: "white", backgroundColor: "green" }}
                                            >
                                                <ThumbUpAltIcon />
                                            </IconButton>
                                        ) : (
                                            <IconButton
                                                aria-label="Waring"
                                                variant="contained"
                                                disabled
                                                style={{ color: "white", backgroundColor: "orange" }}
                                            >
                                                <WarningIcon />
                                            </IconButton>
                                        )}
                                    </Grid>
                                </Grid>
                            </div>
                            {/*
                    <div style={{"fontSize":15}}>                        
                        <p><b>Name: </b>{initialSchool.School}</p>
                        <p><b>Board: </b>{initialSchool.Board}</p>
                        <p><b>City: </b>{initialSchool.City + ', ' + initialSchool.Province}</p>
                    </div>*/}
                            <div className={classes.root}>
                                <Paper elevation={1} variant="outlined" square className={classes.elevation2}>
                                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="information">
                                        {tabs.map((option) => (
                                            <Tab
                                                key={option.title}
                                                label={option.title}
                                                {...indexProps(option.index)}
                                            />
                                        ))}
                                    </Tabs>
                                    <Divider />
                                    <TabPanel value={tabValue} index={0}>
                                        <div>
                                            <Grid container spacing={0}>
                                                <Grid item xs={6}>
                                                    <div style={{ fontSize: 15 }}>
                                                        <p>
                                                            <b>Address: </b>
                                                            {school.address1}
                                                        </p>
                                                        <p>
                                                            <b> </b>
                                                            {school.address2}
                                                        </p>
                                                        <p>
                                                            <b>City: </b>
                                                            {school.city + ", " + school.provCode}
                                                        </p>
                                                        <p>
                                                            <b>Postal: </b>
                                                            {school.postal}
                                                        </p>
                                                        <p>
                                                            <b>Phone: </b>
                                                            {school.phone}
                                                            <b>&nbsp;&nbsp; Fax: </b>
                                                            {school.fax}
                                                        </p>
                                                        <p>&nbsp;</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div style={{ fontSize: 15 }}>
                                                        <p>
                                                            <b>Sales rep.: </b>
                                                            {salesRep && salesRep}
                                                        </p>
                                                        <p>
                                                            <b>CRM Status: </b>
                                                            {school.crmStatus === null ? "N/A" : school.crmStatus}
                                                        </p>
                                                        <p></p>
                                                        <p>
                                                            <b>Active: </b>
                                                            {school.active === true ? " Yes" : " No"}
                                                        </p>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            aria-label="Go"
                                                            onClick={handleClickOpenSchoolEdit}
                                                        >
                                                            <EditIcon /> Edit
                                                        </Button>
                                                        <p>&nbsp;</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Order Date</TableCell>
                                                                    <TableCell align="right">Sub Total</TableCell>
                                                                    <TableCell align="right">Tax</TableCell>
                                                                    <TableCell align="right">Shipping</TableCell>
                                                                    <TableCell align="right">Total</TableCell>
                                                                    <TableCell>Order Status</TableCell>
                                                                    <TableCell>Invoice</TableCell>
                                                                    <TableCell>Action</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {showProgress === true ? (
                                                                    <TableRow>
                                                                        <TableCell colSpan={5} align="center">
                                                                            <Progress />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ) : (
                                                                    schoolorders.map((school) => (
                                                                        <TableRow hover>
                                                                            <TableCell>
                                                                                {Moment(school.orderDate)
                                                                                    .local()
                                                                                    .format("DD-MMM-YYYY")}
                                                                            </TableCell>
                                                                            <TableCell align="right">
                                                                                ${school.subtotal}
                                                                            </TableCell>
                                                                            <TableCell align="right">
                                                                                ${school.tax}
                                                                            </TableCell>
                                                                            <TableCell align="right">
                                                                                ${school.shipping}
                                                                            </TableCell>
                                                                            <TableCell align="right">
                                                                                ${school.total}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <span
                                                                                    style={{
                                                                                        "background-color": `#${school.orderStatusBackColour}`,
                                                                                        color: `#${school.orderStatusForeColour}`,
                                                                                        padding: "3px",
                                                                                    }}
                                                                                >
                                                                                    {school.orderStatusTitle}
                                                                                </span>
                                                                                {}
                                                                            </TableCell>
                                                                            {school.id > 48000 ? (
                                                                                <TableCell>
                                                                                    <Button
                                                                                        variant="contained"
                                                                                        color="primary"
                                                                                        onClick={(e) => {
                                                                                            downloadInvoice(school.id);
                                                                                        }}
                                                                                        value={school.id}
                                                                                    >
                                                                                        {school.id}
                                                                                    </Button>
                                                                                </TableCell>
                                                                            ) : (
                                                                                <TableCell>
                                                                                    <Button
                                                                                        variant="outlined"
                                                                                        color="secondary"
                                                                                        onClick={(e) => {
                                                                                            downloadInvoice(school.id);
                                                                                        }}
                                                                                        value={school.id}
                                                                                    >
                                                                                        {school.id}
                                                                                    </Button>
                                                                                </TableCell>
                                                                            )}

                                                                            <TableCell>
                                                                                <Button
                                                                                    color="primary"
                                                                                    component={RouterLink}
                                                                                    size="small"
                                                                                    to={`/crm/orders/${school.id}`}
                                                                                    variant="outlined"
                                                                                    style={{
                                                                                        "margin-top": "5px",
                                                                                        "margin-left": "5px",
                                                                                    }}
                                                                                >
                                                                                    View
                                                                                </Button>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value={tabValue} index={1}>
                                        <div>
                                            {/* 
Contacts
        schoolcontacts
*/}
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>First name</TableCell>
                                                        <TableCell>Last name</TableCell>
                                                        <TableCell>Title</TableCell>
                                                        <TableCell>Role</TableCell>
                                                        <TableCell>Email</TableCell>
                                                        <TableCell>Phone</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {showProgress === true ? (
                                                        <TableRow>
                                                            <TableCell colSpan={5} align="center">
                                                                <Progress />
                                                            </TableCell>
                                                        </TableRow>
                                                    ) : (
                                                        schoolcontacts.map((school) => (
                                                            <TableRow hover key={school.id}>
                                                                <TableCell>{school.firstName}</TableCell>
                                                                <TableCell>{school.lastName}</TableCell>
                                                                <TableCell>{school.title}</TableCell>
                                                                <TableCell>{school.role}</TableCell>
                                                                <TableCell>{school.email}</TableCell>
                                                                <TableCell>{school.phone}</TableCell>
                                                                <TableCell>
                                                                    <Button
                                                                        color="primary"
                                                                        variant="outlined"
                                                                        onClick={() =>
                                                                            handleClickEditContact(school.id)
                                                                        }
                                                                    >
                                                                        Edit
                                                                    </Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    )}
                                                </TableBody>
                                            </Table>
                                            <br />
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        aria-label="Add Contact"
                                                        onClick={openCreateContact}
                                                    >
                                                        <AddIcon /> Add Contact
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={6} style={{ textAlign: "right" }}>
                                                    {/*<Button variant="contained" color="secondary" aria-label="Invoice" >
                          <DescriptionIcon /> &nbsp;Invoice
                </Button>*/}
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value={tabValue} index={2}>
                                        {/*schoolnotes
                                         */}
                                        <div>
                                            <Grid container spacing={1}>
                                                <Grid item xs={5}>
                                                    <div>
                                                        <TextField
                                                            id="standard-basic"
                                                            disabled
                                                            multiline
                                                            fullWidth
                                                            label="School Notes"
                                                            rows="4"
                                                            value={school.schoolNote}
                                                            variant="outlined"
                                                            style={{ color: "red" }}
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={2}></Grid>
                                                <Grid item xs={5}>
                                                    <TextField
                                                        id="standard-basic"
                                                        disabled
                                                        multiline
                                                        fullWidth
                                                        label="Attention Notes"
                                                        rows="4"
                                                        value={school.warningNote}
                                                        variant="outlined"
                                                        style={{ color: "orange" }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControl
                                                        style={{ width: "300px", marginBottom: "20px" }}
                                                        className={classes.formControl}
                                                    >
                                                        <InputLabel>School years</InputLabel>
                                                        <Select onChange={handleSchoolyearsChange}>
                                                            <MenuItem value="" primaryText=""></MenuItem>
                                                            <MenuItem value=" " primaryText="">
                                                                --Select--
                                                            </MenuItem>
                                                            {schoolyears.map((option) => (
                                                                <MenuItem
                                                                    key={option.id}
                                                                    value={option.id}
                                                                    primaryText={option.value}
                                                                >
                                                                    {option.value}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6} align="right">
                                                    <Button
                                                        variant="contained"
                                                        aria-label="Add Notes"
                                                        color="primary"
                                                        onClick={handleClickOpenNotes}
                                                    >
                                                        <AddIcon /> Add Notes
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Date</TableCell>
                                                                <TableCell>Notes</TableCell>
                                                                <TableCell>Notes By</TableCell>
                                                                <TableCell>Year</TableCell>
                                                                <TableCell>system</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {showProgress === true ? (
                                                                <TableRow>
                                                                    <TableCell colSpan={5} align="center">
                                                                        <Progress />
                                                                    </TableCell>
                                                                </TableRow>
                                                            ) : (
                                                                schoolnotes.map((school) => (
                                                                    <TableRow hover>
                                                                        <TableCell style={{ width: "150px" }}>
                                                                            {Moment(school.dateCreatedUtc)
                                                                                .local()
                                                                                .format("DD-MMM-YYYY")}
                                                                        </TableCell>
                                                                        <TableCell>{school.note}</TableCell>
                                                                        <TableCell style={{ width: "150px" }}>
                                                                            {school.refName}
                                                                        </TableCell>
                                                                        <TableCell>{school.schoolYearText}</TableCell>
                                                                        <TableCell>{school.system}</TableCell>
                                                                    </TableRow>
                                                                ))
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value={tabValue} index={3}>
                                        <Contracts />
                                    </TabPanel>
                                    <TabPanel value={tabValue} index={4}>
                                        <Files />
                                    </TabPanel>
                                    {/**/}
                                </Paper>
                            </div>
                        </div>
                    )}
                </CardContent>
            </Card>

            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography componet="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                            {dialogTitle}
                        </Typography>
                    </Toolbar>
                </AppBar>
                {isNote === true ? (
                    <NoteForm schoolId={schoolId} onSaved={handleNoteFormSaved} />
                ) : isSchool === true ? (
                    <SchoolForm data={school} saved={handleSchoolSave} />
                ) : (
                    <ContactForm
                        schoolId={schoolId}
                        contactId={contactId}
                        contactDetail={contact}
                        updated={handleClose}
                    />
                )}
            </Dialog>
            <Dialog
                open={openAlert}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseAlert}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Alert"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">{openAlertMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAlert} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullScreen
                open={createContactOpen}
                onClose={handleContactSaveClose}
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleContactSaveClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography componet="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                            {dialogTitle}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <CreateContactForm schoolId={schoolId} saved={handleContactSaveClose} />
            </Dialog>
        </div>
    );
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
