import React from "react";
import { Link } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
// import SalesRepIcon from "@material-ui/icons/Wc";
import SchoolIcon from "@material-ui/icons/School";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import MapIcon from "@material-ui/icons/Map";

export const mainListItems = (
    <div>
        <ListItem button component={Link} to="/crm/">
            <ListItemIcon>
                <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem button component={Link} to="/crm/schools">
            <ListItemIcon>
                <SchoolIcon />
            </ListItemIcon>
            <ListItemText primary="Schools" />
        </ListItem>
        <ListItem button component={Link} to="/crm/orders">
            <ListItemIcon>
                <ShoppingCartIcon />
            </ListItemIcon>
            <ListItemText primary="Orders" />
        </ListItem>
        <ListItem button component={Link} to="/crm/contracts">
            <ListItemIcon>
                <BorderColorIcon />
            </ListItemIcon>
            <ListItemText primary="Contracts" />
        </ListItem>
        <ListItem button component={Link} to="/crm/contacts">
            <ListItemIcon>
                <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Contacts" />
        </ListItem>
        {/*}<ListItem button component={Link} to="/crm/reps">
            <ListItemIcon>
                <SalesRepIcon />
            </ListItemIcon>
            <ListItemText primary="Sales Reps" />
        </ListItem>{*/}
        <ListItem button component={Link} to="/crm/map">
            <ListItemIcon>
                <MapIcon />
            </ListItemIcon>
            <ListItemText primary="Map View" />
        </ListItem>
    </div>
);
