import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { CssBaseline } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import settings from '../../settings';

import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

import { withStyles } from '@material-ui/core/styles';

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { RandomSignonBackground } from '../Helpers/RandomBackgrounds';
import Copyright from '../Helpers/Copyright';


const useStyles = (theme) => ({
    root: {
        height: '100vh',
      },
      image: {
        backgroundImage: 'url(' + RandomSignonBackground() + ')',
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.grey[50],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
      },
      form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
      },
      submit: {
        margin: theme.spacing(3, 0, 2),
      },
})

class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: '',
			isStaging: false
        };
    }

    handleInputChange = (event) => {
        const {value, name } = event.target;
        this.setState({
            [name]: value,
			
        });
    }
	
	componentDidMount = () => {
		let self =this
		setTimeout(function() {
				self.checkIsStagingDb();
		}, 500)
		
	}
	checkIsStagingDb = (event) => {
		fetch(settings.api().isProduction, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            if (res.ok){
				console.log(res)
                return res.json();
             }
        })
        .then(data => {
           this.setState({isStaging: data})
        })
        .catch (err => {
            alert('err');
        })
	}

    onSubmit = (event) => {
        event.preventDefault();
        //fetch('/api/authenticate', {
        fetch(settings.api().authenticate, {
            method: 'POST',
            body: JSON.stringify(this.state),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            if (res.ok){
                return res.json();
                // data = res.json();
                // this.props.history.push('/crm');
            }
            else {
                const error = new Error(res.error);
                throw error;
            }
        })
        .then(data => {
            localStorage.setItem('pes-crm-token', data.token);
			this.props.history.push('/crm');
			fetch(settings.api().signon, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${data.token}`
				}
			}).then(res => {
				if (res.ok){
					return res.json();
				} else {
					const error = new Error(res.error);
					throw error;
				}
			}).then(data => {
				localStorage.setItem('user-data', JSON.stringify(data));
			}).catch (err => {
				alert('Error logging in please try again');
			});
			
            //this.props.history.push('/crm');
			localStorage.setItem('filtercity','');
			localStorage.setItem('filterprovince','');
			localStorage.setItem('filterboard','');  
			localStorage.setItem('filterschool','');  
			localStorage.setItem('filterName','');
			localStorage.setItem('filtersalesrep',''); 			
        })
        .catch (err => {
            alert('Error logging in please try again');
        });
    }

    render() {

        const { classes } = this.props;

        return (
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={7} className={classes.image} />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <form className={classes.form} noValidate onSubmit={this.onSubmit}>
                            <TextField
                            variant="outlined"
                            type="email"
                            name="email"
                            margin="normal"
                            fullWidth
                            label="Enter email"
                            autoComplete="email"
                            autoFocus
                            value={this.state.email}
                            required
                            onChange={this.handleInputChange}
                            id="email"
                            />
                            <TextField
                            variant="outlined"
                            type="password"
                            name="password"
                            margin="normal"
                            fullWidth
                            label="Enter password"
                            autoComplete="current-password"
                            autoFocus
                            value={this.state.password}
                            onChange={this.handleInputChange}
                            required
                            id="password"
                            />
                            <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                value="submit"
                            >
                                Sign In
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link href="#" variant="body2">
                                    Forgot password?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link href="https://www.firstclassplanners.ca/" target="_blank" variant="body2">
                                    {"Don't have an account? Contact us"}
                                    </Link>
                                </Grid>
                            </Grid>
                            <Box mt={5}>
                                <Copyright isStaging={this.state.isStaging}/>
                            </Box>
                        </form>
                    </div>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(useStyles)(Login);