import React, { Component } from 'react';

class Profile extends Component {
    render() {
        return (
            <div>
                <h1>User Profile</h1>
                <p>This is sample page. The idea here is that a CRM user can view their own information. This can be grown to show a user's transactions, reset passwords, etc.</p>
            </div>
        );
    }
}

export default Profile;